import React from 'react';

const Pravila = () => {

    return (
        <div className="globalni-kontejner">
            <h1>PRAVILA UPORABE IN SPLOŠNI POGOJI :</h1>
            <br></br>
            <p>  REGISTRACIJA UPORABNIKOV :</p>                           
                <ul>
                    <li>  Uporabnik se zavezuje, da bo pri registraciji spoštoval naslednja navodila:</li>
                    <li> - Oseba ob registraciji na portal Motolegende potrjuje, da je polnoletna.</li>
                    <li>  - Vpisani podatki, zlasti kontaktni podatki morajo biti popolni in resnični; izdajanje za tretjo osebo oziroma vpisovanje podatkov tretje osebe brez njenega dovoljenja je prepovedano.</li>
                    <li>vsak uporabnik ima lahko na eno telefonsko številko in en elektronski naslov odprt le na en uporabniški profil.</li>
                </ul>

            <p>   ODDAJA OGLASOV IN MOTO AVANTUR :</p>
            <ul>
                <li> - Oglas mora biti napisan izključno v slovenskem jeziku.</li>
                <li> - Vsebina oglasa mora biti resnična, pravilna, aktualna.</li>
                <li> - Vsebina oglasa in moto avantur ne sme biti nezakonita, škodljiva, zavajajoča, sporna, moralno neprimerna ali žaljiva, zlasti ne sme predstavljati kršenja pravic.</li>
                <li> - Vsebina oglasa se mora nanašati le na motor lastnika.</li>
            </ul>

            <p>    DELOVANJE PORTALA :</p>
            <ul>
                <li>Portal Motolegende zagotavlja delovanje storitev portala neprekinjeno, razen v primeru višje sile. Zaradi tehničnih razlogov
                in vzdrževanja portala lahko pride do prekinitev. Ob vstopu uporabnika v portal Motolegende se ob vzdrževanju prikaže naslov (Posodabljanje strani...)</li>               
            </ul>
        </div>
    );
};

export default Pravila;