import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const Meteore = () => {
    const pageTitle = "Kometno gorovje Meteore Grčija";
    const pageDescription = "Kometno gorovje Meteore Grčija";

    return (

        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Meteore" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\Meteore.jpg" alt="Meteore Grčija" className="vinotekaumetnostozadje2" />
            <br></br>
            <center>
                <h3 className="k5" alt="kam na dopust">KOMETNO GOROVJE METEORE - GRČIJA</h3>
                <p style={{ color: "white" }}>( - Kometno gorovje in potovanje v neznano- )</p>
                <div>
                    <img src="Slike\DomovSlike\Umetnost\Meteore\slika1.jpg" alt="Meteore Grčija" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\Meteore\slika2.jpg" alt="Meteore Grčija" className="umetnostpodstranivecslik"  />
                    <img src="Slike\DomovSlike\Umetnost\Meteore\slika4.jpg" alt="Meteore Grčija" className="umetnostpodstranivecslik"  />
                    <img src="Slike\DomovSlike\Umetnost\Meteore\slika3.jpg" alt="Meteore Grčija" className="umetnostpodstranivecslik"  />
                    <img src="Slike\DomovSlike\Umetnost\Meteore\slika5.jpg" alt="Meteore Grčija" className="umetnostpodstranivecslik"  />
                </div>
               
            </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 4.07.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }} alt="Opis potovanja v Meteore v Grčiji"> Meteore so skalnato gorovje, ki je po mitih nastalo tisočletja nazaj zaradi geoloških premikov. Znanstveniki verjamejo, da je bilo tam nekoč morje in so zemeljski premiki povzročili umik morja.
                Zaradi tega imajo gorovja meteorno obliko. Nahaja se v prečudoviti Grčiji.
                Skalnato gorovje ima višino med 300-600 metrov. Na vrhu gorovja se nahaja samostan, kjer prebivalci dobijo živila z dvigalom. V samostanu imajo stroga pravila, brez nošenja kratkih rokavov,majic in hlač. Med skalatmi gorovji so postavljeni leseni mosti, kjer si lahko turisti ogledajo gorovje iz več strani. Je idealna
                razgledna točka za vse vrste romantičnih in adrenalinskih ljudi. Priporočena destinacija (10/10).

            </p>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Meteore;