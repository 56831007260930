import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const Velhotes = () => {
    const pageTitle = "Velhotes portovec vino";
    const pageDescription = "Velhotes vino";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/Velhotes" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="Vinoteka klet" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Velhotes portovec">TOP KLASA PORTOVEC - VELHOTES</h1></center>
            <center><p className="k5" alt="odličen portugalski portovec">( - Idealni Portugalski portovec - )</p></center>
            <p className="k4">- Objavljeno dne :  7.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika2.jpg" alt="Velhotes portovec" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Portovec je čudovito aromatično vino pridelano iz grozdja na Portugalskem. Znano je ne samo potem, da vidiš dvojno, ampak v načinu izdelave.
                    Eden izmed postopkov pridelovanja portovca je, da ljudje z golimi nogami teptajo grozdje. Vino vsebuje 19% delež alkohola in je pakiran v 750ml steklenici.
                    Cena za posamezno steklenico znaša 12€. Ima močan aromatičen in sladek okus, ki te z vsakim požirkom samodejno popelje v vinograde Portugalske. Ocena vina (9.4/10).
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Velhotes;
