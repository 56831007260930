import React, { useEffect, useState, Link, useRef } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const Vinoteka = () => {
    const containerRef = useRef(null);
    const [linkCount, setLinkCount] = useState(0);
    const pageTitle = "Najboljša vina, žganja in piva";
    const pageDescription = "Vinoteka";

    useEffect(() => {
        const container = containerRef.current;

        const handleWheel = (event) => {
            if (event.deltaY !== 0) {
                event.preventDefault();
                container.scrollLeft += event.deltaY;
            }
        };

        container.addEventListener('wheel', handleWheel);

        return () => {
            container.removeEventListener('wheel', handleWheel);
        };
    }, []);


    useEffect(() => {
        if (containerRef.current) {
            setLinkCount(containerRef.current.querySelectorAll('a').length);
        }
    }, []);

    return (

        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <center>
                <h3 className="k5" alt="kam na dopust">VINOTEKA KVALITETNIH PIJAČ <img src="/Logotipi/vinoteka2.png" alt="vinoteka najboljša vina, žganja in piva" style={{ width: "15px" }} />  </h3>
                <p style={{ color: "white" }}>( - Vse od Vina, Žganja, Koktelov in Piva - )</p>
                <div>
                    <a href="https://www.motolegende.si/Umetnost">
                        <img src="/Logotipi/nazaj.png" className="k6" />
                    </a>
                </div>
                <div className="vinotekakontejner" ref={containerRef}>
                    <a href="/Umetnost/Vinoteka/Mamos">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika1.jpg" alt="Mamos Grško pivo" className="vinotekaseznam" />
                    </a>

                    <a href="/Umetnost/Vinoteka/Velhotes">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika2.jpg" alt="Portovec Velhotes Portugalska" className="vinotekaseznam" />
                    </a>

                    <a href="/Umetnost/Vinoteka/LegendOfKremlin">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika3.jpg" alt="Legend of Kremlin vodka" className="vinotekaseznam"  />
                    </a>

                    <a href="/Umetnost/Vinoteka/BlueCuracao">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika4.jpg" alt="Blue curacao cocktail drink" className="vinotekaseznam" />
                    </a>

                    <a href="/Umetnost/Vinoteka/HendricksGin">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika6.jpg" alt="Hendricks gin" className="vinotekaseznam" />
                    </a>

                    <a href="/Umetnost/Vinoteka/Courvoisier">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika7.jpg" alt="Courvoisier konjak" className="vinotekaseznam" />
                    </a>
       
                    <a href="/Umetnost/Vinoteka/DarilnaPijaca">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika5.jpg" alt="Pjača za darila" className="vinotekaseznam" /> 
                    </a>

                    <a href="/Umetnost/Vinoteka/Tesseron">
                        <img src="Slike\DomovSlike\Umetnost\Vinoteka\slika8.jpg" alt="Tesseron konjak" className="vinotekaseznam" />
                    </a>
                                                  
                </div>
            </center>
            <br></br>
            <center><p style={{ color: "white" }}>Prikazano število pijač : {linkCount}</p></center>
        </div>




    );
};

export default Vinoteka;