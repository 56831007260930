import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const LegendOfKremlin = () => {
    const pageTitle = "Legend of Kremlin vodka";
    const pageDescription = "Legend of Kremlin Vodka";

    return (
        <div>
            <br></br>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/LegendOfKremlin" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="Vinoteka klet" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Legend Of Kremlin Vodka">LUKSUZNA VODKA - LEGEND OF KREMLIN</h1></center>
            <center><p className="k5" alt="Luksuzna vodka Legend of Kremlin">( - Začetni tečaj tujega jezika - )</p></center>
            <p className="k4">- Objavljeno dne :  8.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika3.jpg" alt="Ruska vodka Legend Of Kremlin" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Ste imeli kdaj občutek, da z vsakim požirkom vodke, se prebuja vaš notranji zen. Luksuzna vodka Legend Of Kremlin vam omogoča ta občutek. Spada med kvalitetnejše ruske vodke, katere občutite aromo in samo izdelavo.
                 Cena na posamezno steklenico znaša 35€ in steklenica vsebuje 700ml vodke. In kot ponavadi, prihaja z odličnimi 40% vsebnosti alkohola. Ocena vodke (10/10).
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default LegendOfKremlin;