import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import "./OddajMotoOglas.css";
import UniversalCookie from 'universal-cookie'; 
import { useNavigate } from 'react-router-dom'; 
import imageCompression from 'browser-image-compression';

function OddajMotoOglas() {
    const [isLoading, setIsLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState(['', '', '']);
    const [files, setFiles] = useState([null, null, null]);

    const [formData, setFormData] = useState({
        znamka: '',
        model: '',
        prostornina: '',
        kilovati: '',
        konji: '',
        cena: '',
        letnik: '',
        prevozenikilometri: '',
        lastnik: '',
        pogon: '',
        krajogleda: '',
        gsmstevilka: '',  
        opismotorja: '',
    });

    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [maxOglasErrorMessage, setMaxOglasErrorMessage] = useState('');

    // State for selected motor type and motorist type
    const [selectedVrstaMotorja, setSelectedVrstaMotorja] = useState('');
    const [selectedVrstaMotorista, setSelectedVrstaMotorista] = useState('');

    const handleVrstaMotorja = (e) => {
        setSelectedVrstaMotorja(e.target.value);
    };

    const handleVrstaMotorista = (e) => {
        setSelectedVrstaMotorista(e.target.value);
    };

    ////////////////////////////////////////////////////// za slike
    const [imageUrl, setImageUrl] = useState('');
    const [uporabniskoImeOrEmail, setUporabniskoImeOrEmail] = useState('');

    useEffect(() => {
        const cookies = new UniversalCookie();
        const authToken = cookies.get('Motolegende') && cookies.get('Motolegende').token;
        const storedUporabniskoImeOrEmail = cookies.get('Motolegende') && cookies.get('Motolegende').username;

        if (authToken && storedUporabniskoImeOrEmail) {
            setUporabniskoImeOrEmail(storedUporabniskoImeOrEmail);
        }
    }, [files]);

    /////////////////////////////////////////////////////////
    const MAX_FILE_SIZE_BYTES = 3 * 1024 * 1024;
    const MAX_IMAGE_WIDTH = 1920;
    const MAX_IMAGE_HEIGHT = 1080;

    const handleFileChange = async (event, index) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size > MAX_FILE_SIZE_BYTES) {
                alert('Slika presega velikost (3MB). Izberite manjšo velikost.');
                event.target.value = ''; // Clear the input field
            } else {
                try {
                    const options = {
                        maxSizeMB: 1, // Set the maximum size of the compressed image
                        maxWidthOrHeight: MAX_IMAGE_WIDTH, // Set the maximum width of the compressed image
                        useWebWorker: true,
                    };

                    const compressedFile = await imageCompression(file, options);

                    const reader = new FileReader();
                    reader.onload = () => {
                        const binaryData = reader.result.split(',')[1]; // Extract binary data
                        const updatedFiles = [...files];
                        updatedFiles[index] = binaryData;
                        setFiles(updatedFiles);

                        const updatedImageUrls = [...imageUrls];
                        updatedImageUrls[index] = reader.result;
                        setImageUrls(updatedImageUrls);
                    };
                    reader.readAsDataURL(compressedFile);
                } catch (error) {
                    console.error('Error compressing image:', error);
                    // Handle the error
                }
            }
        }
    };

    ///slike delete
    const handleDeleteImage = (index, event) => {
        if (event) {
            event.preventDefault(); // Prevent the default behavior of the button click
        }

        const updatedFiles = [...files];
        updatedFiles[index] = null;
        setFiles(updatedFiles);

        const updatedImageUrls = [...imageUrls];
        updatedImageUrls[index] = '';
        setImageUrls(updatedImageUrls);

        // Clear the value of the corresponding file input
        const fileInput = document.getElementById(`fileInput-${index}`);
        if (fileInput) {
            fileInput.value = ''; // Reset the value of the file input
        }
    };   

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent double-click if already loading
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);
            // Step 1: Upload text data
            const textResponse = await axios.post('/api/Oglas/oglas', {
                Znamka: formData.znamka,
                Model: formData.model, 
                Prostornina: formData.prostornina,
                Kilovati: formData.kilovati,
                Konji: formData.konji,
                Cena: formData.cena,
                Letnik: formData.letnik,
                PrevozeniKilometri: formData.prevozenikilometri,
                Lastnik: formData.lastnik,
                Pogon: formData.pogon,
                KrajOgleda: formData.krajogleda,
                GsmStevilka: formData.gsmstevilka,
                OpisMotorja: formData.opismotorja,
                VrstaMotorja: selectedVrstaMotorja,
                VrstaMotorista: selectedVrstaMotorista,
                UporabniskoImeOrEmail: uporabniskoImeOrEmail,
                ImageUrl1: imageUrls[0], // Update to use imageUrls array
                ImageUrl2: imageUrls[1], // Update to use imageUrls array
                ImageUrl3: imageUrls[2], // Update to use imageUrls array
                ImageData1: files[0],    // Update to use files array
                ImageData2: files[1],    // Update to use files array
                ImageData3: files[2],
            });

            // Handle success
            setSuccessMessage('Oglas Uspešno Oddan!');
            setErrorMessage('');

            // Reset form data
            setFormData({
                znamka: '',
                prostornina: '',
                kilovati: '',
                konji: '',
                cena: '',
                letnik: '',
                prevozenikilometri: '',
                lastnik: '',
                pogon: '',
                krajogleda: '',
                gsmstevilka: '',
                opismotorja: '',
                imageUrl: '',
            });

            setImageUrl('');
            // Reset file inputs
            setFiles([null, null, null]);
           

            // Step 2: Upload images if files are selected
            const uploadPromises = files.map(async (file, index) => {
                if (file !== null) {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('UsernameOrEmail', uporabniskoImeOrEmail);
                }
            });

            await Promise.all(uploadPromises);
        } catch (error) {
            setMaxOglasErrorMessage('Presegli ste limit oddanih oglasov 2.');

            // Handle error
            setSuccessMessage('');
            setErrorMessage('Error: Unable to save data.');
        }
        finally {
            // Set loading state back to false, whether success or error
            setIsLoading(false);
            // Navigate to another page
            navigate('/Oglasimotorjev'); 
        };
    }

    //inputi
    const handleKonjiInputChange = (e) => {
        const { name, value } = e.target;

        // Use a regular expression to remove non-numeric characters
        const numericValue = value.replace(/[^0-9.]/g, '');

        // Update the state with the numeric value
        setFormData({
            ...formData,
            [name]: numericValue,
        });
    };


    //

    return (
        <div className="oglaskontejner">
            <h1 className="oglasizdelaj">IZDELAJ OGLAS</h1>
        <div className="oglas1">
            <div className="success">{successMessage}</div>
            <div className="error">{errorMessage}</div>
            <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="znamka">IZBERI ZNAMKO MOTORJA:</label>
                        <select
                            id="znamka"
                            name="znamka"
                            value={formData.znamka}
                            onChange={handleInputChange}
                            className={`oglas2 maxHeight200`}
                            required
                        >
                            <option value="" disabled hidden>Izberi Znamko</option>
                            <option value="Honda">Honda</option>
                            <option value="Yamaha">Yamaha</option>
                            <option value="Kawasaki">Kawasaki</option>
                            <option value="Suzuki">Suzuki</option>
                            <option value="Ducati">Ducati</option>
                            <option value="Aprillia">Aprillia</option>
                            <option value="BMW">BMW</option>
                            <option value="Husqvarna">Husqvarna</option>
                            <option value="Benelli">Benelli</option>
                            <option value="Gilera">Gilera</option>
                            <option value="Harley Davidson">Harley Davidson</option>
                            <option value="Indian">Indian</option>
                            <option value="Victory">Victory</option>
                            <option value="Custom">Custom</option>
                            <option value="Junak">Junak</option>
                            <option value="KTM">KTM</option>
                            <option value="Kymco">Kymco</option>
                            <option value="Moto Guzzi">Moto Guzzi</option>
                            <option value="MV Agusta">MV Agusta</option>
                            <option value="Royal Enfield">Royal Enfield</option>
                            <option value="Triumph">Triumph</option>
                            <option value="Tomos">Tomos</option>
                            <option value="Vespa">Vespa</option>
                            <option value="Piaggio">Piaggio</option>
                            <option value="Daelim">Daelim</option>
                            <option value="Derbi">Derbi</option>
                            <option value="Hyosung">Hyosung</option>
                            <option value="SYM">SYM</option>
                            <option value="Električni Motocikel">Električni Motocikel</option>
                            <option value="Zero">Zero</option>
                            {/* Add more categories as needed */}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="model">Model motorja:</label>
                        <input
                            type="text"
                            name="model"
                            id="model"
                            value={formData.model}
                            onChange={handleInputChange}
                            placeholder="Model..."
                            className="oglas2"
                            required
                        />
                    </div>

                <div>
                    <label htmlFor="prostornina">Prostornina:</label>
                    <input
                    type="text"
                    name="prostornina"
                    id="prostornina"  // Add this id attribute
                    value={formData.prostornina + " ccm"}
                    onChange={handleKonjiInputChange}
                    placeholder="Prostornina..."
                    className="oglas2"
                    required  // Add the required attribute here
                    />
                 </div>


                 <div>
                     <label htmlFor="kilovati">Kilovati:</label>
                       <input
                          type="text"
                          name="kilovati"
                          id="kilovati"  // Add this id attribute
                          value={formData.kilovati + " kW"}
                          onChange={handleKonjiInputChange}
                          placeholder="Kilovati..."
                          className="oglas2"
                          required  // Add the required attribute here
                        />
                 </div>

                 <div>
                     <label htmlFor="konji">Konjska moč:</label>
                       <input
                          type="text"
                          name="konji"
                          id="konji"
                          value={formData.konji + " HP"}
                          onChange={handleKonjiInputChange}
                          placeholder="Konji..."
                          className="oglas2"
                          required
                        />
                 </div>

                <div>
                    <label htmlFor="cena">Cena € :</label>
                <input
                    type="text"
                    name="cena"
                    id="cena"  // Add this id attribute
                    value={formData.cena + " €"}
                    onChange={handleKonjiInputChange}
                    placeholder="Cena..."
                    className="oglas2"
                    required  // Add the required attribute here
                    />
                    </div>

                <div>
                    <label htmlFor="letnik">Letnik :</label>
                <input
                    type="text"
                    name="letnik"
                    id="letnik"  // Add this id attribute
                    value={formData.letnik}
                    onChange={handleInputChange}
                    placeholder="Letnik..."
                    className="oglas2"
                    required  // Add the required attribute here
                    />
                </div>
                <div>
                    <label htmlFor="prevozenikilometri">Prevoženi kilometri :</label>
                <input
                    type="text"
                    name="prevozenikilometri"
                    id="prevozenikilometri"  // Add this id attribute
                    value={formData.prevozenikilometri + " Km"}
                    onChange={handleKonjiInputChange}
                    placeholder="Prevozeni kilometri..."
                    className="oglas2"
                    required  // Add the required attribute here
                    />
                 </div>


                 <div>
                    <label htmlFor="lastnik">Kateri lastnik:</label>
                      <select
                          id="lastnik"
                          name="lastnik"
                          value={formData.lastnik}
                          onChange={handleInputChange}
                          className={`oglas2 maxHeight200`}
                          required
                        >
                          <option value="" disabled hidden>Izberi Lastnika</option>
                          <option value="Prvi Lastnik">Prvi lastnik</option>
                          <option value="Drugi Lastnik">Drugi lastnik</option>
                          <option value="Tretji Lastnik">Tretji lastnik</option>
                            <option value="Četrti Lastnik">Četrti lastnik</option>
                            <option value="Peti Lastnik">Peti lastnik</option>
                          <option value="Trrrr Skibidi Dop Dop Dop 6+">Trrrr Skibidi Dop Dop Dop 6+</option>
                       </select>
                    </div>

                    <div>
                        <label htmlFor="pogon">Vrsta pogona:</label>
                        <select
                            id="pogon"
                            name="pogon"
                            value={formData.pogon}
                            onChange={handleInputChange}
                            className={`oglas2 maxHeight200`}
                            required
                        >
                            <option value="" disabled hidden>Izberi Pogon</option>
                            <option value="Veriga">Veriga</option>
                            <option value="Jermen">Jermen</option>
                            <option value="Kardan">Kardan</option>
                        </select>
                    </div>


                <div>
                    <label htmlFor="krajogleda">Kraj ogleda:</label>
                <input
                    type="text"
                    name="krajogleda"
                    id="krajogleda"  // Add this id attribute
                    value={formData.krajogleda}
                    onChange={handleInputChange}
                    placeholder="Kraj ogleda..."
                    className="oglas2"
                    required  // Add the required attribute here
                    />
                </div>
                <div>
                    <label htmlFor="gsmstevilka">Gsm številka:</label>
                    <input
                        type="text"
                        name="gsmstevilka"
                        id="gsmstevilka"  // Add this id attribute
                        value={formData.gsmstevilka}
                        onChange={handleInputChange}
                        placeholder="gsm stevilka..."
                        className="oglas2"
                        required  // Add the required attribute here
                    />
                </div>
                <div>
                    <label htmlFor="opismotorja">
                        Opis motorja 
                    </label>
                    <textarea
                        name="opismotorja"
                        id="opismotorja"  // Add this id attribute
                        value={formData.opismotorja}
                        onChange={handleInputChange}
                        placeholder="Opis motorja..."
                        className="oglas2"
                        style={{ height: '200px', resize: 'none' }} 
                        required  // Add the required attribute here
                    />
                    </div>
                <br></br>
                <div className="oglas2">
                    <label htmlFor="VrstaMotorja">IZBERI VRSTO MOTORJA :</label>
                    <select id="VrstaMotorja" onChange={handleVrstaMotorja} value={selectedVrstaMotorja} required>
                        <option value="">Izberi Opcijo</option>
                        <option value="Chopper">Chopper</option>
                        <option value="Naked">Naked Bike</option>
                        <option value="Tourer">Tourer</option>
                        <option value="Enduro">Enduro</option>
                        <option value="Sport">Sport</option>
                        <option value="Retro">Retro</option>
                        <option value="Scrambler">Scrambler</option>
                        <option value="Cross">Cross</option>
                        <option value="Scooter">Scooter</option>
                        <option value="Moped">Moped</option>
                        <option value="Minimoto">Mini Moto</option>
                    </select>
                    </div>
                <br></br>
                <div className="oglas2">
                    <label htmlFor="VrstaMotorista" className="puscicagumbokras">ZA KATERE VRSTE MOTORISTA JE TA MOTOR :</label>
                    <select id="VrstaMotorista" onChange={handleVrstaMotorista} value={selectedVrstaMotorista} required>
                        <option value="">Izberi Opcijo</option>
                        <option value="Mlajše motoriste">Mlajše Motoriste</option>
                        <option value="Motoriste z izkušnjami">Motoriste z Izkušnjami</option>
                        <option value="Rock N Roll">Rock "N" Roll</option>
                        </select>
                        <div className="oglas3">
                        <br></br>
                            <p>IZBERITE 3 FOTOGRAFIJE :</p>
                            {Array.from({ length: 3 }).map((_, index) => (
                                <label key={index}>
                                    <input
                                        type="file"
                                        id={`fileInput-${index}`} // Add this ID attribute
                                        accept=".jpg, .jpeg"
                                        onChange={(e) => handleFileChange(e, index)}
                                        style={{ display: 'none' }}
                                        required
                                    />
                                    <div>
                                        {imageUrls[index] ? (
                                            <>
                                                <img src={imageUrls[index]} className="image-container" alt={`Image ${index + 1}`} />
                                                <button type="button" onClick={(event) => handleDeleteImage(index, event)}>Zbriši Sliko</button>
                                            </>
                                        ) : (
                                            <div className="oglascustominput">
                                                KLIKNI ZA IZBOR SLIKE {index + 1}
                                            </div>
                                        )}
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                    <button type="submit" className="oglassubmitbutton" disabled={isLoading}>
                        {isLoading ? 'Oddajanje...' : 'ODDAJ OGLAS'}
                    </button>
            </form>
                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {maxOglasErrorMessage && <div style={{ color: 'red' }}>{maxOglasErrorMessage}</div>}
            </div>
        </div>       
    );
}

export default OddajMotoOglas;