import React, { useEffect, useState } from 'react';
import "./Motoavanture.css";
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const MotoAvanture = () => {
    const [posts, setPosts] = useState([]);
    const [commentTexts, setCommentTexts] = useState({});
    const [uporabniskoImeOrEmail, setUporabniskoImeOrEmail] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 3;
    const [userAvatars, setUserAvatars] = useState({});
    const [hasCookie, setHasCookie] = useState(false);
    const [likedPosts, setLikedPosts] = useState({});
    const [likeCounts, setLikeCounts] = useState({});
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const [userLikedPosts, setUserLikedPosts] = useState({});
    const likeToggleImageSrc = '/Logotipi/vsecek2.png'; // Replace with the actual path to your toggleable "Like" image
    const pageTitle = "Moto avanture";
    const pageDescription = "Najnovejše objavljene motoristične zgodbe uporabnikov";

    const fetchCommentsForPost = async (postId) => {
        try {
            const response = await axios.get(`/api/Avanture/avanture/${postId}/comments`);
            return response.data;
        } catch (error) {
            return [];
        }
    };

    useEffect(() => {
        const cookieName = 'Motolegende'; // Replace with your actual cookie name
        const cookies = new Cookies();

        const authToken = cookies.get(cookieName) && cookies.get(cookieName).token;
        const storedUporabniskoImeOrEmail = cookies.get(cookieName) && cookies.get(cookieName).username;

        if (authToken && storedUporabniskoImeOrEmail) {
            // Use storedUporabniskoImeOrEmail for any user-specific operations
            setUporabniskoImeOrEmail(storedUporabniskoImeOrEmail);
        }
    }, []); // Empty dependency array means this effect 


    const fetchPosts = async () => {
        try {
            const response = await axios.get('/api/avanture/avanture');
            const postsWithComments = await Promise.all(
                response.data.map(async (post) => {
                    const comments = await fetchCommentsForPost(post.id);
                    return {
                        ...post,
                        comments: comments || [],
                    };
                })
            );
            setPosts(postsWithComments.reverse()); // Reverse the order of posts
        } catch (error) {
        
        }
    };

    const handleCommentChange = (postId, event) => {
        const newText = event.target.value;

        setCommentTexts((prevCommentTexts) => ({
            ...prevCommentTexts,
            [postId]: newText,
        }));
    };

   
    const handleCommentSubmit = (postId) => {
        const text = commentTexts[postId];

        if (!text.trim()) {
            // Add validation for empty comments
            return;
        }


        const newComment = {
            postId: postId,
            text: text,
            usernameOrEmail: uporabniskoImeOrEmail,
            timestamp: new Date().toISOString(),// Make sure the property name is correct
        };

        axios
            .post(`/api/Avanture/avanture/${postId}/comments`, newComment)
            .then(() => {
                fetchPosts();
                setCommentTexts((prevCommentTexts) => ({
                    ...prevCommentTexts,
                    [postId]: "",
                }));
            })
            .catch((error) => console.error('Error submitting comment:', error));
    };

    // Calculate current posts based on pagination
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    };


    //AVATARJI
    const fetchAvatarForUser = async (usernameOrEmail) => {
        try {
            const response = await axios.get(`/api/Avatar/avatar?uporabniskoImeOrEmail=${usernameOrEmail}`, {
                responseType: 'blob',
            });

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'image/jpeg' });
                const avatarUrl = URL.createObjectURL(blob);

                // Update userAvatars state with the fetched avatar
                setUserAvatars((prevAvatars) => ({
                    ...prevAvatars,
                    [usernameOrEmail]: avatarUrl,
                }));

                return avatarUrl;
            }
        } catch (error) {
           
        }
    };

    //cookie
    
    useEffect(() => {
        const cookieName = 'Motolegende'; // Replace with your actual cookie name
        const cookies = new Cookies();

        const authToken = cookies.get(cookieName) && cookies.get(cookieName).token;
        const storedUporabniskoImeOrEmail = cookies.get(cookieName) && cookies.get(cookieName).username;

        if (authToken && storedUporabniskoImeOrEmail) {
            setUporabniskoImeOrEmail(storedUporabniskoImeOrEmail);
            setHasCookie(true);
        }
    }, []);



    //delete post
    const handleDeletePost = async (postId) => {
        try {
            const response = await axios.delete(`/api/Avanture/avanture/${postId}`);

            if (response.status === 200) {
                fetchPosts(); // Refresh the posts after deletion
             
            } else {
               
            }
        } catch (error) {
         
        }
    };

    // likes
    const fetchLikesForPost = async (postId) => {
        try {
            const response = await axios.get(`/api/Likes/likes/${postId}`);
            return response.data.likes || 0;
        } catch (error) {
    
            return 0;
        }
    };

    const handleLikePost = async (postId) => {
        try {
            const response = await axios.post(`/api/Likes/likes/${postId}`);
            if (response.status === 204) {
                // Update liked status in state
                setLikedPosts((prevLikedPosts) => ({
                    ...prevLikedPosts,
                    [postId]: true,
                }));
                // Fetch and update like count
                const likes = await fetchLikesForPost(postId);
                setLikeCounts((prevCounts) => ({
                    ...prevCounts,
                    [postId]: likes,
                }));
                // Update user-specific like status
                setUserLikedPosts((prevUserLikedPosts) => ({
                    ...prevUserLikedPosts,
                    [postId]: true,
                }));
            }
        } catch (error) {
           
        }
    };

    useEffect(() => {
        // Fetch likes for each post and update liked status and like counts in state
        const fetchLikes = async () => {
            const likedStatus = {};
            const counts = {};
            const userLiked = {};

            for (const post of posts) {
                const likes = await fetchLikesForPost(post.id);
                const userLikeStatus = await fetchUserLikeStatus(post.id); // Add this function

                likedStatus[post.id] = likes > 0;
                counts[post.id] = likes;
                userLiked[post.id] = userLikeStatus;
            }

            setLikedPosts(likedStatus);
            setLikeCounts(counts);
            setUserLikedPosts(userLiked);
        };

        const fetchUserLikeStatus = async (postId) => {
            try {
                const response = await axios.get(`/api/Likes/likes/${postId}`);
                return response.data.liked || false;
            } catch (error) {
                console.error(`Error fetching user like status for post ${postId}:`, error);
                return false;
            }
        };

        fetchLikes();
    }, [posts]);

    useEffect(() => {
        fetchPosts();
    }, []);

    document.addEventListener('contextmenu', event => {
        event.preventDefault();
    });

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Motoavanture" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <p style={{ color: 'white', marginTop: "1%", textAlign: 'center' }}>( Število objavljenih postov : {posts.length} )</p>
            <img src="Slike\DomovSlike\Umetnost\umetnostozadje.jpg" alt="Soncni zahod" className="umetnostozadje" />
        <div className="avanture-container">
                <h1 className="avanture-title">Najnovejše Motoristične Objave : <img src="/logotipi/zemlja.png" alt="komentar" className="domovzramnaslovaslika" /> </h1>
            <ul className="avanture-list">
                {currentPosts.map((post) => (
                    <li key={post.id} className="avanture-post">
                        {adminCookie && (
                            <button onClick={() => handleDeletePost(post.id)}>Delete</button>
                        )}
                        <p className="post-text1">{post.text1}</p>
                        <p className="post-author">Uporabnik: {post.usernameOrEmail}</p>
                        <p className="post-time">Objavljeno: {new Date(post.timestamp).toLocaleString()} </p>                    
                        <img
                            src={`data:image/jpeg;base64,${post.image}`}
                            className="post-image"
                            loading="lazy"
                            alt="Objava motoristicne avanture"
                        />                     
                      
                        <p className="post-text2">{post.text2}</p>
                        <img
                            src={likeToggleImageSrc}
                            alt={userLikedPosts[post.id] ? 'Liked' : 'Like'}
                            onClick={() => handleLikePost(post.id)}
                            className="vsecekikona"
                            style={{ cursor: 'pointer' }}
                        />
                        <p className="post-like-count">Všečki : {likeCounts[post.id]}</p>
                        <div className="avanturecomment-section">
                            {hasCookie ? (
                                <>
                            <input
                                className="avantureinput"
                                type="text"
                                value={commentTexts[post.id] || ""}
                                onChange={(event) => handleCommentChange(post.id, event)}
                                placeholder="Dodaj komentar..."
                            />
                            <button onClick={() => handleCommentSubmit(post.id)} className="avanturebutton">
                               Komentiraj
                            </button>
                                </>
                            ) : (
                                <p>Za komentiranje vsebine je obvezna prijava.</p>
                                // You can replace this with any message or leave it empty based on your requirements
                            )}
                            <h3>Komentarji</h3>
                            <p>Število Komentarjev: {post.comments.length}</p>
                            <ul className="avanturecomment-list">
                                {post.comments && post.comments.length > 0 ? (
                                    post.comments.slice().reverse().map((comment) => (
                                        <li key={comment.id} className="avanturecomment">                              
                                            {comment.usernameOrEmail && (
                                                <img
                                                    src={userAvatars[comment.usernameOrEmail] || fetchAvatarForUser(comment.usernameOrEmail)}
                                                    alt="Avatar uporabnika"
                                                    className="avantureavatar-image"
                                                    loading="lazy"
                                                />
                                            )}
                                            <div className="avanturecomment-details">
                                                <p className="avanturecomment-user">Uporabnik: {comment.usernameOrEmail}</p>
                                                <p className="avantureobjavljeno">Objavljeno: {new Date(comment.timestamp).toLocaleString()}</p>
                                            </div>
                                            <p className="avanturecomment-text">{comment.text}</p>
                                        </li>
                                    ))
                                ) : (
                                    <li key="no-comments">No comments yet.</li>
                                )}
                            </ul>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="motoavanturepagination">
                {currentPage > 1 && (
                    <button onClick={() => handlePageChange(currentPage - 1)}>Nazaj</button>
                )}
                <span>{currentPage}</span>
                {currentPage < Math.ceil(posts.length / postsPerPage) && (
                    <button onClick={() => handlePageChange(currentPage + 1)}>Naprej</button>
                )}
            </div>
            <center><p> Vpiši se in objavi svojo najbolj noro avanturo :)</p></center>
            <center><img src="/logotipi/vespa.gif" alt="Vespa skok" className="motoavanturevespa" /></center>
            </div>
        </div>
    );
};

export default MotoAvanture;