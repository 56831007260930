import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import "./DomovPodstrani.css";
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';


const Sopota2024 = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const pageId = 'Sopota2024';
    const pageTitle = "Sopota2024";
    const pageDescription = "Motoristični izlet";

    const videoRef1 = useRef(null);
    const videoSource1 = "/Slike/DomovSlike/Sopota2024/Sopota2024.mp4";
    const imageSource1 = "/Slike/DomovSlike/Sopota2024/th1.jpg";
    const [isVideoPlaying1, setIsVideoPlaying1] = useState(false);
    const videoTitle1 = "Video 1 : { Motoristični izlet v Sopoto in Logatec }";
    const [isLoading1, setIsLoading1] = useState(false);

    const toggleVideo1 = () => {
        setIsLoading1(true);

        // Access the video element using the ref
        if (videoRef1.current) {
            videoRef1.current.load(); // Start loading the video
        }

        setTimeout(() => {
            if (videoRef1.current) {
                videoRef1.current.play(); // Start playing the video
            }

            setIsLoading1(false);
            setIsVideoPlaying1(true);
        }, 3000); // 3-second delay
    };

    const closeVideo1 = () => {
        setIsVideoPlaying1(false);
        setIsLoading1(false);
    };



    const [fullImageSrc, setFullImageSrc] = useState(null);
    const [selectedImageTitle, setSelectedImageTitle] = useState('');

    const openFullImage = (src, title) => {
        setFullImageSrc(src);
        setSelectedImageTitle(title);
    };

    const closeFullImage = () => {
        setFullImageSrc(null);
        setSelectedImageTitle('');
    };

    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'auto' // Use 'auto' for an instant jump to the top
        });
    }, []);

    useEffect(() => {
        // Fetch comments from the API and set them in reverse order
        axios.get(`/api/Komentar/komentar?pageId=${pageId}`)
            .then((response) => {
                setComments(response.data.reverse());
            })
            .catch((error) => {
                console.error('Error fetching comments:', error);
            });

        // Check for cookies
        const cookies = new Cookies();
        const motolegendeCookie = cookies.get('Motolegende');
        const adminCookie = cookies.get('adminCookie');

        // Set isLoggedIn to true if either cookie exists
        if (motolegendeCookie || adminCookie) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleSubmitComment = () => {
        const cookies = new Cookies();
        const user = cookies.get('Motolegende');
        const admin = cookies.get('adminCookie');

        // Check isLoggedIn and comment content
        if ((user || admin) && newComment.trim() !== '') {
            const commentData = {
                userId: user ? user.id : admin.id,
                username: user ? user.username : admin.Administrator, // Use 'administrator' field as username
                content: newComment,
                timestamp: moment().format(),
                pageId: pageId,
            };
            // Send the new comment to the API with the pageId parameter
            axios.post(`/api/Komentar/createkomentar?pageId=${pageId}`, commentData)
                .then((response) => {
                    // Add the new comment to the top of the list
                    setComments([response.data, ...comments]);
                    setNewComment('');
                })
                .catch((error) => {
                    console.error('Error submitting comment:', error);
                });
        } else {
            console.error('No logged-in user found or the comment is empty.');
        };
    };

    const deleteComment = (commentId) => {
        axios
            .delete(`/api/Komentar/deletekomentar/${commentId}`)
            .then(() => {
                // Remove the deleted comment from the state
                setComments(comments.filter(comment => comment.id !== commentId));
            })
            .catch((error) => {
                console.error('Error deleting comment:', error);
            });
    };

    return (
        <div className="okvirkontejnerja">      
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Domov/Sopota2024" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <div className="vsebinakontejnerja">   
                <div className="levikontejner">
                    <div className="large-image-container">
                        <img
                            src="/Slike//DomovSlike/Sopota2024/slika1.jpg"
                            alt="Sopota Zagorje ob Savi "
                            className="podstrandomovimage"
                            loading="lazy"
                            onClick={() => openFullImage('/Slike//DomovSlike/Sopota2024/slika1.jpg', "Slika 1: ( Pavza po končanju polaganja ovinkov )")}
                        />
                        <br></br>
                        <p className="podstranislikeopis">Slika 1: (Pavza po končanju polaganja ovinkov)</p>
                    </div>
                    <div className="small-images-container">
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Sopota2024/slika2.jpg" alt="Največja slovenska zastava" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Sopota2024/slika2.jpg', "Slika 2: ( Največja slovenska zastava )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2: (Največja slovenska zastava)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Sopota2024/slika3.jpg" alt="Največja slovenska zastava" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Sopota2024/slika3.jpg', "Slika 2: ( Opis največje slovenske zastave  )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2: (Opis največje slovenske zastave)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Sopota2024/slika4.jpg" alt="Moto potovanje proti Litiji in Logatcu" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Sopota2024/slika4.jpg', "Slika 3: ( Moto potovanje proti Litiji in Logatcu )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 3: (Moto potovanje proti Litiji in Logatcu)</p>
                        </div>

                        {fullImageSrc && (
                            <div className="full-image-overlay" onClick={closeFullImage}>
                                <img src={fullImageSrc} alt="celotnaslika" className="full-img" />
                                <h5 className="koizbereslikoopis">{selectedImageTitle}</h5>
                            </div>
                        )}
                    </div>

                    <div>
                        {isLoading1 && (
                            <div className="video-overlay" onClick={closeVideo1}>
                                <div className="loading-overlay">
                                    <div style={{ color: 'white' }}>
                                        <h1>Nalaganje videoposnetka...</h1>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isVideoPlaying1 && (
                            <div className="video-overlay" onClick={closeVideo1}>
                                <video ref={videoRef1} controls style={{ height: '600px' }} preload="auto" loading="lazy" onClick={(e) => e.stopPropagation()}>
                                    <source src={videoSource1} type="video/mp4" />
                                </video>
                                <div className="video-title">{videoTitle1}</div>
                            </div>
                        )}

                        {!isVideoPlaying1 && !isLoading1 && (
                            <div>
                                <img src={imageSource1} alt="Motolegende video 2024" onClick={toggleVideo1} loading="lazy" className="videoposnetki" />
                            </div>
                        )}
                    </div>

                    <img
                        src="/Logotipi/Motolegende.png"
                        alt="rock n roll"
                        className="podstranilogoslika"
                        loading="lazy"
                    />
                    <p className="podstranilogoslikaime">Powered From 2024</p>
                </div>
                <div className="desnikontejner">
                    <h1 className="naslovzapodnaslovdomov">MOTORISTIČNI IZLET V SOPOTO - VELIKO PRESKO (ZAGORJE OB SAVI)</h1>                  
                    <br></br>
                    <h6>- Država : Slovenija  <img src="/Logotipi/slovenija.png" alt="slovenija" className="ikonedrzave" /> </h6>
                    <h6>- Lokacija : Sopota - Velika Preska (Zagorje ob Savi)  <img src="/Logotipi/lokacijaikona.png" alt="Zagorje ob savi" style={{ width: "30px" }} /> </h6>
                    <h6>- Dolžina ceste : cca.10km  <img src="/Logotipi/motolegendeikona.png" alt="Zagorje ob savi" style={{ width: "40px" }} /> </h6>
                    <h6>- Priporočeno za vse vrste motoristov</h6>

                    <p className="desnikontejnertext">
                        Dne 29.06.2024 smo se odpravili na čudoviti motoristični izlet. Na kupček tankali jeklene konjičke in se odpravili na dogodivščino. Peljali smo se mimo naselja Sopota vse do Velike Preske. Za vse motoristične ljubitelje je to tapravi kraj za polaganje ovinkov z spremstvom odlične asfaltirane ceste.
                        Naselje Sopota se nahaja v Občini Zagorje ob Savi. Motoristična cesta se začenja med Sopoto in Veliko Presko. Dolžina cesta je cca. 10km in je bombastična.
                        Po končanem polaganju in taktični kafe pavzi smo nadaljevali pot do Logatca. V tej čudoviti občini so pred kratkim postavili največjo slovensko zastavo. Katera ponosno plapola in odmeva vse od Logatca do mednarodne vesoljske postaje.
                        Ocena ovinkov med Sopoto in Veliko Presko : Rock "N" Roll (8).  <img src="/Logotipi/vsecek2.png" alt="like button" className="vsecekikona" />
                    </p>
                    <p>Članek objavljen : 3.07.2024</p>
                    <div className="komentarcelotnikontejner">
                        <h3 className="klepetalnicatext" alt="klepetalnica">KLEPETALNICA : </h3>
                        <p className="klepetalnicatext">Število Komentarjev: {comments.length}</p>
                        <div className="komentarlist">
                            {comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort comments by timestamp in descending order
                                .map((comment) => (
                                    <div key={comment.id} className="komentar">
                                        <strong className="uporabnikime">{comment.username}</strong>: {comment.content}
                                        <br />
                                        <span className="timestamp">{moment(comment.createdAt).format("DD MMMM YYYY  HH:mm")}</span>
                                    </div>
                                ))}
                        </div>
                        <div className="komentarinput">
                            {isLoggedIn ? (
                                <div>
                                    <textarea
                                        rows="3"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        className="komentartextarea"
                                        placeholder="Komentiraj Vsebino ..."
                                    />
                                    <br />
                                    <button onClick={handleSubmitComment} className="komentarbutton">Pošlji Komentar</button>
                                </div>
                            ) : (
                                <p>Če želite komentirati, se prijavite...</p>
                            )}
                        </div>
                    </div>
                    {isLoggedIn && adminCookie && comments.map((comment) => (
                        <div key={comment.id} className="komentar">
                            <strong>{comment.username}</strong>: {comment.content}
                            <br />
                            <span className="timestamp">{moment(comment.timestamp).format("LLL")}</span>
                            <button onClick={() => deleteComment(comment.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Sopota2024;