import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const DarilnaPijaca = () => {
    const pageTitle = "Najboljša pijača za darila";
    const pageDescription = "Darilna Pijača Ballantines, Chivas Regal in Jameson";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/DarilnaPijaca" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="Vinoteka klet" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Klasični viskiji">KLASIČNI VISKIJI - ZA DARILA</h1></center>
            <center><p className="k5" alt="Ballantines Chival Regal in Jameson viskiji">( - Pravijo da velikost ni vse, ampak ne tukaj - )</p></center>
            <p className="k4">- Objavljeno dne :  23.06.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika5.jpg" alt="Ballantines, Chivas Regal, Jameson" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Ni nujno, da je kvaliteta v majhnih stvareh. Ste kdaj pomislili, kako bi osrečili prijatelje, ki se radi družijo v naravnih habitatih (kleteh).
                    Stari dobri viskiji Ballantines, Chivas Regal in Jameson bodo naredili definitivno dober vtis. Cenovno niso pri ugodnejših vendar količinsko pa so na mestu.
                    Vsi vsebujejo 40%+ alkoholne vsebnosti in raznorazne pustolovščine ljudi. Ocena viskijev (9.2/10).

                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default DarilnaPijaca;