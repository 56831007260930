import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import "./DomovPodstrani.css";
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const Dobrodoslica = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const pageId = 'Dobrodoslica';
    const videoRef1 = useRef(null);
    const videoSource1 = "/Slike/DomovSlike/Dobrodoslica/Motolegende.mp4";
    const imageSource1 = "/Slike/DomovSlike/Dobrodoslica/thumbnail.jpg";  
    const [isVideoPlaying1, setIsVideoPlaying1] = useState(false);
    const videoTitle1 = "Video 1 : { Motolegende Video  Song By: Royalty Free Rock Music - Energetic Rock Background Music No Copyright }";
    const [isLoading1, setIsLoading1] = useState(false);

    const pageTitle = "Dobrodošlica";
    const pageDescription = "Portal za motoriste in umetnost";

    const toggleVideo1 = () => {
        setIsLoading1(true);

        // Access the video element using the ref
        if (videoRef1.current) {
            videoRef1.current.load(); // Start loading the video
        }

        setTimeout(() => {
            if (videoRef1.current) {
                videoRef1.current.play(); // Start playing the video
            }

            setIsLoading1(false);
            setIsVideoPlaying1(true);
        }, 3000); // 3-second delay
    };

    const closeVideo1 = () => {
        setIsVideoPlaying1(false);
        setIsLoading1(false);
    };
 
    // SLIKE
    //

    const [fullImageSrc, setFullImageSrc] = useState(null);

    const openFullImage = (src) => {
        setFullImageSrc(src);
    };

    const closeFullImage = () => {
        setFullImageSrc(null);
    };

    useEffect(() => {
        // Fetch comments from the API and set them in reverse order
        axios.get(`/api/Komentar/komentar?pageId=${pageId}`)
            .then((response) => {
                setComments(response.data.reverse());
            })
            .catch((error) => {
         
            });

        // Check for cookies
        const cookies = new Cookies();
        const motolegendeCookie = cookies.get('Motolegende');
        const adminCookie = cookies.get('adminCookie');

        // Set isLoggedIn to true if either cookie exists
        if (motolegendeCookie || adminCookie) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleSubmitComment = () => {
        const cookies = new Cookies();
        const user = cookies.get('Motolegende');
        const admin = cookies.get('adminCookie');

        // Check isLoggedIn and comment content
        if ((user || admin) && newComment.trim() !== '') {
            const commentData = {
                userId: user ? user.id : admin.id,
                username: user ? user.username : admin.Administrator, // Use 'administrator' field as username
                content: newComment,
                timestamp: moment().format(),
                pageId: pageId, 
            };
            // Send the new comment to the API with the pageId parameter
            axios.post(`/api/Komentar/createkomentar?pageId=${pageId}`, commentData)
                .then((response) => {
                    // Add the new comment to the top of the list
                    setComments([response.data, ...comments]);
                    setNewComment('');
                })
                .catch((error) => {
                 
                });
        } else {
       
        };
    };

    const deleteComment = (commentId) => {
        axios
            .delete(`/api/Komentar/deletekomentar/${commentId}`)
            .then(() => {
                // Remove the deleted comment from the state
                setComments(comments.filter(comment => comment.id !== commentId));
            })
            .catch((error) => {
                
            });
    };

    return (
        <div className="okvirkontejnerja">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Domov/Dobrodoslica" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <div className="vsebinakontejnerja">
                <div className="levikontejner">
                    <div className="large-image-container">
                        <img
                            src="/Slike//DomovSlike/Dobrodoslica/livetoride.jpg"
                            alt="Dobrodoslica za motoriste"
                            className="podstrandomovimage"
                            loading="lazy" 
                            onClick={() => openFullImage('/Slike//DomovSlike/Dobrodoslica/livetoride.jpg')}
                        />
                        <br></br>
                        <p className="podstranislikeopis">Slika 1 : (Logotip Moto Legende)</p>
                    </div>
                    <div className="small-images-container">
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Dobrodoslica/motor.jpg" alt="Predelani motocikli" className="img" loading="lazy"  onClick={() => openFullImage('/Slike//DomovSlike/Dobrodoslica/motor.jpg')} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2 : (Custom motocikli)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Dobrodoslica/avantureposvetu.jpg" alt="avanture po svetu" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Dobrodoslica/avantureposvetu.jpg')} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 3 : (Avanture po svetu)</p>
                        </div>
                        {fullImageSrc && (
                            <div className="full-image-overlay" onClick={closeFullImage}>
                                <img src={fullImageSrc} alt="celotnaslika" className="full-img" />
                            </div>
                        )}
                    </div>  


                    <div>
                        {isLoading1 && (
                            <div className="video-overlay" onClick={closeVideo1}>
                                <div className="loading-overlay">
                                    <div style={{ color: 'white' }}>
                                        <h1>Nalaganje videoposnetka...</h1>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isVideoPlaying1 && (
                            <div className="video-overlay" onClick={closeVideo1}>
                                <video ref={videoRef1} controls style={{ height: '600px' }} preload="auto" loading="lazy" onClick={(e) => e.stopPropagation()}>
                                    <source src={videoSource1} type="video/mp4" />
                                </video>
                                <div className="video-title">{videoTitle1}</div>
                            </div>
                        )}

                        {!isVideoPlaying1 && !isLoading1 && (
                            <div>
                                <img src={imageSource1} alt="Motolegende video 2024" onClick={toggleVideo1} loading="lazy" className="videoposnetki" />
                            </div>
                        )}
                    </div>


                    <br></br>
                    <p className="podstranislikeopis">Avtor Fotografij : (Rock 'N' Roll) </p>
                    <img
                        src="/Logotipi/Motolegende.png"
                        alt="Motolegende"
                        className="podstranilogoslika"
                    />
                    <p className="podstranilogoslikaime">Powered From 2024</p>
                </div>
                <div className="desnikontejner">
                    <h1 className="naslovzapodnaslovdomov">PORTAL ZA MOTORISTE IN UMETNOST</h1>
                    <br></br>
                    <h6>- Država : Slovenija  <img src="/Logotipi/slovenija.png" alt="Slovenija" className="ikonedrzave" /></h6>
                    <h6>- Zagon portala : 2024 </h6>
                    <h6>- Tematike : Motociklizem, Umetnost, Potovanja, Izkušnje  <img src="/Logotipi/fiki.png" alt="Motociklizem,umetnost,potovanja,izkusnje" className="ikonedrzave" /> </h6>                   
                    <p className="desnikontejnertext">
                        Pozdravljeni motoristi, motoristke, ki še vozimo motorje na bencin in ostali uporabniki v novem portalu za motociklizem in umetnost. Registrirani uporabniki bodo imeli možnost komentiranja vsebine, oddajanje svojih motociklističnih oglasov in imeli bodo možnost
                        deljenja svoje najbol kul motoristične dogodivščine in doživetja. Tematike se bodo nanašale na moto potovanja, svetovanja in doživetja na podlagi lastnih izkušenj izkušenih motoristov.
                        Skupaj bomo ustvarjali in delili motociklistične vsebine, ki so uporabne, doživete, kulske in začetnim motoristom v pomoč.
                        Lets Sit Down-Buckle up and Rock "N" Roll ;)  <img src="/Logotipi/vsecek2.png" alt="nasmeh" style={{ width: '20px' }} /> 
                    </p>
                  
                    <p>Članek objavljen : 2.01.2024</p>
                    <div className="komentarcelotnikontejner">
                        <h3 alt="klepetalnica">KLEPETALNICA : </h3>
                        <p>Število Komentarjev: {comments.length}</p>
                        <div className="komentarlist">
                            {comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort comments by timestamp in descending order
                                .map((comment) => (
                                    <div key={comment.id} className="komentar">
                                        <strong className="uporabnikime">{comment.username}</strong>: {comment.content}
                                        <br />
                                        <span className="timestamp">{moment(comment.createdAt).format("DD MMMM YYYY  HH:mm")}</span>
                                    </div>
                                ))}
                        </div>
                        <div className="komentarinput">
                            {isLoggedIn ? (
                                <div>
                                    <textarea
                                        rows="3"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        className="komentartextarea"
                                        placeholder="Komentiraj Vsebino ..."
                                    />
                                    <br />
                                    <button onClick={handleSubmitComment} className="komentarbutton">Pošlji Komentar</button>
                                </div>
                            ) : (
                                <p>Če želite komentirati, se prijavite...</p>
                            )}
                        </div>
                    </div>
                    {isLoggedIn && adminCookie && comments.map((comment) => (
                        <div key={comment.id} className="komentar">
                            <strong>{comment.username}</strong>: {comment.content}
                            <br />
                            <span className="timestamp">{moment(comment.timestamp).format("LLL")}</span>
                            <button onClick={() => deleteComment(comment.id)}>Delete</button>
                        </div>
                    ))}
                </div>
                </div>             
            </div>
         
    );
};

export default Dobrodoslica;


