import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "./Administrator.css";

const Administrator = () => {
    const [users, setUsers] = useState([]);
    const [deletedUser, setDeletedUser] = useState(null);
    const navigate = useNavigate();
    const cookies = new Cookies();
    const cookieName = 'adminCookie';
    const [adminCookieValue, setAdminCookieValue] = useState(null);

    useEffect(() => {
        // Retrieve the cookie value when the component mounts
        const adminCookie = cookies.get('adminCookie');
        if (adminCookie) {
            setAdminCookieValue(adminCookie.Administrator);
        } else {
            // If the admin cookie doesn't exist, redirect to another page
            navigate('/'); // Redirect to the login page or any other desired route
        }
    }, []); 


    const handleLogout = () => {
        // Clear the adminCookie
        cookies.remove('adminCookie');

        // Redirect to the login page or any other desired route after logout
        navigate('/');
        window.location.reload();
    };

    useEffect(() => {
        // Fetch users when the component mounts
        const fetchUsers = async () => {
            try {
                const response = await axios.get('/api/Administrator/getusers');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);


    const deleteUser = async (uporabniskoImeOrEmail) => {
        try {
            await axios.delete(`/api/Administrator/delete/${uporabniskoImeOrEmail}`);
            // Filter out the deleted user from the state
            setUsers(users.filter(user => user.uporabniskoIme !== uporabniskoImeOrEmail && user.email !== uporabniskoImeOrEmail));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    
    return (
        <div className="admin-kontejner">
            <div>
                <p>ADMINISTRATOR : {adminCookieValue}</p>
                <button onClick={handleLogout} className="admin-button">ODJAVI SE</button>
            </div>
            <div className="admin-uporabniki">
                <h1>LISTA UPORABNIKOV :</h1>
                <p>Skupno Število Uporabnikov : {users.length}</p> {/* Display the number of users */}
                <div>
                    {users.map(user => (
                        <li key={user.uporabnikId}>
                            {user.uporabniskoIme} - {user.email}
                            <button onClick={() => deleteUser(user.uporabniskoIme)} className="admin-button">Izbris</button>
                        </li>
                    ))}
                </div>                          
            </div>
        </div>
    );
};

export default Administrator;





