import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const PortoPiccolo = () => {
    const pageTitle = "Luksuzno naselje Portopiccolo Italija";
    const pageDescription = "Portopiccolo Italija";

    return (
        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/PortoPiccolo" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\Portopiccolo.jpg" alt="Portopiccolo Italija" className="vinotekaumetnostozadje2" />
            <center>
                <h3 className="k5" alt="Porto Piccolo">PORTOPICCOLO - ITALIJA </h3>
                <p style={{ color: "white" }}>( - Izlet na luksuzno naselje v Italiji, ki je bilo nekoč kamnolom - )</p>
                <div>
                    <img src="Slike\DomovSlike\Umetnost\PortoPiccolo\slika3.jpg" alt="Portopiccolo italija" className="umetnostpodstranivecslik" />                
                    <img src="Slike\DomovSlike\Umetnost\PortoPiccolo\slika2.jpg" alt="Portopiccolo italija" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\PortoPiccolo\slika4.jpg" alt="Portopiccolo italija" className="umetnostpodstranivecslik" />
                </div>
            </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 22.06.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }}> PortoPiccolo spada med luksuzno turistično naselje v Italiji. Naselje je bilo zgrajeno namesto nekdanjega kamnoloma in obsega 3,5 hektarja površin.
                Naselje vsebuje 460 bivalnih enot, ki jih predstavljajo apartmaji, vile in hiše. Ob središču naselja se nahaja marina in kavarne, ki niso med najcenejšimi. Za dokaj velik kup denarja pa si lahko tudi privoščite nakup luksuznega apartmaja.
                Ob začetku naselja se nahaja čudovita plaža in bar.
                Če štartamo čez slovensko mejo pridemo v Portopiccolo v dobrih 30-minutah (seveda od razmer na cesti in šoferjev). Je odlična turistična destinacija za splošno razgledanost in denarnico.
            </p>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default PortoPiccolo;