import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';


const BlueCuracao = () => {
    const pageTitle = "Liker za koktelje Blue Curacao";
    const pageDescription = "Blue Curacao liker";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/BlueCuracao" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="Vinoteka klet" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Eksotični kokteli Blue Curacao">EKSOTIČNI LIKER - BLUE CURACAO</h1></center>
            <center><p className="k5" alt="najboljši koktelj">( - Idealna mešanica za koktelj - )</p></center>
            <p className="k4">- Objavljeno dne :  2.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika4.jpg" alt="Blue Curacao koktelj" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Blue Curacao spada med ene najbolj popularnih likerjev na svetu in Sloveniji. Prihaja v eksotični modri barvi, kot morje. Uporabljajo ga pogosto kot ključno komponento kokteljev v gostinstvu kot privat.
                    Vsebnost alkohola vsebuje 20% in kupimo ga lahko v steklenici 750ml. Cena posamezne steklenice znaša 20€. Če hočemo narediti dober nezahteven koktelj, moramo izbrati mešanico 1-4. Sepravi 1 enoto Blue Curacao in 4 enote sprite.
                    Ob uspešnem popitju prvega kozarčka, dobimo moder jezik in smo pripravljeni na selfie. Ocena likerja (9.5/10).
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default BlueCuracao;