import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const Vasiliki = () => {
    const pageTitle = "Raj za srfanje Vasiliki Grčija";
    const pageDescription = "Raj za srfanje Vasiliki Grčija";

    return (
        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vasiliki" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\Vasiliki.jpg" alt="Otok Lefakada Vasiliki Grčija" className="vinotekaumetnostozadje2" />
                <center>
                    <h3 className="k5" alt="raj za srfanje">RAJ ZA SRFANJE - VASILIKI GRČIJA</h3>
                    <br></br>
                    <div>
                    <img src="Slike\DomovSlike\Umetnost\Vasiliki\slika1.jpg" alt="Vasiliki srfanje" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\Vasiliki\slika2.jpg" alt="Vasiliki srfanje" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\Vasiliki\slika3.jpg" alt="Vasiliki srfanje" className="umetnostpodstranivecslik" />
                    </div>
                </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 17.07.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }}>Če ste ljubitelj vetrov, srfanja in jadranja, je mesto Vasiliki idealno za vas. Vasiliki je mesto na otoku Lefkada, ki se nahaja v prečudoviti Grčiji. Potovanje z avtomobilom do lokacije je cca.1500km.
                    Ob obali Vasilikija se nahaja klub Vass, kjer lahko si izposodite tečaje srfanja, jadranja in supov. Za izposojo srfa je cena cca. 60€ za 2uri. Zraven izposoje srfov pa se nahaja idealen bar, kjer si lahko naročite hladno pjačo in uživate v razgledu.
                    Med sezono so hoteli malo dražji, cene se gibljejo med 100-150€ na nočitev. Če odštejemo cene kampa in najema avtodoma, se bolje splača, da do tja potujete z avtomobilom in si najamete hotel. Priporočeno (10/10).
            </p>
                <div>
                    <a href="https://www.motolegende.si/Umetnost">
                        <img src="/Logotipi/nazaj.png" className="k6" />
                    </a>
                </div>        
        </div>

    );
};

export default Vasiliki;