import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const Mamos = () => {
    const pageTitle = "Mamos Grško pivo";
    const pageDescription = "Mamos Grško pivo";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/Mamos" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="vinoteka klet" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Grško pivo Mamos">GRŠKO PIVO - MAMOS</h1></center>
            <center><p className="k5" alt="Najboljšo Grško pivo">( - Idealno Grško pivo za odžejati - )</p></center>
            <p className="k4">- Objavljeno dne :  6.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika1.jpg" alt="Mamos Grško pivo" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Varjeno v prečudoviti Grški pivovarni, ki se nahaja v Atenah. Pivo varijo že od leta 1867 dalje in s tem prikazujejo, da področje obvladajo.
                    Mamos spada pod vrsto piva Pilsner, saj ima prečudovit močnejši aromatičen okus od ostalih piv. Če pokažete malo šarmantnosti, še zraven dobite priložen originalen kozarček Mamos piva.
                    Vsebuje visokokakovostne sestavine ječmena, hmelja, kvasa in 5% vsebnosti alkohola. Cena za posamezno stekleničko znaša 3€. Priporočena je minimalna uporaba enega piva. Ob kozarčku ali dveh, že sami postanete grki.
                    Ocena piva (11.2/10).
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Mamos;
