import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const Viganj = () => {
    const pageTitle = "Otok Pelješac Viganj";
    const pageDescription = "Otok Pelješac Viganj";

    return (

        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Viganj" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\Viganj.jpg" alt="Otok Pelješac Viganj" className="vinotekaumetnostozadje2" />
            <br></br>
            <center>
                <h3 className="k5" alt="kam na dopust">OTOK PELJEŠAC - HRVAŠKA</h3>
                <p style={{ color: "white" }}>( - Idealna pokrajina, sonček in srfanje - )</p>
                <div>
                    <img src="Slike\DomovSlike\Umetnost\Viganj\slika1.jpg" alt="Viganj Pelješac Hrvaška" className="umetnostpodstranivecslik"  />
                    <img src="Slike\DomovSlike\Umetnost\Viganj\slika2.jpg" alt="Viganj Pelješac Hrvaška" className="umetnostpodstranivecslik"  />
                    <img src="Slike\DomovSlike\Umetnost\Viganj\slika3.jpg" alt="Viganj Pelješac Hrvaška" className="umetnostpodstranivecslik"  />
                </div>
            </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 2.07.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }} alt="Viganj Pelješac Hrvaška"> Otok Pelješac leži na Hrvaškem. Do tja je vožnje z avotomobilom cca.5 ur. Je idelano mesto za dopust in za ljudi, ki imajo radi odmakjeno pokrajno in morje. Med drugimi je tudi srfarski raj, saj je znan po odličnih vetrovih.
                Je majhno mesto z 322 prebivalci, 3 gostilnam, 1 trgovino in čudovito šolo za srfanje. Je odlična potovalna točka prav tako za motoriste kot za dopustnike in kolesarje. Določeni ponudniki
                nudijo v ceno vključeno zajterk in kosilo z domačo kuhinjo in zelenjavo. Cene apartmajev na noč se gibljejo cca.100€ na nočitev. Na rtu Viganja pa imamo tudi razgled na prečudovito Korčulo.
                Definitivno priporočena destinacija za dopust. Ocena (9.9/10).
            </p>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Viganj;