import React, { useState, useRef} from 'react';
import "./Grobnik.css";
import { Helmet } from 'react-helmet';

const Grobnik = () => {
    const [dolzina, setDolzina] = useState(4.168); // Default distance of 4.158 km
    const [minute, setMinute] = useState(0); // Default time of 0 minutes
    const [sekunde, setSekunde] = useState(0); // Default time of 0 seconds
    const [averageSpeed, setAverageSpeed] = useState(null);
    const pageTitle = "Grobnik";
    const pageDescription = "Dirkališče Grobnik";
    const videoRef1 = useRef(null);
    const videoSource1 = "/Slike/DomovSlike/Grobnik/Grobnikvideo.mp4";
    const imageSource1 = "/Slike/DomovSlike/Grobnik/grobnik2.jpg";
    const [isVideoPlaying1, setIsVideoPlaying1] = useState(false);
    const videoTitle1 = "Video 1 : { Grobnik dirkališče }";
    const [isLoading1, setIsLoading1] = useState(false);

    const toggleVideo1 = () => {
        setIsLoading1(true);

        // Access the video element using the ref
        if (videoRef1.current) {
            videoRef1.current.load(); // Start loading the video
        }

        setTimeout(() => {
            if (videoRef1.current) {
                videoRef1.current.play(); // Start playing the video
            }

            setIsLoading1(false);
            setIsVideoPlaying1(true);
        }, 3000); // 3-second delay
    };
    const closeVideo1 = () => {
        setIsVideoPlaying1(false);
        setIsLoading1(false);
    };

    const calculateAverageSpeed = () => {
        const totalMinutes = minute + sekunde / 60;
        const totalTimeInHours = totalMinutes / 60; // Convert minutes to hours
        const averageSpeedResult = dolzina / totalTimeInHours;

        if (!isNaN(averageSpeedResult)) {
            setAverageSpeed(averageSpeedResult);
        } else {
            setAverageSpeed(null);
        }
    };

    return (
        <div className="grobnikkontejner">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Domov/Grobnik" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <h1 className="grobniknaslov">GROBNIK</h1>
            <br></br>
            <p className="grobniktext">- Država : Hrvaška  <img src="/Logotipi/hrvaska.png" alt="Hrvaška" className="ikonedrzave" /> </p>
            <p className="grobniktext">- Lokacija : Automotodrom 1, 51219 Čavle  <img src="/Logotipi/lokacijaikona.png" alt="Grobnik lokacija" style={{ width: "25px" }} /> </p>
            <p className="grobniktext">- Dolžina Proge : 4.168 Km  </p>
            <p className="grobniktext">- Časovni Rekord Proge : 1:26 m (Superbike racing - Ducati Panigale V4 (1103ccm))  </p>
            <p className="grobniktext">- Cena Dirkanja na osebo : cca 120€</p>
            <p className="grobniktext">- Rock "N" Roll : (100%)</p>
            <div className="grobnikokvir">
                <img src="/Slike/DomovSlike/Grobnik/grobnik.png" alt="Grobnik steza" className="grobniksteza" />   
            </div>
             
            <br></br>
            <div>
                {isLoading1 && (
                    <div className="video-overlay" onClick={closeVideo1}>
                        <div className="loading-overlay">
                            <div style={{ color: 'white' }}>
                                <h1>Nalaganje videoposnetka...</h1>
                            </div>
                        </div>
                    </div>
                )}

                {isVideoPlaying1 && (
                    <div className="video-overlay" onClick={closeVideo1}>
                        <video ref={videoRef1} controls style={{ width: '600px' }} preload="auto" loading="lazy" onClick={(e) => e.stopPropagation()}>
                            <source src={videoSource1} type="video/mp4" />
                        </video>
                        <div className="video-title">{videoTitle1}</div>
                    </div>
                )}

                {!isVideoPlaying1 && !isLoading1 && (
                    <div>
                        <img src={imageSource1} alt="Grobnik dirkališče video" onClick={toggleVideo1} loading="lazy" className="videoposnetki" />
                    </div>
                )}
            </div>   
            <br></br>
            <div className="izracunhitrosti">
                <h4>Izračunaj povprečno hitrost dirkališča :</h4>
                <br></br>
                <label>
                    Dolžina (km):
                    <input
                        type="number"
                        value={dolzina}
                        onChange={(e) => setDolzina(Number(e.target.value))}
                    />
                </label>
                <label>
                    Čas (minute):
                    <input
                        type="number"
                        value={minute}
                        onChange={(e) => setMinute(Number(e.target.value))}
                    />
                    <span> in </span>
                    <input
                        type="number"
                        value={sekunde}
                        onChange={(e) => setSekunde(Number(e.target.value))}
                    />
                    <span> Sekunde</span>
                    <br></br>
                </label>              
                <button onClick={calculateAverageSpeed}>Izračun</button>
                {averageSpeed !== null && (               
                    <p>
                        Povprečna Hitrost : {averageSpeed.toFixed(2)} km/h.
                    </p>
                )}
            </div>
        </div>

    );
};

export default Grobnik;
