import React from "react";
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import "../Domov/Domov.css";
import { Helmet } from 'react-helmet';
import axios from 'axios';

const itemsPerPage = 5;

const data = [

    {
        id: 1,
        title: "MOTORISTIČNI IZLET V SOPOTO (ZAGORJE OB SAVI)",
        imageSrc: "/Slike/DomovSlike/Sopota2024/slika1.jpg",
        text: "Lepega sobotnega jutra smo se odpravili na čudovit motoristični izlet v Sopoto. To naselje in cesta se nahaja v Občini Zagorje ob Savi. Tam so nas čakali čudoviti ovinki in sveža cesta. Priporočeno za vse ljubitelje motociklov.",
        altText: "Motoristični izlet v Sopoto Zagorje ob Savi",
        description: "Avtor Fotografije : Rock 'N' Roll.",
        kategorija: "Motoristični izlet.",
        objavaclanka: "3.07.2024",
        path: "/Domov/Sopota2024",
    },
    {
        id: 2,
        title: "KONCERT LEGENDARNE ROKERSKE SKUPINE AC DC 23.06.2024 -VIENNA",
        imageSrc: "/Slike/DomovSlike/Acdc2024/slika1.jpg",
        text: "Dne 26.06.2024 Smo v Dunaju obiskali legendarni rokerski koncert AC DC. Pred nastopom velikana pa smo imeli tudi čast poslušati predskupino The Pretty Reckless. Grmelo je po rokenrolu, slušnih aparatih in cenah.",
        altText: "Legendarni rokerski koncert AC DC",
        description: "Avtor Fotografije : Rock 'N' Roll.",
        kategorija: "Koncerti in umetnost.",
        objavaclanka: "26.06.2024",
        path: "/Domov/Acdc2024",
    },
    {
        id: 3,
        title: "SEJEM PREDELANIH VOZIL UMAG 2024",
        imageSrc: "/Slike/DomovSlike/UmagSejem2024/slika1.jpg",
        text: "Dne 2.05.2024 je v Umagu znova grmelo po predelanih avtomobilih, retro motociklih in po hrvaških cenah. Obiskali smo prosti sejem predelanih avtomobilov, ki se je nahajal v središču Umaga.",
        altText: "Sejem predelanih vozil Umag",
        description: "Avtor Fotografije : Rock 'N' Roll.",
        kategorija: "Sejem predelanih vozil.",
        objavaclanka: "2.05.2024",
        path: "/Domov/UmagSejem2024",
    },
    {
        id: 4,
        title: "MOTORISTIČNI SEJEM (MOTOR BIKE EXPO 2023) VERONA",
        imageSrc: "/Slike/DomovSlike/Verona2023/slika1.jpg",
        text: "Obiskali smo motoristični sejem -Motor Bike Expo 2023 v Veroni. Veliko boljši, rokerski in ugodnejši od Eicme. Kljub Italijanskim cenam, se je dalo zabavati in občudovati predelane motocikle.",
        altText: "Motoristicni sejem Motor Bike Expo 2023 Verona,Italija",
        description: "Avtor Fotografije : Rock 'N' Roll.",
        kategorija: "Motoristični Sejem.",
        objavaclanka: "10.01.2024",
        path: "/Domov/Verona2023",
    },
    {
        id: 5,
        title: "MOTORISTIČNI SEJEM (EICMA 2023) MILANO",
        imageSrc: "/Slike/DomovSlike/Eicma2023/africatwin2023.jpg",
        text: "Po dolgem času smo na žalost obiskali največji Evropski sejem motociklov (Eicma) v Milanu. Vino z leti pridobiva na vrednosti, vendar za ta sejem ne moremo povedati enako. ",
        altText: "Motoristicni sejem Eicma 2023 Milano,Italija",
        description: "Avtor Fotografije : Rock 'N' Roll.",
        kategorija: "Motoristični Sejem.",
        objavaclanka: "07.01.2024",
        path: "/Domov/Eicma2023",
    },
    {
        id: 6,
        title: "DIRKALIŠČE GROBNIK HRVAŠKA",
        imageSrc: "/Slike/DomovSlike/Grobnik/grobnik2.jpg",
        text: "V tej rubriki bomo opisali podrobnosti dirkališča Grobnik na Hrvaškem. Uporabniki si lahko izračunate povprečno hitrost na dirkališču Grobnik in ostalih dirkališč.",
        altText: "Dirkalisce Grobnik, Hrvaska",
        description: "Avtor Fotografije : Rock 'N' Roll.",
        kategorija: "Dirkališče.",
        objavaclanka: "19.01.2024",
        path: "/Domov/Grobnik",
    },
    {
        id: 7,
        title: "PORTAL ZA MOTOCIKLIZEM IN UMETNOST",
        imageSrc: "/Slike/DomovSlike/welcomepage.png",
        text: "V tem članku se bomo spustili v podrobnosti delovanja tega portala. Katere vsebine bodo objavljene in možnosti uporabe portala za registriranega uporabnika.",
        altText: "Portal za motociklizem in umetnost",
        description: "Avtor Fotografije : Rock 'N' Roll",
        kategorija: "Dobrodošlica za Uporabnike.",
        objavaclanka: "02.01.2024",
        path: "/Domov/Dobrodoslica",
    },
    // Add more items as needed
];

function Domov() {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const [pageViews, setPageViews] = useState({});
    const pageTitle = "Najnovejše novice";
    const pageDescription = "Motoristične novice";

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            window.scrollTo(0, 0);
            setCurrentPage(currentPage + 1);
            updateUrl(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            window.scrollTo(0, 0);
            setCurrentPage(currentPage - 1);
            updateUrl(currentPage - 1);
        }
    };

    const updateUrl = (page) => {
        const newUrl = `/domov?page=${page}`;
        window.history.pushState({}, '', newUrl);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pageParam = parseInt(urlParams.get('page')) || 1;
        setCurrentPage(pageParam);
    }, []);

    const currentItems = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');

    let interval = useRef();

    const startTimer = () => {
        const countdownDate = new Date('July 26, 2024 00:00:00').getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval.current)

            } else {
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        }, 1000);
    }
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    })

    document.addEventListener('contextmenu', event => {
        event.preventDefault();
    });

    const [linkCount, setLinkCount] = useState(0);

    useEffect(() => {
        setLinkCount(data.length);
    }, []);

    useEffect(() => {
        data.forEach(item => {
            axios.get(`/api/PageView/pageview/${item.id}`).then(response => {
                setPageViews(prevState => ({ ...prevState, [item.id]: response.data }));
            });
        });
    }, []);

    const incrementPageView = (pageId) => {
        axios.post(`/api/PageView/pageview/${pageId}`).then(() => {
            setPageViews(prevState => ({ ...prevState, [pageId]: (prevState[pageId] || 0) + 1 }));
        });
    };


    return (
        <div>          
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <h3 className="naslovnica">(NAJNOVEJŠE NOVICE)</h3>
            <img src="Slike\DomovSlike\Umetnost\umetnostozadje.jpg" alt="" className="umetnostozadje" />
            <p className="steviloclankovtext" alt="olimpijske igre 2024">Število objavljenih člankov : ( {linkCount} ) <img src="/logotipi/zemlja.png" alt="komentar" className="domovzramnaslovaslika" /> ( Olimpijske igre 2024 čez : {timerDays} Dni : {timerHours} Ur :  {timerMinutes} Minut :  {timerSeconds} sekund : ) <img src="/Logotipi/olimpic.png" alt="olimpijske igre" style={{ width: "30px" }} /></p>
            <div className="globalni-kontejner">               
                {currentItems.map((item) => (
                    <div className="domovcontainerwithborder" key={item.id}>
                        <div className="domovimagetextcontainer">
                            <Link to={item.path} className="domovimagecontainer" onClick={() => incrementPageView(item.id)}>
                                <img src={item.imageSrc} alt="m" className="domovimage" />
                            </Link>
                            <div className="domovrighttext">
                                <Link to={item.path} className="title-style" onClick={() => incrementPageView(item.id)}>
                                    {item.title}  <img src="/Logotipi/lokacijaikona.png" alt="komentarji" style={{ width: "20px" }} />
                                </Link>                               
                                <h6 style={{ marginTop: "10px" }}>{item.text}</h6>
                                <br></br>
                                <p>{item.description}</p>
                                <p>Kategorija: {item.kategorija}</p>
                                <p>Članek Objavljen: {item.objavaclanka}  <img src="/Logotipi/komentar.png" alt="komentarji" style={{ width: "30px" }} /></p>
                                <p>Ogledov strani : ({pageViews[item.id] || 0}) <img src="/logotipi/ogledi.png" alt="ogledi" style={{ width: "30px" }} /></p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="pagination">
                {currentPage > 1 && (
                    <button onClick={handlePreviousPage}>Prejšna Stran</button>
                )}
                <span>{`Stran ${currentPage} od ${totalPages}`}</span>
                {currentPage < totalPages && (
                    <button onClick={handleNextPage}>Naslednja Stran</button>
                )}
            </div>
        </div>
    );
}

export default Domov;