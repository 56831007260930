import React from "react";
import "./Donacije.css";

const Donacije = () => {

    return (

        <div className="donacijekontejner">
            <br></br>
            <h2 className="domovfootertext">Omogoči Delovanje Portala v Bitcoin :  <img src="/Logotipi/donacije.png" alt="motolegende" className="donacijeikona" /></h2>
                <br></br>
                <img src="/Logotipi/kodazabinance.png" className="" loading="lazy" alt="kriptovaluta" style={{ width: "320px" }} />
                <p className="">1. -  Odpri spletno denarnico za Kriptovalute (Binance)</p>
            <p className="">2. -  Skeniraj prikazano QR Kodo</p>
            <p className="">3. -  Doniraj 0.0003 Bitcoin</p>

                <br></br>
                <p className="">© Powered by Rock 'N' Roll </p>
                <img
                    src="/Logotipi/Motolegende.png"
                    alt="Motolegende"
                    className="donacijaslika"
                    loading="lazy"
                    style={{ width: '250px' }}
                />
         </div>
    );
};

export default Donacije;