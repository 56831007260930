import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const Email = () => {
    const [email, setEmail] = useState({
        to: '',
        link: '',
    });
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Set default values for link when the component mounts
        setEmail((prevEmail) => ({
            ...prevEmail,
        }));
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("/api/email/sendemail", email);
            const resetCode = response.data.resetCode;

            alert("Email sent successfully!");

            // Maximum number of attempts
            const maxAttempts = 5;
            let attempts = 0;

            while (attempts < maxAttempts) {
                const userEnteredPassword = prompt('Vnesite kodo poslano na Email :');

                if (userEnteredPassword === resetCode) {
                    alert('Koda uspesno vnesena!');
                    // Navigate to the desired page with email as state
                    navigate('/Vpis/Email/PreverjanjeGesla/MenjavaGesla', { state: { email: email.to } });
                    break; // Exit the loop if the code is correct
                } else {
                    alert(`Napacna koda. Imate se ${maxAttempts - attempts - 1} poskusov.`);
                    attempts++;
                }
            }

            if (attempts === maxAttempts) {
                alert('Maximum vnesenih poskusov. Poskusite ponovno pozneje.');
                // Add logic to handle the maximum attempts scenario
            }
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email. Please try again.');
        }
    };

    return (
        <div style={{ color: "white", margin: "5% auto 0", width: "320px" }}>
            <form onSubmit={handleSubmit}>
                <p>Vnesite svoj Email naslov za pridobitev kode :</p>
                <label>Email:</label>
                <input
                    type="email"
                    name="to"
                    value={email.to}
                    onChange={(e) => setEmail((prevEmail) => ({ ...prevEmail, to: e.target.value }))}
                    required
                />

                <button type="submit">Pošlji Email</button>
            </form>
        </div>
    );
};

export default Email;