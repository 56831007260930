import React, { useEffect, useState } from 'react';
import "./Kontakt.css";

const Kontakt = () => {
    const [showLink, setShowLink] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if the 'z' key is pressed
            if (event.key === 'o') {
                // Show the link
                setShowLink(true);
            }
        };

        const handleKeyUp = (event) => {
            // Check if the 'z' key is released
            if (event.key === 'o') {
                // Hide the link
                setShowLink(false);
            }
        };

        // Add a keydown event listener to the window
        window.addEventListener('keydown', handleKeyDown);
        // Add a keyup event listener to the window
        window.addEventListener('keyup', handleKeyUp);

        // Clean up the event listeners when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return (
        <div className="globalni-kontejner">
            <div className="kontakt">
                <h3>Kontaktni Podatki : </h3>
                <br></br>
                <p>E-MAIL :  info@motolegende.si</p>      
                <img
                    src="/Logotipi/Motolegende.png"
                    alt="Motolegende kontakt"
                    className="podstranilogoslika"
                />
                <p className="domovfootertext">© Powered by Rock 'N' Roll </p>
            </div>
            <div>
                {showLink && (
                    <a href="/Kontakt/AdministratorVpis" className="kontaktnilink">
                        .
                    </a>
                )}
            </div>
        </div>
      
    );
};

export default Kontakt;