import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const Loisium = () => {
    const pageTitle = "Loisium vinski spa Avstrija";
    const pageDescription = "Loisium vinski spa Avstrija";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Loisium" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\loisium.jpg" alt="Loisium vinograd" className="vinotekaumetnostozadje2" />
            <br></br>
            <center><h1 className="k5" alt="Loisium Wine Spa">LOISIUM VINSKI SPA - AVSTRIJA</h1></center>
            <center><p className="k5" alt="Destinacija za vse ljubitelje vina">( - Destinacija za vse ljubitelje vina - )</p></center>
            <p className="k4">- Objavljeno dne :  9.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Loisium\slika1.jpg" alt="Loisium Avstrija" className="k3slika" />
                <p className="k2">Loisium je hotelski resort, ki se nahaja v Avstriji. Imajo 3 hotele, dva sta na lokaciji Avstrije in eden v Franciji. Imajo bogato ponudbo hotelskih sob, bazenov, vinov in šampanjcev.
                Je rajski kraj za ljubitelje vina kjer si lahko rezervirajo tudi degustacijo. V avstriji imajo mobilno postojanko v središču vinograda, kjer si lahko ustvarite svoj lasten zen.
                </p>
            </div>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Loisium;