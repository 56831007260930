import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const OglasiMotorjevMotor = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const location = useLocation();
    const { state } = location;

    if (!state || !state.oglas) {
        return <p>No oglas data found.</p>;
    }

    const { znamka, model, prostornina, kilovati, konji, cena, letnik, prevozeniKilometri, lastnik, pogon, krajOgleda, gsmStevilka, opisMotorja, uporabniskoImeOrEmail, vrstaMotorja, vrstaMotorista, imageUrl1, imageUrl2, imageUrl3 } = state.oglas;

    const openImageModal = (image) => {
        setSelectedImage(image);
    };

    const closeImageModal = () => {
        setSelectedImage(null);
    };

    
    return (

        <div className="oglasimotorjevmotorkontejner">
            <div className="images-and-text-container">
                <div className="oglasimotorjevmotorgalerija">
                    <img onClick={() => openImageModal(imageUrl1)} className="oglasimotorjevmotorslike" src={imageUrl1} alt="oglas motorja" loading="lazy" />
                    <img onClick={() => openImageModal(imageUrl2)} className="oglasimotorjevmotorslike" src={imageUrl2} alt="oglas motorja" loading="lazy" />
                    <img onClick={() => openImageModal(imageUrl3)} className="oglasimotorjevmotorslike" src={imageUrl3} alt="oglas motorja" loading="lazy" />            
                </div>

                {selectedImage && (
                    <div className="oglasimotorjevnovaslika" onClick={closeImageModal}>
                        <div className="slikamodal">
                            <img className="celotnaslikamodal" src={selectedImage} alt="celotnaslika" />
                        </div>
                    </div>
                )}
            </div>

            <div className="oglasimotorjevmotortext">
                <h3 className="oglasimotorjevmotornaslovmotorja">{znamka}&nbsp;&nbsp;&nbsp;{model}</h3>
                <br></br>
                <p className="oglasimotorjevmotornaslovitext">MODEL MOTORJA :</p>
                <p className="oglasimotorjevmotordata">{model}</p>
                <p className="oglasimotorjevmotornaslovitext">PROSTORNINA MOTORJA :</p>
                <p className="oglasimotorjevmotordata">{prostornina + " (ccm) "}</p>
                <p className="oglasimotorjevmotornaslovitext">MOČ MOTORJA: :</p>
                <p className="oglasimotorjevmotordata">{kilovati + "(kW) " + konji + "(HP)"}</p>
                <p className="oglasimotorjevmotornaslovitext">CENA MOTORJA € :</p>
                <p className="oglasimotorjevmotordata">{cena + " €"}</p>
                <p className="oglasimotorjevmotornaslovitext">LETNIK MOTORJA :</p>
                <p className="oglasimotorjevmotordata">{letnik}</p>
                <p className="oglasimotorjevmotornaslovitext">ŠTEVILO PREVOŽENIH KILOMETROV :</p>
                <p className="oglasimotorjevmotordata">{prevozeniKilometri + " (Km) "}</p>
                <p className="oglasimotorjevmotornaslovitext">ŠTEVILO LASTNIKOV MOTORJA :</p>
                <p className="oglasimotorjevmotordata">{lastnik}</p>
                <p className="oglasimotorjevmotornaslovitext">VRSTA POGONA MOTORJA :</p>
                <p className="oglasimotorjevmotordata">{pogon}</p>
                <p className="oglasimotorjevmotornaslovitext">KRAJ OGLEDA :</p>
                <p className="oglasimotorjevmotordata">{krajOgleda}</p>  
                <p className="oglasimotorjevmotornaslovitext">OPIS MOTORJA :</p>
                <p className="oglasimotorjevmotordata">{opisMotorja}</p>
                <p className="oglasimotorjevmotornaslovitext">VRSTA MOTORJA :</p>
                <p className="oglasimotorjevmotordata">{vrstaMotorja}</p>
                <p className="oglasimotorjevmotornaslovitext">ZA KATERE VRSTE MOTORISTA JE MOTOR :</p>
                <p className="oglasimotorjevmotordata">{vrstaMotorista}</p>
                <p className="oglasimotorjevmotordata">Uporabnik : {uporabniskoImeOrEmail}</p>
                <p className="oglasimotorjevmotordata">GSM ŠTEVILKA : {gsmStevilka}</p>
                <br></br>
            </div>
         
        </div>
    );
};

export default OglasiMotorjevMotor;
