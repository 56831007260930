import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MenjavaGesla = () => {
    const [newPassword, setNewPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const location = useLocation();
    const email = location.state ? location.state.email : '';
    const navigate = useNavigate();

    const handleResetPassword = async () => {
        try {
            const response = await fetch(`/api/registracija/reset-password/${email}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ NovoGeslo: newPassword }),
            });

            if (response.ok) {
                // Password reset successfully, handle as needed (e.g., redirect, show success message)
                setSuccessMessage('Geslo Uspesno Spremenjeno! Preusmerjanje v 3 sekundah...');
            } else {
                const data = await response.json();
                // Handle error, show error message to the user
                console.error(data.message);
            }
        } catch (error) {
            console.error('Error resetting password:', error);
        }
    };

    useEffect(() => {
        if (successMessage) {
            // Redirect after 5 seconds
            const timer = setTimeout(() => {
                navigate('/Vpis'); // Replace 'your-redirect-path' with the actual path
            }, 3000);

            // Clear the timer on component unmount
            return () => clearTimeout(timer);
        }
    }, [successMessage, navigate]);

    return (

        <div style={{ color: "white", textAlign: "center" }}>
        <br></br>
            <p>Uporabnik : {email}</p>
            <br></br>
            <p>Vnesite svoje novo geslo :</p>
            <label>Novo Geslo : <input type="text" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} /></label>
            <button onClick={handleResetPassword}>Ponastavi Geslo</button>
            {successMessage && <p>{successMessage}</p>}
        </div>
    );
};

export default MenjavaGesla;