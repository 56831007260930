import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const Stupice = () => {
    const pageTitle = "Srfanje v kampu Stupice Hrvaška";
    const pageDescription = "Srfanje v kampu Stupice Hrvaška";

    return (

        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Stupice" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\KampStupice.jpg" alt="Kamp Stupice Hrvaška" className="vinotekaumetnostozadje2" />
            <center>
                <h3 className="k5" alt="Kamp Stupice Hrvaška">KAMP STUPICE - HRVAŠKA</h3>
                <p style={{ color: "white" }}>( - Srfanje, Kokteli in Rokenroll - )</p>
                <div>
                    <img src="Slike\DomovSlike\Umetnost\Stupice\slika1.jpg" alt="Kamp Stupice Hrvaška" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\Stupice\slika2.jpg" alt="Kamp Stupice Hrvaška" className="umetnostpodstranivecslik" />  
                    <img src="Slike\DomovSlike\Umetnost\Stupice\slika3.jpg" alt="Kamp Stupice Hrvaška" className="umetnostpodstranivecslik" />
                           
                </div>

            </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 11.07.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }} alt="Kamp Stupice Hrvaška"> Kamp Stupice leži na Hrvaški obali. Je odlična srfarska destinacija z odličnimi vetrovi in kamp destinacijo. V zadnjih letih so se cene kampa zvišale, sedaj je potrebno odšteti 60€ na nočitev z šotorom.
             Še nekaj doplačila je, če imaste dodatno prikolico. V središču samega kampa se nahaja srf šola, ki jo vodi eden in edini legendarni Boris. Na izposojo imajo srfe, jadrnice in tubo. Rezervirate si lahko tudi srf tečaje. Zraven srf šole se nahajajo postojanke z sladicami za vse sladkosnede. 
             In seveda rampo za čolne. Če ste ljubitelj športnih aktivnosti, je na voljo nogometno in tenis igrišče ter trampolini. In seveda po napornem srfarskem dnevu imate tudi na voljo masaže.
            </p>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Stupice;