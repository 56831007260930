import React from "react";
import { Link, Outlet } from "react-router-dom";
import { useEffect, useState } from 'react';
import UniversalCookie from 'universal-cookie';


const Domov = () => {

    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const cookies = new UniversalCookie();

    useEffect(() => {
        const authToken = cookies.get('Testni') && cookies.get('Testni').token;
        const storedUsernameOrEmail = cookies.get('Testni') && cookies.get('Testni').username;

        if (authToken && storedUsernameOrEmail) {
            setUsernameOrEmail(storedUsernameOrEmail);
        }
    }, []);


    return (
        <>
            {/* <center><div><p>[{usernameOrEmail}]</p></div></center> */}
            <Outlet />
        </>
    );
};

export default Domov;
