import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const SvetiStefan = () => {
    const pageTitle = "Luksuzna destinacija Sveti Štefan Črna Gora";
    const pageDescription = "Luksuzna destinacija za potovanje Sveti Štefan Črna Gora";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/SvetiStefan" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\SvetiStefan.jpg" alt="Sveti Štefan Črna Gora" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Sveti Štefan">SVETI ŠTEFAN - ČRNA GORA</h1></center>
            <center><p className="k5" alt="Luksuzna destinacija">( - Luksuzna destinacija bogatašev - )</p></center>
            <p className="k4">- Objavljeno dne :  5.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\SvetiStefan\slika1.jpg" alt="Sveti Štefan Črna Gora" className="k3slika" />
                <p className="k2">Sveti štefan je turistično naselje v Črni Gori, ki spada pod občino Budva. Tam prebiva okoli 400 prebivalcev in spada med ene najbolj lukszuznih letovišč na jadranskem morju.
                    Obiskale so ga številne znane osebnost, od filmskih zvezd, politikov in športa. Hotelske cene pred otokom se gibljejo okoli 100€ na nočitev, vendar če se pa odločimo prenočiti na polotoku pa bomo odšteli 300€+ na nočitev.                   
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default SvetiStefan;