import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const Tesseron = () => {
    const pageTitle = "Najboljši konjak Tesseron";
    const pageDescription = "Tesseron konjak";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/Tesseron" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="Vinoteka klet" className="vinotekaumetnostozadje2"/>
            <center><h1 className="k5" alt="Tesseron konjak">TOP KONJAK - TESSERON</h1></center>
            <center><p className="k5" alt="odličen konjak Tesseron">( - Idealni konjak za vse priložnosti - )</p></center>
            <p className="k4">- Objavljeno dne :  15.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika8.jpg" alt="Konjak Tesseron" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Tesseron je francoski konjak, ki je bil narejen leta 1905 od Abel Tesserona. Čeprav je med dražjimi konjaki, okus pove svoje. Kupimo ga lahko v 700ml steklenici in vsebuje 40% delež vsebnosti alkohola.
                Za posamezno steklenico bomo morali odšteti 150€. Vendar je to investicija, ki je prav tako ne pozabimo iz finančnega vidika kot okusa. Ocena konjaka (12.3/10).
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Tesseron;
