import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import "./DomovPodstrani.css";
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const UmagSejem2024 = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const pageId = 'UmagSejem2024';
    const pageTitle = "Umag Sejem 2024";
    const pageDescription = "Sejem predelanih avtomobilov";

    // SLIKE
    //

    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'auto' // Use 'auto' for an instant jump to the top
        });
    }, []);

    const [fullImageSrc, setFullImageSrc] = useState(null);
    const [selectedImageTitle, setSelectedImageTitle] = useState('');

    const openFullImage = (src, title) => {
        setFullImageSrc(src);
        setSelectedImageTitle(title);
    };

    const closeFullImage = () => {
        setFullImageSrc(null);
        setSelectedImageTitle('');
    };


    useEffect(() => {
        // Fetch comments from the API and set them in reverse order
        axios.get(`/api/Komentar/komentar?pageId=${pageId}`)
            .then((response) => {
                setComments(response.data.reverse());
            })
            .catch((error) => {

            });

        // Check for cookies
        const cookies = new Cookies();
        const motolegendeCookie = cookies.get('Motolegende');
        const adminCookie = cookies.get('adminCookie');

        // Set isLoggedIn to true if either cookie exists
        if (motolegendeCookie || adminCookie) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleSubmitComment = () => {
        const cookies = new Cookies();
        const user = cookies.get('Motolegende');
        const admin = cookies.get('adminCookie');

        // Check isLoggedIn and comment content
        if ((user || admin) && newComment.trim() !== '') {
            const commentData = {
                userId: user ? user.id : admin.id,
                username: user ? user.username : admin.Administrator, // Use 'administrator' field as username
                content: newComment,
                timestamp: moment().format(),
                pageId: pageId,
            };
            // Send the new comment to the API with the pageId parameter
            axios.post(`/api/Komentar/createkomentar?pageId=${pageId}`, commentData)
                .then((response) => {
                    // Add the new comment to the top of the list
                    setComments([response.data, ...comments]);
                    setNewComment('');
                })
                .catch((error) => {

                });
        } else {

        };
    };

    const deleteComment = (commentId) => {
        axios
            .delete(`/api/Komentar/deletekomentar/${commentId}`)
            .then(() => {
                // Remove the deleted comment from the state
                setComments(comments.filter(comment => comment.id !== commentId));
            })
            .catch((error) => {

            });
    };

    return (
        <div className="okvirkontejnerja">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Domov/UmagSejem2024" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <div className="vsebinakontejnerja">
                <div className="levikontejner">
                    <div className="large-image-container">
                        <img
                            src="/Slike//DomovSlike/UmagSejem2024/slika1.jpg"
                            alt="Umag sejem"
                            className="podstrandomovimage"
                            onClick={() => openFullImage('/Slike//DomovSlike/UmagSejem2024/slika1.jpg', "Slika 1 : ( Središče mesta Umag )")}
                            loading="lazy"
                        />
                        <br></br>
                        <p className="podstranislikeopis">Slika 1 : (Središče mesta Umag.) </p>
                    </div>
                    <div className="small-images-container">
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/UmagSejem2024/avto1.jpg" alt="Predelani Audi-R8" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/UmagSejem2024/avto1.jpg", "Slika 2 : ( Predelani Audi-R8. )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2 : (Predelani Audi-R8.)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/UmagSejem2024/avto2.jpg" alt="Predelana Opel-Manta" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/UmagSejem2024/avto2.jpg", "Slika 3 : ( Predelana Opel-Manta. )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 3 : (Predelana Opel-Manta.)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/UmagSejem2024/avto3.jpg" alt="Predelani Porsche-Carrera" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/UmagSejem2024/avto3.jpg", "Slika 4 : ( Predelani Porsche-Carrera )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 4 : (Predelani Porsche-Carrera)</p>
                        </div>
                        {fullImageSrc && (
                            <div className="full-image-overlay" onClick={closeFullImage}>
                                <img src={fullImageSrc} alt="celotnaslika" className="full-img" />
                                <h5 className="koizbereslikoopis">{selectedImageTitle}</h5>
                            </div>
                        )}
                    </div>

                    <div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/UmagSejem2024/avto4.jpg" alt="Predelan golf enka" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/UmagSejem2024/avto4.jpg", "Slika 5 : ( Predelan Golf 1 )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 5 : (Predelan Golf-1)</p>
                        </div>                    
                    </div>

                    <br></br>
                    <p className="podstranislikeopis">Avtor Fotografij : Rock 'N' Roll. </p>
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Verona2023" rel="noopener noreferrer">Motoristični sejem MBE (Motor Bike Expo 2023)</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Verona2023/slika3.jpg" width="150px;"/>
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Grobnik" rel="noopener noreferrer">Dirkališče Grobnik - Hrvaška</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Grobnik/grobnik2.jpg" width="150px;" />
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Eicma2023" rel="noopener noreferrer">Motoristični sejem EICMA 2023 (Milano)</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Eicma2023/africatwin2023.jpg" width="150px;" />
                    <br></br>

                    <img
                        src="/Logotipi/Motolegende.png"
                        alt="Rock 'N' Roll"
                        className="podstranilogoslika"
                    />
                    <p className="podstranilogoslikaime">Powered From 2024</p>
                </div>
                <div className="desnikontejner">
                    <h1 className="naslovzapodnaslovdomov">SEJEM PREDELANIH AVTOMOBILOV UMAG (2.05.2024)</h1>
                    <br></br>
                    <h6>- Država : Hrvaška  <img src="/Logotipi/hrvaska.png" alt="Italija" className="ikonedrzave" /> </h6>
                    <h6>- Lokacija : 52470-Umag <img src="/Logotipi/motolegendeikonamini.png" alt="Verona" style={{ width: "45px" }} />  </h6>
                    <h6>- Naslov : Obala Josipa Broza Tita (Središče Umaga)  <img src="/Logotipi/lokacijaikona.png" alt="Verona" style={{ width: "25px" }} /></h6>
                    <p className="desnikontejnertext">
                        Dne 2.05.2024 smo obiskali sejem predelanih avtomobilov in motociklov v Umagu (Hrvaška). Vstopnine ni, ker se sejem odvijal v središču samega Umaga ob cesti - (Obala Josipa Broza Tita). Cena parkinga v Umagu znaša cca 1,30€ /h, (verjetno predsezonska cena). Cena za posamezno malo pivo pa je znašala cca. 4€ in sladoled cca. 2€, da se o hrani sploh ne menimo. Obiskovalci, ki so prikazovali svoje predelane avtomobile
                        so se večinoma zbrali iz držav Evrope, kjer so predvsem izstopala vozila iz držav Nemčije, Nizozemske, Velike Britanije in Balkana.
                        Glede predelanih avtomobilov so izstopale predvsem nemške znamke : Mercedes Benz (280-SE), Porsche (Carrera), Audi (R8), BMW (M-Power), Volkswagen (Golf 1-2), Opel (Manta). Presenečeni pa smo bili glede predelanih avtomobilov KIA, Ford, Toyota in Subaru.
                        Za enodnevni izlet na morje s prijatelji ali družino se splača ustaviti v Umagu in si z pivom ali sladoledom v roki ogledati ta sejem. Kar se zadeva pa cen,bližje ko si morju - dražje je, zato ne padit na prvo žogo v centru Umaga, saj je v okolici še veliko restavracij z bolj razumnimi cenami.
                        Ob koncu sejma so avtomobilistični navdušenci poskrbeli, da se je v okolici Umaga slišalo pokanje in hrumenje V8 mašin in turbin. Vzdušje pa so tudi pričarali kombi-štanti z raznorazni reklamnimi panoji in glasbo, kot v filmih Fast & Furious.
                        Ocena sejma: Rock "N" Roll (9.5).
                        <img src="/Logotipi/vsecek2.png" alt="motolegende" className="vsecekikona" />
                    </p>
                    <p>Članek objavljen : 2.05.2024</p>
                    <div className="komentarcelotnikontejner">
                        <h3 alt="klepetalnica">KLEPETALNICA : </h3>
                        <p>Število Komentarjev: {comments.length}</p>
                        <div className="komentarlist">
                            {comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort comments by timestamp in descending order
                                .map((comment) => (
                                    <div key={comment.id} className="komentar">
                                        <strong className="uporabnikime">{comment.username}</strong>: {comment.content}
                                        <br />
                                        <span className="timestamp">{moment(comment.createdAt).format("DD MMMM YYYY  HH:mm")}</span>
                                    </div>
                                ))}
                        </div>
                        <div className="komentarinput">
                            {isLoggedIn ? (
                                <div>
                                    <textarea
                                        rows="3"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        className="komentartextarea"
                                        placeholder="Komentiraj Vsebino ..."
                                    />
                                    <br />
                                    <button onClick={handleSubmitComment} className="komentarbutton">Pošlji Komentar</button>
                                </div>
                            ) : (
                                <p>Če želite komentirati, se prijavite...</p>
                            )}
                        </div>
                    </div>
                    {isLoggedIn && adminCookie && comments.map((comment) => (
                        <div key={comment.id} className="komentar">
                            <strong>{comment.username}</strong>: {comment.content}
                            <br />
                            <span className="timestamp">{moment(comment.timestamp).format("LLL")}</span>
                            <button onClick={() => deleteComment(comment.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default UmagSejem2024;