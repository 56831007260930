import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';


const Courvoisier = () => {
    const pageTitle = "Najboljši konjak Courvoisier";
    const pageDescription = "Courvoisier konjak";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/Courvoisier" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="Vinoteka klet" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Courvoisier Viski">KONJAK ZA GENTLEMANE - Courvoisier</h1></center>
            <center><p className="k5" alt="Courvoisier Viski">( - Cigara, konjak in dan se začne - )</p></center>
            <p className="k4">- Objavljeno dne :  10.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika7.jpg" alt="Courvoisier konjak" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Courvoisier konjak spada med ene izmed boljših kvalitet konjaka. Prvič so ga naredili leta 1835 v Franciji. Je bila ena izmed popularnejših pijač Napoleona.
                    Prihaja v 700ml steklenici, ki jo za njo treba odšteti 30€. Vsebuje 40% alkoholne vrednosti in karakterja. Ob vsakem požirku konjaka, ste bližje temu, da se ne sekirate za vsako položnico in uživate v trenutku kot gentleman.
                    Je idealno darilo za posebno priložnost ali ločitev. Ocena konjaka (11.3/10).
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default Courvoisier;