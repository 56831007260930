import React from 'react';
import Cookies from 'universal-cookie';
import './Header.css';
import { Helmet } from 'react-helmet';

const Header = () => {
    const cookies = new Cookies();
    const isLoggedIn = !!cookies.get('Motolegende');
    const adminCookie = cookies.get('adminCookie');

    const pageTitle = "Motolegende";
    const pageDescription = "Motoristicne novice, Oddaja oglasa, Moto avanture, Vinoteka, Kam na dopust, Srfanje";

    return (
        
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content="motorji, motoristični oglasi, moto avanture, motoristične novice, Moto legende, Kam na dopust, Vinoteka , Srfanje" />
                <meta name="author" content="Motolegende" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <header className="header">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <a href="/">
                            <img src="/Logotipi/Motolegende1.png" className="motologotip" alt="Moto legende" />
                        </a>
                    </div>
                </div>
                {/* Add a container for the links */}
                <div style={{ textAlign: 'right' }}>
                    {!isLoggedIn ? ( // Show links if the user is not logged in
                        <>
                            <a href="/Vpis" className="links">PRIJAVI SE</a>
                         
                        </>
                    ) : (
                        null
                    )}
                </div>
                {adminCookie && <a href="/Kontakt/Administrator">ADMINISTRATOR</a>}  

                <a href="https://www.youtube.com/watch?v=l2943ZzmvZI" target="_blank" rel="noopener noreferrer" className="youtubelink">
                    <p className="youtubetext">Youtube kanal   <img src="Logotipi/Playbutton.png" className="youtubegumb" alt="motolegende" /> </p>                  
                </a>
        
              
            </header>
        </div>
    );
};

export default Header;