import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UniversalCookie from 'universal-cookie';
import './UrediOglas.css'; // Import the CSS file


const UrediOglas = () => {
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [selectedOglas, setSelectedOglas] = useState(null);
    const [imageUrls, setImageUrls] = useState(['', '', '']);
    const [files, setFiles] = useState([null, null, null]);
    const [successMessages, setSuccessMessages] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const cookies = new UniversalCookie();

        const authToken = cookies.get('Motolegende')?.token;
        const storedUporabniskoImeOrEmail = cookies.get('Motolegende')?.username;

        if (authToken && storedUporabniskoImeOrEmail) {
            axios
                .get(`/api/Oglas/getoglasbyuser?uporabniskoImeOrEmail=${storedUporabniskoImeOrEmail}`)
                .then((response) => {
                    setData(response.data);
                
                })
                .catch((error) => {
           
                });
        }
    }, []);

    const handleChange = (e, index, field) => {
        const { name, value } = e.target;

        setData((prevData) => {
            const newData = [...prevData];
            const updatedOglas = { ...newData[index], [field || name]: value };
            newData[index] = updatedOglas;
            return newData;
        });
    };

    const handleUpdateDetails = async (oglasId, index) => {
        const cookies = new UniversalCookie();
        const authToken = cookies.get('Motolegende')?.token;
        const storedUporabniskoImeOrEmail = cookies.get('Motolegende')?.username;

        try {
            // Create an object with only the fields you want to update
            const updateData = {
                ...formData[index],
                znamka: data[index].znamka || '',
                model: data[index].model || '',
                prostornina: data[index].prostornina || '',
                kilovati: data[index].kilovati || '',
                konji: data[index].konji || '',
                cena: data[index].cena || '',
                letnik: data[index].letnik || '',
                prevozeniKilometri: data[index].prevozeniKilometri || '',
                lastnik: data[index].lastnik || '',
                pogon: data[index].pogon || '',
                krajOgleda: data[index].krajOgleda || '',
                gsmStevilka: data[index].gsmStevilka || '',
                opisMotorja: data[index].opisMotorja || '',
                vrstaMotorja: data[index].vrstaMotorja || '',
                vrstaMotorista: data[index].vrstaMotorista || '',
                UporabniskoImeOrEmail: storedUporabniskoImeOrEmail,
                ImageUrl1: imageUrls[0], // Update to use imageUrls array
                ImageUrl2: imageUrls[1], // Update to use imageUrls array
                ImageUrl3: imageUrls[2], // Update to use imageUrls array
                ImageData1: files[0] || '', // Update to use files array
                ImageData2: files[1] || '', // Update to use files array
                ImageData3: files[2] || '',
                // Ensure the field is not undefined
                // Add more fields as needed
            };

            // Send a PUT request to update the details on the server
            await axios.put(`/api/Oglas/oglas/${oglasId}`, updateData);

            // Optionally, you can fetch and update the data again to reflect the changes
            const updatedData = await axios.get(`/api/Oglas/getoglasbyuser?uporabniskoImeOrEmail=${storedUporabniskoImeOrEmail}`);
            setData(updatedData.data);


            // Clear the form data
            setFormData({});
         
            // Update success message for the specific oglas
            setSuccessMessages((prevMessages) => ({
                ...prevMessages,
                [oglasId]: 'Oglas uspešno posodobljen!',
            }));

            // Reload the window after 4 seconds
            setTimeout(() => {
                window.location.reload();
            }, 4000);

        } catch (error) {
            
        }
    };


    //zbris oglas
    const handleDelete = (id) => {
        axios
            .delete(`/api/Oglas/deleteoglas/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    // Remove the item from the data
                    setData((prevData) => prevData.filter((item) => item.id !== id));
                    // Set the success message
                    setSuccessMessage('Oglas uspešno izbrisan.');
                    window.location.reload();
                }
            })
            .catch((error) => {
            
            });
    };


    //
    const handleImageChange = (e, index, inputIndex) => {
        const newFiles = [...files];

        // Clear the existing file data for all three images
        for (let i = 0; i < 3; i++) {
            newFiles[index * 3 + i] = null;
        }

        // Set the new file data
        if (e.target.files.length > 0) {
            newFiles[index * 3 + inputIndex - 1] = e.target.files[0];
        }

        setFiles(newFiles);
    };

    const handleSelectImage = (index, inputIndex) => {
        const inputElement = document.getElementById(`fileInput${index}_${inputIndex}`);
        inputElement.click();
    };

    const handleImageUpdate = async (oglasId, index) => {
        const cookies = new UniversalCookie();
        const authToken = cookies.get('Motolegende')?.token;
        const storedUporabniskoImeOrEmail = cookies.get('Motolegende')?.username;

        try {
            const formData = new FormData();
            formData.append('image', files[index]);

            // Send a POST request to update the image on the server
            await axios.post(`/api/Oglas/updateoglas/${oglasId}/${index}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Fetch and update the data again to reflect the changes
            const updatedData = await axios.get(`/api/Oglas/getoglasbyuser?uporabniskoImeOrEmail=${storedUporabniskoImeOrEmail}`);
            setData(updatedData.data);

            const newImageUrls = [...imageUrls];

            switch (index) {
                case 0:
                    newImageUrls[index] = `${updatedData.data[index].imageUrl1}?t=${Date.now()}`;
                    break;
                case 1:
                    newImageUrls[index] = `${updatedData.data[index].imageUrl2}?t=${Date.now()}`;
                    break;
                case 2:
                    newImageUrls[index] = `${updatedData.data[index].imageUrl3}?t=${Date.now()}`;
                    break;
                default:
                    break;
            }

            setImageUrls(newImageUrls);
            setSuccessMessage(`Image ${index + 1} updated successfully!`);
            window.location.reload(true);
        } catch (error) {

        }
    };


    return (
        <div>         
            {Object.keys(successMessages).map((id) => (
                <div key={id} className="success-message">
                    {successMessages[id]}
                </div>
            ))}
            <p className="uredioglastextnaslovna">Maksimalno število oglasov: 1</p>
            <div className="uredioglaskontejner">
                {data.length === 0 ? (
                    <p className="naslovnica">Nimate naloženih oglasov.</p>
                ) : (
                    data.map((oglas, index) => (
                        <div key={index} className="uredioglasminikontejner">
                            {/* Display basic ad information */}
                            {/* Render detailed data here */}
                            <br></br>
                            <img src={oglas.imageUrl1} className="detailsImage" loading="lazy" alt="oglas motorja" />         
                            <div>
                                <label htmlFor={`fileInput${index}_1`} className="selectImageLabel">
                                    Izberi in zamenjaj sliko 1 
                                </label>
                                <input
                                    type="file"
                                    id={`fileInput${index}_1`}
                                    accept=".jpg, .jpeg"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleImageChange(e, index, 1)}
                                />
                                <button onClick={() => handleSelectImage(index, 1)}>Izberi Sliko</button>
                                {files[index * 3] && (
                                    <button onClick={() => handleImageUpdate(oglas.id, index * 3)}>Posodobi Sliko</button>
                                )}
                            </div>
                            <br></br>
                            <img src={oglas.imageUrl2} className="detailsImage" loading="lazy" alt="oglas motorja" />
                            <div>
                                <label htmlFor={`fileInput${index}_2`} className="selectImageLabel">
                                    Izberi in zamenjaj sliko 2 
                                </label>
                                <input
                                    type="file"
                                    id={`fileInput${index}_2`}
                                    accept=".jpg, .jpeg"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleImageChange(e, index, 2)}
                                />
                                <button onClick={() => handleSelectImage(index, 2)}>Izberi Sliko</button>
                                {files[index * 3 + 1] && (
                                    <button onClick={() => handleImageUpdate(oglas.id, index * 3 + 1)}>Posodobi Sliko</button>
                                )}
                            </div>
                            <br></br>
                            <img src={oglas.imageUrl3} className="detailsImage" loading="lazy" alt="oglas motorja" />
                            <div>
                                <label htmlFor={`fileInput${index}_3`} className="selectImageLabel">
                                    Izberi in zamenjaj sliko 3  
                                </label>
                                <input
                                    type="file"
                                    id={`fileInput${index}_3`}
                                    accept=".jpg, .jpeg"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleImageChange(e, index, 3)}
                                />
                                <button onClick={() => handleSelectImage(index, 3)}>Izberi Sliko</button>
                                {files[index * 3 + 2] && (
                                    <button onClick={() => handleImageUpdate(oglas.id, index * 3 + 2)}>Posodobi Sliko</button>
                                )}
                            </div>

                            <div>         
                                <label htmlFor={`znamka_${index}`}></label>
                                <p className="uredioglastext">Znamka motorja :</p>
                                <select
                                    id={`znamka_${index}`}
                                    name="znamka"
                                    value={formData.znamka ?? oglas.znamka}
                                    onChange={(e) => handleChange(e, index, "znamka")}
                                    className="uredioglasselect"
                                >

                                    <option value="" disabled hidden>Izberi Znamko</option>
                                    <option value="Honda">Honda</option>
                                    <option value="Yamaha">Yamaha</option>
                                    <option value="Kawasaki">Kawasaki</option>
                                    <option value="Suzuki">Suzuki</option>
                                    <option value="Ducati">Ducati</option>
                                    <option value="Aprillia">Aprillia</option>
                                    <option value="BMW">BMW</option>
                                    <option value="Husqvarna">Husqvarna</option>
                                    <option value="Benelli">Benelli</option>
                                    <option value="Gilera">Gilera</option>
                                    <option value="Harley Davidson">Harley Davidson</option>
                                    <option value="Indian">Indian</option>
                                    <option value="Victory">Victory</option>
                                    <option value="Custom">Custom</option>
                                    <option value="Junak">Junak</option>
                                    <option value="KTM">KTM</option>
                                    <option value="Kymco">Kymco</option>
                                    <option value="Moto Guzzi">Moto Guzzi</option>
                                    <option value="MV Agusta">MV Agusta</option>
                                    <option value="Royal Enfield">Royal Enfield</option>
                                    <option value="Triumph">Triumph</option>
                                    <option value="Tomos">Tomos</option>
                                    <option value="Vespa">Vespa</option>
                                    <option value="Piaggio">Piaggio</option>
                                    <option value="Daelim">Daelim</option>
                                    <option value="Derbi">Derbi</option>
                                    <option value="Hyosung">Hyosung</option>
                                    <option value="SYM">SYM</option>
                                    <option value="Električni Motocikel">Električni Motocikel</option>
                                    <option value="Zero">Zero</option>
                                    {/* Add more categories as needed */}
                                </select>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Model motorja :</p>
                                    <input
                                        type="text"
                                        name="model"
                                        value={formData.model ?? oglas.model}
                                        onChange={(e) => handleChange(e, index, "model")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Prostornina motorja :</p>
                                    <input
                                        type="text"
                                        name="prostornina"
                                        value={formData.prostornina ?? oglas.prostornina}
                                        onChange={(e) => handleChange(e, index, "prostornina")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Kilovati kW :</p>
                                    <input
                                        type="text"
                                        name="kilovati"
                                        value={formData.kilovati ?? oglas.kilovati}
                                        onChange={(e) => handleChange(e, index, "kilovati")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Moč motorja :</p>
                                    <input
                                        type="text"
                                        name="konji"
                                        value={formData.konji ?? oglas.konji}
                                        onChange={(e) => handleChange(e, index, "konji")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Cena motorja :</p>
                                    <input
                                        type="text"
                                        name="cena"
                                        value={formData.cena ?? oglas.cena}
                                        onChange={(e) => handleChange(e, index, "cena")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Letnik motorja :</p>
                                    <input
                                        type="text"
                                        name="letnik"
                                        value={formData.letnik ?? oglas.letnik}
                                        onChange={(e) => handleChange(e, index, "letnik")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Prevoženi kilometri :</p>
                                    <input
                                        type="text"
                                        name="prevozeniKilometri"
                                        value={formData.prevozeniKilometri ?? oglas.prevozeniKilometri}
                                        onChange={(e) => handleChange(e, index, "prevozeniKilometri")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>  
                            <div>
                                <label htmlFor={`lastnik_${index}`}></label>
                                <p className="uredioglastext">Število lastnikov motorja :</p>
                                <select
                                    id={`lastnik_${index}`}
                                    name="lastnik"
                                    value={formData.lastnik ?? oglas.lastnik}
                                    onChange={(e) => handleChange(e, index, "lastnik")}
                                    className="uredioglasselect"
                                >
                                    <option value="" disabled hidden>Izberi Lastnika</option>
                                    <option value="Prvi Lastnik">Prvi lastnik</option>
                                    <option value="Drugi Lastnik">Drugi lastnik</option>
                                    <option value="Tretji Lastnik">Tretji lastnik</option>
                                    <option value="Četrti Lastnik">Četrti lastnik</option>
                                    <option value="Peti Lastnik">Peti lastnik</option>
                                    <option value="Trrrr Skibidi Dop Dop Dop 6+">Trrrr Skibidi Dop Dop Dop 6+</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor={`pogon_${index}`}></label>
                                <p className="uredioglastext">Vrsta pogona :</p>
                                <select
                                    id={`pogon_${index}`}
                                    name="pogon"
                                    value={formData.pogon ?? oglas.pogon}
                                    onChange={(e) => handleChange(e, index)}
                                    className="uredioglasselect"
                                >
                                    <option value="" disabled hidden>Izberi Pogon</option>
                                    <option value="Veriga">Veriga</option>
                                    <option value="Jermen">Jermen</option>
                                    <option value="Kardan">Kardan</option>
                                </select>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Kraj ogleda :</p>
                                    <input
                                        type="text"
                                        name="krajOgleda"
                                        value={formData.krajOgleda ?? oglas.krajOgleda}
                                        onChange={(e) => handleChange(e, index, "krajOgleda")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">GSM Številka :</p>
                                    <input
                                        type="text"
                                        name="gsmStevilka"
                                        value={formData.gsmStevilka ?? oglas.gsmStevilka}
                                        onChange={(e) => handleChange(e, index, "gsmStevilka")}
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className="uredioglastext">Opis Motorja :</p>
                                    <textarea
                                        type="text"
                                        name="opismotorja"
                                        value={formData.opisMotorja ?? oglas.opisMotorja}
                                        onChange={(e) => handleChange(e, index, "opisMotorja")}
                                        style={{ height: '200px', resize: 'none' }} 
                                        className="uredioglasselect"
                                    />
                                </label>
                            </div>
                    
                            <div>
                                <label htmlFor={`vrstaMotorista_${index}`}> </label>
                                <p className="uredioglastext">Izberi vrsto motorista :</p>
                                <select
                                    id={`vrstaMotorista_${index}`}
                                    name="vrstaMotorista"
                                    onChange={(e) => handleChange(e, index, "vrstaMotorista")}
                                    value={formData.vrstaMotorista ?? oglas.vrstaMotorista}
                                    className="uredioglasselect"
                                >
                                    <option value="">Izberi Opcijo</option>
                                    <option value="Mlajše motoriste">Mlajše Motoriste</option>
                                    <option value="Motoriste z izkušnjami">Motoriste z Izkušnjami</option>
                                    <option value="Rock N Roll">Rock "N" Roll</option>
                                </select>
                            </div>

                            <div>

                                <label htmlFor={`vrstaMotorja_${index}`}></label>
                                <p className="uredioglastext">Izberi vrsto motorja :</p>
                                <select
                                    id={`vrstaMotorja_${index}`}
                                    name="vrstaMotorja"
                                    onChange={(e) => handleChange(e, index, "vrstaMotorja")}
                                    value={formData.vrstaMotorja ?? oglas.vrstaMotorja}
                                    className="uredioglasselect"
                                >
                                    <option value="" disabled hidden>Izberi Vrsto</option>
                                    <option value="Chopper">Chopper</option>
                                    <option value="Naked">Naked Bike</option>
                                    <option value="Tourer">Tourer</option>
                                    <option value="Enduro">Enduro</option>
                                    <option value="Sport">Sport</option>
                                    <option value="Retro">Retro</option>
                                    <option value="Scrambler">Scrambler</option>
                                    <option value="Cross">Cross</option>
                                    <option value="Scooter">Scooter</option>
                                    <option value="Moped">Moped</option>
                                    <option value="Minimoto">Mini Moto</option>
                                </select>
                                {successMessages[oglas.id] && (
                                    <p className="success-message">{successMessages[oglas.id]}</p>
                                )}
                 
                            </div>
                            <br></br>
                            <button className="uredioglasbutton" onClick={() => handleUpdateDetails(oglas.id, index)}>
                                Posodobi Oglas
                            </button>
                            <br></br>
                            <button className="uredioglasbutton" onClick={() => handleDelete(oglas.id)}>
                                Zbriši Oglas
                            </button>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default UrediOglas;