import React, { useEffect,  useState,} from 'react';
import axios from 'axios';
import UniversalCookie from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import "./IzbrisRacuna.css";
import { Link} from "react-router-dom";


const IzbrisRacuna = () => {
    const [uporabniskoImeOrEmail, setUporabniskoImeOrEmail] = useState('');
    const [deleteMessage, setDeleteMessage] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();
    const cookies = new UniversalCookie();
    const cookieName = 'Motolegende'; // Replace with your actual cookie name

    useEffect(() => {
        const authToken = cookies.get(cookieName) && cookies.get(cookieName).token;
        const storedUporabniskoImeOrEmail = cookies.get(cookieName) && cookies.get(cookieName).username;

        if (authToken && storedUporabniskoImeOrEmail) {
            // Use storedUsernameOrEmail for any user-specific operations
            setUporabniskoImeOrEmail(storedUporabniskoImeOrEmail); // na novo za prikaz imena
        } else {
            // If the user does not have the cookie, redirect to another page
            navigate('/Vpis'); // Redirect to the login page
        }
    }, [cookies, cookieName, navigate]);

    const handleDeleteCurrentUser = async () => {
        try {
            // Make a DELETE request to the backend API
            const response = await axios.delete(`/api/Registracija/delete/${uporabniskoImeOrEmail}`);

            if (response.status === 200) {
                setDeleteMessage('Račun izbrisan uspešno');
                // Clear the authentication cookie on the client side
                cookies.remove(cookieName, { path: '/' });
                // Redirect to the login page after deletion
                navigate('/Vpis');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            setDeleteMessage('An error occurred while deleting the user.');
        } finally {
            // Reset the deleting state
            setIsDeleting(false);
        }
    };

    const handleDeleteButtonClick = () => {
        // Set the deleting state to true to show the warning
        setIsDeleting(true);
    };

    const handleCancelDelete = () => {
        // Reset the deleting state if the user cancels the action
        setIsDeleting(false);
    };
///////////////////////////////////////////////////////////////////////////77
    return (
        <div className="izbrisracunakontejner">
            {/* Your existing registration form goes here */}

            {/* Button to trigger deletion */}
            <p className="izbrisracunatext">Trenutno prijavljeni uporabnik : {uporabniskoImeOrEmail}</p>
            <div>
                <a href="https://www.motolegende.si/MojProfil">
                    <img src="/Logotipi/nazaj.png" className="nazajikona" />
                </a>
            </div>
            <button onClick={handleDeleteButtonClick} className="izbrisracuna">Izbriši Račun</button>

            {/* Display delete warning */}
            {isDeleting && (
                <div>
                    <p className="izbrisracunatext">Ali ste prepričani, da želite izbrisati svoj račun?</p>
                    <button onClick={handleDeleteCurrentUser} className="izbrisracuna">Da, izbriši</button>
                    <button onClick={handleCancelDelete} className="izbrisracuna">Prekliči</button>
                </div>
            )}

            {/* Display delete message */}
            <p style={{ color: 'red', fontWeight: 'bold' }}>{deleteMessage}</p>
        </div>
    );
};

export default IzbrisRacuna;