import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";

const HondaShadow750 = () => {

    return (
        <div>
            <br></br>
            <center><h1 className="k5" alt="Honda Shadow 750">HONDA SHADOW SPIRIT ( 750.ccm )</h1></center>
            <center><p className="k5" alt="Klasika proti izkušnjami">( - Klasika proti izkušnjam - )</p></center>
            <p className="k4" alt="Jožko Gregorovič">- Avtor članka :  Jožko Gregorovič</p>
            <p className="k4">- Objavljeno dne :  2.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\HondaShadow750\slika1.jpg" alt="Honda Shadow Spirit 750ccm" className="k3slika" />
                <p className="k2">Honda shadow 750 s svojim zvokom ne pusti še tako zahtevnega motorista ravnodušnega.
                    Začetnika okuži z globokim glasom, izkušenemu boža ušesa. Za prvi motor ne priporočam zaradi teže in ne prav idealne ciklistike.
                    Je zanesljiv sopotnik, z malo porabo in nudi tisto pričakovano čopersko vzdušje. Tudi po 12ih letih od slovesa, se mi je zapisala globoko v spomin.
                    Dejansko jo na nek način še vedno pogrešam.
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default HondaShadow750;