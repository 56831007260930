import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import "./Dizajn/GlobalniKontejner.css";
import Domov from "./Domov/Domov.js";
import SharedLayout from "./SharedLayout.js";
import MotoAvanture from "./MotoAvanture/MotoAvanture.js";

import OglasiMotorjev from "./OglasiMotorjev/OglasiMotorjev.js";
import MojProfil from "./MojProfil/MojProfil.js";
import Header from "./Header/Header.js";
import NavigacijskaVrstica from "./NavigacijskaVrstica/NavigacijskaVrstica";
import Footer from "./Footer/Footer.js";
import Registracija from "./Registracija/Registracija.js";
import Vpis from "./Vpis/Vpis.js";
import OddajMotoOglas from "./OddajMotoOglas/OddajMotoOglas.js";
import Administrator from "./Administrator/Administrator.js";
import AdministratorVpis from "./Administrator/AdministratorVpis.js";
import IzbrisRacuna from "./IzbrisRacuna/IzbrisRacuna.js";
import UrediOglas from './UrediOglas/UrediOglas.js';
import Kontakt from "./Kontakt/Kontakt.js";
import Pravila from "./Pravila/Pravila.js";
import OglasiMotorjevMotor from "./OglasiMotorjev/OglasiMotorjevMotor.js";
import Dobrodoslica from './Domov/Podstrani/Dobrodoslica.js';
import Verona2023 from './Domov/Podstrani/Verona2023.js';
import Eicma2023 from './Domov/Podstrani/Eicma2023.js';
import UmagSejem2024 from './Domov/Podstrani/UmagSejem2024.js';
import Grobnik from './Domov/Podstrani/Grobnik.js';
import Acdc2024 from './Domov/Podstrani/Acdc2024.js';
import OddajAvanture from "./OddajAvanturo/OddajAvanture.js";
import Donacije from "./Donacije/Donacije.js";
import Email from './Email/Email';
import MenjavaGesla from './MenjavaGesla/MenjavaGesla';
import PreverjanjeGesla from './MenjavaGesla/PreverjanjeGesla';
import Snake from './Igre/Snake';

import Umetnost from "./Umetnost/Umetnost.js";
import Sopota2024 from './Domov/Podstrani/Sopota2024';
import YamahaMt03 from './Umetnost/UmetnostPodstrani/YamahaMt03';
import HondaShadow750 from './Umetnost/UmetnostPodstrani/HondaShadow750';
import Vasiliki from './Umetnost/UmetnostPodstrani/Vasiliki';
import PortoKatsikiBeach from './Umetnost/UmetnostPodstrani/PortoKatsikiBeach';
import Viganj from './Umetnost/UmetnostPodstrani/Viganj';
import Meteore from './Umetnost/UmetnostPodstrani/Meteore';
import Stupice from './Umetnost/UmetnostPodstrani/Stupice';
import Vinoteka from './Umetnost/UmetnostPodstrani/Vinoteka';
import SvetiStefan from './Umetnost/UmetnostPodstrani/SvetiStefan';
import Mamos from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/Mamos';
import Velhotes from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/Velhotes';
import LegendOfKremlin from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/LegendOfKremlin';
import BlueCuracao from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/BlueCuracao';
import HendricksGin from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/HendricksGin';
import DarilnaPijaca from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/DarilnaPijaca';
import HrvaskaPriprave from './Umetnost/UmetnostPodstrani/HrvaskaPriprave';
import Loisium from './Umetnost/UmetnostPodstrani/Loisium';
import Courvoisier from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/Courvoisier';
import PortoPiccolo from './Umetnost/UmetnostPodstrani/PortoPiccolo';
import HojaPoZerjavici from './Umetnost/UmetnostPodstrani/HojaPoZerjavici';
import GrossGlockner from './Umetnost/UmetnostPodstrani/Grossglockner';
import Tesseron from './Umetnost/UmetnostPodstrani/VinotekaPodstrani/Tesseron';



const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter basename={baseUrl}>  
        <Header />
        <NavigacijskaVrstica />
        <Routes>
            <Route path="/" element={<SharedLayout />}>
                <Route index element={<Domov />} />
                <Route path="/domov" element={<Domov />} />
                <Route path="/Domov/Dobrodoslica" element={<Dobrodoslica />} /> 
                <Route path="/Domov/Verona2023" element={<Verona2023 />} /> 
                <Route path="/Domov/Eicma2023" element={<Eicma2023 />} /> 
                <Route path="/Domov/Grobnik" element={<Grobnik />} /> 
                <Route path="/Domov/UmagSejem2024" element={<UmagSejem2024 />} />
                <Route path="/Domov/Acdc2024" element={<Acdc2024 />} />
                <Route path="/Domov/Sopota2024" element={<Sopota2024 />} /> 
                <Route path="Donacije" element={<Donacije />} /> 

                <Route path="Vpis" element={<Vpis />} />
                <Route path="/Vpis/Email" element={<Email />} />
                <Route path="/Vpis/Email/PreverjanjeGesla" element={<PreverjanjeGesla />} />
                <Route path="/Vpis/Email/PreverjanjeGesla/MenjavaGesla" element={<MenjavaGesla />} />

                <Route path="Registracija" element={<Registracija />} />

                <Route path="Motoavanture" element={<MotoAvanture />} />

                <Route path="Umetnost" element={<Umetnost />} />
                <Route path="/Umetnost/YamahaMt03" element={<YamahaMt03 />} />
                <Route path="/Umetnost/HondaShadow750" element={<HondaShadow750 />} />
                <Route path="/Umetnost/Vasiliki" element={<Vasiliki />} />
                <Route path="/Umetnost/PortoKatsikiBeach" element={<PortoKatsikiBeach />} />
                <Route path="/Umetnost/Viganj" element={<Viganj />} />
                <Route path="/Umetnost/Meteore" element={<Meteore />} />
                <Route path="/Umetnost/Stupice" element={<Stupice />} />
                <Route path="/Umetnost/SvetiStefan" element={<SvetiStefan />} />
                <Route path="/Umetnost/HrvaskaPriprave" element={<HrvaskaPriprave />} />
                <Route path="/Umetnost/Loisium" element={<Loisium />} />
                <Route path="/Umetnost/PortoPiccolo" element={<PortoPiccolo />} />
                <Route path="/Umetnost/HojaPoZerjavici" element={<HojaPoZerjavici />} />
                <Route path="/Umetnost/GrossGlockner" element={<GrossGlockner />} />


                <Route path="/Umetnost/Vinoteka" element={<Vinoteka />} />
                <Route path="/Umetnost/Vinoteka/Mamos" element={<Mamos />} />
                <Route path="/Umetnost/Vinoteka/Velhotes" element={<Velhotes />} />
                <Route path="/Umetnost/Vinoteka/LegendOfKremlin" element={<LegendOfKremlin />} />
                <Route path="/Umetnost/Vinoteka/BlueCuracao" element={<BlueCuracao />} />
                <Route path="/Umetnost/Vinoteka/HendricksGin" element={<HendricksGin />} />
                <Route path="/Umetnost/Vinoteka/DarilnaPijaca" element={<DarilnaPijaca />} />
                <Route path="/Umetnost/Vinoteka/Courvoisier" element={<Courvoisier />} />
                <Route path="/Umetnost/Vinoteka/Tesseron" element={<Tesseron />} />

                <Route path="OglasiMotorjev" element={<OglasiMotorjev />} />
                <Route path="/OglasiMotorjevMotor/:id" element={<OglasiMotorjevMotor />} />
                <Route path="/OglasiMotorjev/page/:page" element={<OglasiMotorjev />} />

                <Route path="MojProfil" element={<MojProfil />} />
                <Route path="MojProfil/OddajMotoOglas" element={<OddajMotoOglas />} />
                <Route path="MojProfil/UrediOglas" element={<UrediOglas />} />
                <Route path="MojProfil/OddajAvanture" element={<OddajAvanture />} />

                <Route path="Kontakt" element={<Kontakt />} />

                <Route path="/Kontakt/AdministratorVpis" element={<AdministratorVpis />} />

                <Route path="Kontakt/Administrator" element={<Administrator />} />

                <Route path="IzbrisRacuna" element={<IzbrisRacuna />} />

                <Route path="Pravila" element={<Pravila />} />

                <Route path="Snake" element={<Snake />} />
            </Route>
        </Routes>
        <Footer />  
  </BrowserRouter>);

