import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const HrvaskaPriprave = () => {
    const pageTitle = "Kako se pripraviti na hrvaški dopust";
    const pageDescription = "Kam na dopust";

    return (

        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/HrvaskaPriprave" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <img src="Slike\DomovSlike\Umetnost\plaza.jpg" alt="Kempinski plaža" className="vinotekaumetnostozadje2" />
            <br></br>
            <center>
                <h3 className="k5" alt="Dopust na Hrvaškem">KAKO SE PRAVILNO PRIPRAVITI NA HRVAŠKI DOPUST</h3>
                <p style={{ color: "white" }}>( - Meditacija, Cene in kako ne zapravit vsega denarja - )</p>
                <div>
                    <img src="Slike\DomovSlike\Umetnost\HrvaskaPriprave\slika3.jpg" alt="Viganj Pelješac Hrvaška" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\HrvaskaPriprave\slika2.jpg" alt="Viganj Pelješac Hrvaška" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\HrvaskaPriprave\slika4.jpg" alt="Viganj Pelješac Hrvaška" className="umetnostpodstranivecslik" />
                </div>

            </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 13.06.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize:"20px" }} alt="Dopust na Hrvaškem"> Ste se kdaj vprašali, ali imate tako nizko plačo, ali pa hrvati drage cene?. Odgovor ni v vas. Cene na hrvaškem se vsako leto vzpenjajo kot Pogačar na hrib.
                Zato moramo s seboj imeti čim več hrane, pijače, cigaret in optimizma. Cene na obali plapolajo, na primer za skodelico kave odštejemo kar 4€, Malo pivo 4,50€, Kokteli 10-15€ (odvisno od lokacije). V prihodnost še verjetno deci zraka 2€. Da ne govorimo o restavracijah.
                Seveda moramo vedeti, da za domačine je cena drugačna. Če že morate kupovati na hrvaškem so trgovine kot Kaufland idealne, saj imajo normalno ceno izdelkov. Ponavadi imajo pred trgovino Fast Food štacuno, pri kateri lahko kupite kompletnega pečenega piščanca za 7,50€ (5,50€ izven sezone).
                Kampiranje se je podražalo na takšno ceno, če odšetejte najem avtodoma, prikolice in nočitve, za podobno ceno dobite apartma. Kampiranje z šotorom pa tudi ni slaba ideja, seveda, če niste na soncu.
                Seveda je pa najcenejše, da si dopust omislite mimo hrvaške na jug ali pa greste v hribe.
            </p>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default HrvaskaPriprave;