import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const HojaPoZerjavici = () => {
    const pageTitle = "Hoja po žerjavici Maribor";
    const pageDescription = "Hoja po žerjavici Maribor";


    return (

        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/HojaPoZerjavici" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\HojaPoZerjavici.jpg" alt="Hoja po žerjavici Maribor" className="vinotekaumetnostozadje2" />
            <center>
                <h3 className="k5" alt="Hoja po žerjavici">HOJA PO ŽERJAVICI - MARIBOR</h3>
                <p style={{ color: "white" }}>( - Meditacija in stanje notranjega duha - )</p>
                <div>
                    <img src="Slike\DomovSlike\Umetnost\HojaPoZerjavici\slika1.jpg" alt="Hoja po žerjavici" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\HojaPoZerjavici\slika2.jpg" alt="Hoja po žerjavici" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\HojaPoZerjavici\slika3.jpg" alt="Hoja po žerjavici" className="umetnostpodstranivecslik" style={{width:"300px"} } />
                </div>

            </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 6.07.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }} alt="Hoja po žerjavici Maribor">V okolici Maribora smo obiskali posebni tečaj hodenja po žerjavici. Imeli smo dva odličnega inštruktorja, ki sta nas pripravila za meditacijo. Tečaj je potekal tako, da smo na začetku imeli 3 ure meditacijskih vaj. 
                Predpriprave so ključnega pomena za takšen izziv saj ob nepravičnost si lahko zakurimo podplate. Z posebnimi zvoki na gong in dimom smo si pripravili notranjega duha. Ob končani meditaciji in mentaliteti kung fuja, smo uspešno prehodili žerjavico. Ob končanem obredu, smo si pogledali podplate. In na tisti točki , kjer te je žerjavica ožgala, lahko vidimo našo šibko točko v telesu.
                Ocena tečaja (10/10).

            </p>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default HojaPoZerjavici;