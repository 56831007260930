import React from "react";
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import "../Domov/Domov.css";
import { Helmet } from 'react-helmet';
import "./Umetnost.css";

function Umetnost() {
    const pageTitle = "Potovanja in Vinoteka";
    const pageDescription = "Umetnost, Potovanja, Srfanje, Vinoteka";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <p style={{ color: 'white', marginTop: "1%", textAlign: 'center' }}>( Umetnost, Potovanja, Vinoteka in Članki )    <img src="/Slike/DomovSlike/Umetnost/knjiga.png" alt="kako izbrati motor" style={{ width: "30px"}} /></p> 
         
            <div>
               
                <img src="Slike\DomovSlike\Umetnost\umetnostozadje.jpg" alt="Umetnost ozadje" className="umetnostozadje" />
                <center><img src="Slike\DomovSlike\Umetnost\kitara.png" alt="Umetnost ozadje" className="jesuskitara" /></center>
                <center><img src="Slike\DomovSlike\Umetnost\jesus3.png" alt="Umetnost ozadje" className="" style={{ width: "80px", height: "130px", marginTop: "320px", position: "absolute", zIndex: "-999" }} /></center>
                <br></br>
                <div className="strelaefektjesus"></div>
                <center>
                    <div>
                     

                        <div className="umetnostkontejnerzalinke">


                            <div className="umetnostkontejner1">
                                <div className="umetnostlevikontejner">
                                    <p alt="kam na dopust" className="umetnostnaslovteksta">KAM NA DOPUST ?  </p> <img src="/logotipi/zoga.png" alt="morje" className="domovzramnaslovaslika" /> <img src="/Logotipi/palma.png" alt="kako izbrati motor" style={{ width: "50px" }} />
                                    <div>
                                        <a href="/Umetnost/HrvaskaPriprave" alt="Kako se pripraviti na hrvaški dopust" className="umetnostnaslovlinka">
                                            -  Kako se pripraviti na hrvaški dopust
                                        </a>
                                    </div>
                                    <div>
                                        <a href="/Umetnost/PortoPiccolo" alt="Portopiccolo italija" className="umetnostnaslovlinka">
                                            - Naselje Portopiccolo  ( Italija )
                                        </a>
                                    </div>
                                    <div>
                                        <a href="/Umetnost/Loisium" alt="Loisium Avstrija" className="umetnostnaslovlinka">
                                            - Narava in vino Loisium ( Avstrija )
                                        </a>
                                    </div>
                                    <div>
                                        <a href="/Umetnost/PortoKatsikiBeach" alt="Porto katsiki beach Grčija" className="umetnostnaslovlinka">
                                            - Plaža Porto Katsiki ( Grčija )
                                        </a>
                                    </div>
                                    <div>
                                        <a href="/Umetnost/Viganj" alt="Otok Pelješac Viganj" className="umetnostnaslovlinka">
                                            - Viganj - Otok Pelješac ( Hrvaška )
                                        </a>
                                    </div>
                                    <div>
                                        <a href="/Umetnost/Meteore" alt="Gorovje Meteore Grčija" className="umetnostnaslovlinka">
                                            - Kometno gorovje Meteore ( Grčija )
                                        </a>
                                    </div>
                                    <div>
                                        <a href="/Umetnost/SvetiStefan" alt="Sveti Štefan Črna Gora" className="umetnostnaslovlinka">
                                            - Sveti Štefan ( Črna Gora )
                                        </a>
                                    </div>

                                    <div>
                                        <a href="/Umetnost/HojaPoZerjavici" alt="Hoja po žerjavici" className="umetnostnaslovlinka">
                                            - Hoja po žerjavici ( Slovenija - Maribor )
                                        </a>
                                    </div>
                                    <br></br>
                                    <p className="umetnostnaslovteksta" alt="Raj za srfanje">RAJ ZA SRFANJE : </p><img src="/Slike/DomovSlike/Umetnost/lo123.png" alt="kako izbrati motor" style={{ width: "50px", borderRadius:"5px" }} />
                                    <div className="umetnostkontejnerzalinke">
                                        <div>
                                            <a href="/Umetnost/Vasiliki" className="umetnostnaslovlinka" alt="vasiliki greece">
                                                - Raj za srfanje ( Vasiliki-Grčija )
                                            </a>
                                        </div>
                                        <div>
                                            <a href="/Umetnost/Stupice" className="umetnostnaslovlinka" alt="Kamp Stupice Hrvaška">
                                                - Kamp Stupice ( Hrvaška )
                                            </a>
                                        </div>
                                    </div>
                                </div>

                          
                                <div className="umetnostlevikontejner">
                                    <p alt="Vinoteka vina, žganja in piva" className="umetnostnaslovteksta">VINOTEKA (18+) : </p><img src="/Slike/DomovSlike/Umetnost/klet.jpg" alt="Vinoteka klet" style={{ width: "100px", borderRadius: "5px" }} />
                                    <div className="umetnostkontejnerzalinke">
                                        <a href="/Umetnost/Vinoteka" alt="Vinoteka vina, žganja in piva" className="umetnostnaslovlinka">
                                            - Najboljša vina, žganja in piva
                                        </a>
                                    </div>
                                    <br></br>
                                    <p alt="Motopotovanja in avanture" className="umetnostnaslovteksta">MOTO POTOVANJA :  </p> <img src="/Logotipi/Motolegendeikona.png" alt="moto legende" style={{ width: "50px" }} />
                                    <div className="umetnostkontejnerzalinke">
                                        <div>
                                            <a href="/Umetnost/GrossGlockner" className="umetnostnaslovlinka" alt="Motoristični izlet na Grossglockner avstrija">
                                                - Grossglockner (Avstrija)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        
                        </div>
                      
                    </div>
          

                </center>
            
                
                {/*
                <div>
                    <p>MOTO AVANTURE PO SLOVENIJI IN SVETU : <img src="/Logotipi/motolegendeikona.png" alt="komentarji" style={{ width: "30px" }} /></p>
                    <div>
                        <a href="/IzbrisRacuna" className="">
                            Pot v Maroko (ŠERUGA VERZIJA)
                        </a>
                    </div>
                    <div>
                        <a href="/IzbrisRacuna" className="">
                            Lendav Ring - Slovenija
                        </a>
                    </div>
                    <div>
                        <a href="/IzbrisRacuna" className="">
                            Pot v Sopoto - Slovenija
                        </a>
                    </div>
                    <div>
                        <a href="/IzbrisRacuna" className="">
                            Pot na Rakitno - Slovenija
                        </a>
                    </div>
                </div>
                */}
                {/* }
                <div>
                    <p className="umetnostnaslovteksta">KAKO IZBRATI PRAVILEN MOTOR : <img src="/Logotipi/motolegendeikona.png" alt="kako izbrati motor" style={{ width: "60px" }} /></p>
                    <div>
                        <a href="/Umetnost/HondaShadow750" className="umetnostpodstrankontejner" alt="Honda Shadow Spirit 750ccm">
                            Klasika proti izkušnjam (Honda Shadow Spirit 750.ccm)
                        </a>
                    </div>
                    <div>
                        <a href="/Umetnost/YamahaMT03" className="umetnostpodstrankontejner" alt="motor za nizke ljudi yamaha mt03">
                            Odličen motor za nižje motoriste (Yamaha MT-03)
                        </a>
                    </div>
                </div>
                  
                */}


            </div>                     
        </div>
    );
}

export default Umetnost;