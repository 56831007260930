import React, { useEffect, useState} from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const YamahaMt03 = () => {

    return (
        <div>

            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/YamahaMT03" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <center><h1 className="k5" alt="Yamaha MT-03">YAMAHA MT-03 ( 300.ccm )</h1></center>
            <center><p className="k5" alt="idealni motor za nižje motoriste">( - Idealni motor za nižje motoriste - )</p></center>
            <p className="k4" alt="Mojca Gregorovič">- Avtor članka :  Mojca Gregorovič</p>
            <p className="k4">- Objavljeno dne :  5.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\YamahaMT03\slika1.jpg" alt="Opis Yamaha MT-03" className="k3slika" />
                <p className="k2">Sem Mojca,  vozila sem yamaho mt-03. Izpit sem delala na mt 07 tako da je mt 03 malček. Vendar so bili začetki težavni. Povedat moram da sem kratkonoga in je bil motor previsok. Dala sem ga ponižat in na škornje 3cm podplat, da sem bila na sigurnih prstih.
                    Prvo leto po izpitu sem z možem odšla v Grčijo. Bila sem zelo zelena, desni zaprti ovinki mi sploh niso šli, makedam tudi ne. Še dobro, da sem imela s seboj izkušenega moža. Potovanje je bilo zanimivo, motor je bil zanesljiv in tudi servisi so bili v zadovoljivem številu.
                    Sedaj več let po tem se še vedno rada spomnim mt-03, je zanesljiv in lep motor. Izkušnje so bile nepozabne . Želim veliko užitkov na motorjih.
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default YamahaMt03;