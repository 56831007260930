import React, { useEffect, useState } from 'react';
import "./MojProfil.css"; // Adjust the CSS import based on your file structure
import axios from 'axios';
import UniversalCookie from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import StarryBackground from '../StarsEffect/StarryBackground';

const MojProfil = () => {
    const navigate = useNavigate();
    const [uporabniskoImeOrEmail, setUporabniskoImeOrEmail] = useState(''); // na novo za prikaz imena
    const [file, setFile] = useState(null);
    const cookies = new UniversalCookie();
    const cookieName = "Motolegende"; // Define the cookie name
    const [avatar, setAvatar] = useState(null);
    const [userPostCount, setUserPostCount] = useState(null);


    useEffect(() => {
        const authToken = cookies.get(cookieName) && cookies.get(cookieName).token;
        const storedUporabniskoImeOrEmail = cookies.get(cookieName) && cookies.get(cookieName).username;

        if (authToken && storedUporabniskoImeOrEmail) {
            // Use storedUsernameOrEmail for any user-specific operations
            setUporabniskoImeOrEmail(storedUporabniskoImeOrEmail); // na novo za prikaz imena
        } else {
            // If the user does not have the cookie, redirect to another page
            navigate('/Vpis'); // Redirect to the login page
        }
    }, [cookies, cookieName, navigate]);

    const handleLogout = async () => {
        try {
            // Call the logout endpoint first to sign out the user
            const response = await axios.post("/api/Vpis/izpis");
            console.log(response.data.message);

            // Clear the specific cookie after successfully logging out
            await cookies.remove(cookieName, { path: '/' }); // Specify the path

            // Redirect to the login page
            navigate("/Vpis");
            window.location.reload();
        } catch (error) {
            // Handle any errors
            console.error("Error logging out:", error);
        }
    };
    //
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        const compressedFile = await compressImage(file);
        formData.append('uporabniskoImeOrEmail', uporabniskoImeOrEmail);
        formData.append('file', compressedFile);

        try {
            const response = await axios.post('/api/Avatar/avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            if (response.status === 200) {
                console.log('Avatar uploaded successfully.');
                // Handle success here               
                window.location.reload();
                if (avatar) {
                    await handleDelete();
                }

            }
        } catch (error) {
            console.error("Error uploading avatar:", error);
        }
    };

    //kompres image
    const compressImage = async (imageFile) => {
        return new Promise((resolve, reject) => {
            const options = {
                maxSizeMB: 0.5, // Maximum size of the compressed image
                maxWidthOrHeight: 500, // Maximum width or height of the compressed image
                useWebWorker: true,
            };

            try {
                const blobImage = new Blob([imageFile], { type: imageFile.type });

                imageCompression(blobImage, options)
                    .then((compressedBlob) => {
                        resolve(compressedBlob);
                    })
                    .catch((error) => {

                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };
    //


    useEffect(() => {
        const fetchAvatar = async () => {
            try {
                const response = await axios.get(`/api/Avatar/avatar?uporabniskoImeOrEmail=${uporabniskoImeOrEmail}`, {
                    responseType: 'blob',
                });

                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'image/jpeg' }); // Adjust the content type as needed
                    setAvatar(URL.createObjectURL(blob));
                } else {
                    console.error(`Failed to fetch avatar. Status: ${response.status}`);
                    // Optionally, you can set a default avatar or display a placeholder image
                    // setAvatar(defaultAvatar);
                }
            } catch (error) {
                console.error('An error occurred while fetching the avatar:', error);
                // Optionally, you can set a default avatar or display a placeholder image
            }
        };

        if (uporabniskoImeOrEmail) {
            fetchAvatar();
        }
    }, [uporabniskoImeOrEmail]);


    //
    const handleDelete = async () => {
        try {
            const response = await axios.delete(`/api/Avatar/deleteavatar?uporabniskoImeOrEmail=${uporabniskoImeOrEmail}`);

            if (response.status === 200) {                
                // Handle success here
                window.location.reload(true);
            }
        } catch (error) {          
            // Handle the error here
        }
    };

    useEffect(() => {
        const fetchUserPostCount = async () => {
            try {
                const response = await axios.get(`/api/avanture/userpostcounts/${uporabniskoImeOrEmail}`);
                setUserPostCount(response.data);
            } catch (error) {
           
            }
        };

        if (uporabniskoImeOrEmail) {
            // Fetch post count for the currently logged-in user
            fetchUserPostCount();
        }
    }, [uporabniskoImeOrEmail]);

    return (
        <div className="mojprofilkontejner">
            <img src="/Logotipi/zemlja.png" loading="lazy" alt="motolegende clan" className="mojprofilzramnaslovaslikazemlja" />
                <a href="/IzbrisRacuna" className="nastavitvebutton">
                <img src="/Logotipi/Nastavitve.png" alt="uporabniske nastavitve" className="nastavitvebutton" />
            </a>

            <div className="content-container"> 
                <div className="left-section">
                    <p className="avatarmojprofil"> MOJ PROFIL</p>
                    <div className="avatar-container">
                        {avatar ? (
                            <img src={avatar} className="avatar" alt="Avatar uporabnika" />
                        ) : (
                                <img src="/Logotipi/avatar1.jpg" className="avatar" loading="lazy" alt="Avatar uporabnika" />
                        )}
                    </div>    
                    <div className="userpostcounts">
                        <h4>Objavljene motoristične zgodbe : <img src="/logotipi/zemlja.png" alt="komentar" className="domovzramnaslovaslika" /></h4>
                        <p>
                            {uporabniskoImeOrEmail && userPostCount !== null
                                ? `Objavili ste ${userPostCount} zgodb.`
                                : 'Loading post count...'}
                        </p>
                    </div>
                </div>


                <div className="right-section">
                        <a href="MojProfil/OddajMotoOglas">
                            <button className="mojprofilbuttons">Oddaj motoristični oglas</button>
                        </a>
                        <a href="MojProfil/UrediOglas">
                            <button className="mojprofilbuttons">Uredi motoristični oglas</button>
                        </a>
                        <a href="MojProfil/OddajAvanture">
                        <button className="mojprofilbuttons">Objavi svojo motoristično avanturo</button> 
                        </a>
                    <button onClick={handleLogout} className="mojprofilbuttonsodjava">Odjavi se</button>
                    <div className="avatarinputkontejner">
                        <button onClick={handleUpload} className="avatar-input" >Naloži profilno sliko</button>
                        <input type="file" accept=".jpg, .jpeg" onChange={handleFileChange} placeholder="Naloži Sliko" className="avatar-input" />
                        <button onClick={handleDelete} className="avatar-input" >Izbris profilne slike</button>                    
                    </div>        
                    <p style={{ color: "white" }}>Olimpijske igre 2024 :  <img src="/Logotipi/olimpic.png" alt="komentarji" style={{ width: "40px" }} />  <img src="/Logotipi/slovenija.png" alt="komentarji" style={{ width: "30px" }} /></p>
                </div> 
                
            </div> 
            <p className="mojprofilimeuporabnika"> Uporabniško Ime : &nbsp; {uporabniskoImeOrEmail} <img src="/Logotipi/olimpic.png" className="logotipmember" loading="lazy" alt="motolegende clan" /></p>   
            <StarryBackground show={true} />   
            </div>
    );
};

export default MojProfil;

