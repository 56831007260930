import React, { useState, useEffect, useRef } from 'react';
import './Snake.css';
import StarryBackground from '../StarsEffect/StarryBackground';

const Snake = () => {
    const numRows = 20;
    const numCols = 20;

    const [snake, setSnake] = useState([{ row: 0, col: 0 }]);
    const [food, setFood] = useState(generateRandomPosition());
    const [direction, setDirection] = useState('RIGHT');
    const [gameOver, setGameOver] = useState(false);
    const [isGameRunning, setIsGameRunning] = useState(false);
    const [foodCounter, setFoodCounter] = useState(0);
    const audioRef = useRef(null);

    const snakeRef = useRef(snake);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        snakeRef.current = snake;
    }, [snake]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Function to play the sound when food is collected
        const playEatSound = () => {
            // Reset audio playback by setting currentTime to 0
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        };

        // Check if foodCounter changes and play sound if it does
        if (foodCounter > 0) {
            playEatSound();
        }
    }, [foodCounter]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            e.preventDefault();

            if (isGameRunning) {
                switch (e.key) {
                    case 'ArrowUp':
                        setDirection('UP');
                        break;
                    case 'ArrowDown':
                        setDirection('DOWN');
                        break;
                    case 'ArrowLeft':
                        setDirection('LEFT');
                        break;
                    case 'ArrowRight':
                        setDirection('RIGHT');
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [isGameRunning]);

    useEffect(() => {
        let gameInterval;

        if (isGameRunning) {
            const moveSnake = () => {
                const newSnake = [...snakeRef.current];
                const head = { ...newSnake[0] };

                switch (direction) {
                    case 'UP':
                        head.col -= 1;
                        break;
                    case 'DOWN':
                        head.col += 1;
                        break;
                    case 'LEFT':
                        head.row -= 1;
                        break;
                    case 'RIGHT':
                        head.row += 1;
                        break;
                    default:
                        break;
                }

                newSnake.unshift(head);

                if (head.row === food.row && head.col === food.col) {
                    setFood(generateRandomPosition());
                    setFoodCounter((prevCounter) => prevCounter + 1);
                } else {
                    newSnake.pop();
                }

                checkCollision(newSnake);
                setSnake(newSnake);
            };

            gameInterval = setInterval(moveSnake, 100);
        }

        return () => {
            clearInterval(gameInterval);
        };
    }, [direction, isGameRunning]);

    const handleArrowClick = (dir) => {
        if (isGameRunning) {
            setDirection(dir);
        }
    };

    const startGame = () => {
        resetGame();
        setIsGameRunning(true);
        setGameOver(false);
        setFoodCounter(0);
    };

    const stopGame = () => {
        setIsGameRunning(false);
    };

    const checkCollision = (snake) => {
        const head = snake[0];

        if (
            head.row < 0 ||
            head.row >= numRows ||
            head.col < 0 ||
            head.col >= numCols ||
            snake.slice(1).some((segment) => segment.row === head.row && segment.col === head.col)
        ) {
            setGameOver(true);
            setIsGameRunning(false);
        }
    };

    function generateRandomPosition() {
        const row = Math.floor(Math.random() * numRows);
        const col = Math.floor(Math.random() * numCols);

        return { row, col };
    }

    const resetGame = () => {
        setSnake([{ row: 0, col: 0 }]);
        setFood(generateRandomPosition());
        setDirection('RIGHT');
        setGameOver(false);
        setFoodCounter(0);
    };

    return (
        <div>
            <audio ref={audioRef} src="/Logotipi/flappy2.mp3"></audio>
            <center><img src="/Logotipi/90s.jpg" alt="snake igra" className="kacaigra" /></center>
            <p className="igratext">Številka hrane : {foodCounter}</p>
            <div className="app-container">
                <div className="grid">
                    {Array.from({ length: numRows }).map((_, rowIndex) => (
                        <div key={rowIndex} className="row">
                            {Array.from({ length: numCols }).map((_, colIndex) => (
                                <div key={colIndex} className="cell">
                                    <div
                                        className={`content ${snake.some((segment) => segment.row === rowIndex && segment.col === colIndex) ? 'snake' : ''
                                            } ${food.row === rowIndex && food.col === colIndex ? 'food' : ''}`}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <br></br>
            <div className="igratext">
                {!isGameRunning && <button onClick={startGame} className="startajtext">Štartaj</button>}
                {isGameRunning && <button onClick={stopGame} className="startajtext">Ustavi</button>}
                {gameOver && <p>Igra Končana!</p>}
            </div>
            {isMobile && (
                <div className="arrow-buttons">
                    <div>
                        <button onClick={() => handleArrowClick('LEFT')}>←</button>
                    </div>
                    <div>
                        <button onClick={() => handleArrowClick('DOWN')}>↓</button>
                        <button onClick={() => handleArrowClick('UP')}>↑</button>                      
                    </div>
                    <div>
                        <button onClick={() => handleArrowClick('RIGHT')}>→</button>
                    </div>
                </div>
            )}
            <StarryBackground show={true} />      
        </div>
    );
};

export default Snake;