import React from "react";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useState } from 'react';
import "../Dizajn/Navigacija.css";

const NavigacijskaVrstica = () => {
    const cookies = new Cookies();
    const isLoggedIn = !!cookies.get('Motolegende');
    const [activePage, setActivePage] = useState('');

    const handlePageClick = (page) => {
        setActivePage(page);
        setBurgerOpen(false);
    };

    const [isBurgerOpen, setBurgerOpen] = useState(false);

    const handleBurgerClick = () => {
        setBurgerOpen(!isBurgerOpen);
    };


    return (

        <nav className="navbar">
            <div className="burger-icon" onClick={handleBurgerClick}>
                {/* Replace the 'src' attribute with the correct path to your image file */}
                <img src="/Logotipi/hamburger.png" className="burger-icon" alt="Burger Icon" />
            </div>
            <ul className={isBurgerOpen ? 'nav-links open' : 'nav-links'}>
                <li>
                    <Link to="/snake" className={activePage === 'snake' ? 'active' : ''} onClick={() => handlePageClick('snake')}>
                        90's
                    </Link>
                </li>
                <li>
                    <Link to="/" className={activePage === 'domov' ? 'active' : ''} onClick={() => handlePageClick('domov')}>
                        DOMOV
                    </Link>
                </li>
                <li>
                    <Link
                        to="/Motoavanture"
                        className={activePage === 'motoavanture' ? 'active' : ''}
                        onClick={() => handlePageClick('motoavanture')}
                    >
                        OBJAVE MOTORISTOV
                    </Link>
                </li>
                <li>
                    <Link
                        to="/Umetnost"
                        className={activePage === 'umetnost' ? 'active' : ''}
                        onClick={() => handlePageClick('umetnost')}
                    >
                        UMETNOST
                    </Link>
                </li>
                <li>
                    <Link
                        to="/Oglasimotorjev"
                        className={activePage === 'oglasimotorjev' ? 'active' : ''}
                        onClick={() => handlePageClick('oglasimotorjev')}
                    >
                        OGLASI MOTORJEV
                    </Link>
                </li>
                {isLoggedIn && (
                    <li>
                        <Link
                            to="/MojProfil"
                            className={activePage === 'mojprofil' ? 'active' : ''}
                            onClick={() => handlePageClick('mojprofil')}
                        >
                            MOJ PROFIL
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default NavigacijskaVrstica;

