import React, { useState , useEffect} from 'react';
import axios from 'axios';
import UniversalCookie from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import "./OddajAvanture.css";

const OddajAvanture = () => {

    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [image, setImage] = useState(null);
    const [uporabniskoImeOrEmail, setUporabniskoImeOrEmail] = useState('');
    const cookies = new UniversalCookie();
    const cookieName = 'Motolegende'; // Define the cookie name
    const navigate = useNavigate();
    const [previewImage, setPreviewImage] = useState(null);


    const handleText1Change = (e) => {
        setText1(e.target.value);
    };

    const handleText2Change = (e) => {
        setText2(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);

        // Display a preview of the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else {
            setPreviewImage(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const authToken = cookies.get(cookieName) && cookies.get(cookieName).token;
            const storedUporabniskoImeOrEmail = cookies.get(cookieName) && cookies.get(cookieName).username;

            if (!authToken || !storedUporabniskoImeOrEmail) {
                navigate('/Vpis'); // Redirect to the login page if no token or username is found
                return;
            }

            const compressedImage = await compressImage(image);

            const formData = new FormData();
            formData.append('text1', text1);
            formData.append('text2', text2);
            formData.append('image', compressedImage);
            formData.append('usernameOrEmail', storedUporabniskoImeOrEmail);

            await axios.post('/api/Avanture/avanture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authToken}`,
                },
            });

            console.log('Post created successfully');

            // Navigate to another page
            navigate('/Motoavanture'); 
        } catch (error) {
            console.error('Error creating post:', error.response ? error.response.data : error.message);
        }
    };

    //image compress
    const compressImage = async (imageFile) => {
        return new Promise((resolve, reject) => {
            const options = {
                maxSizeMB: 2.0, // Maximum size of the compressed image
                maxWidthOrHeight: 1920, // Maximum width or height of the compressed image
                useWebWorker: true,
            };

            try {
                const blobImage = new Blob([imageFile], { type: imageFile.type });

                imageCompression(blobImage, options)
                    .then((compressedBlob) => {
                        resolve(compressedBlob);
                    })
                    .catch((error) => {
                        console.error('Error compressing image:', error);
                        reject(error);
                    });
            } catch (error) {
                console.error('Error converting file to Blob:', error);
                reject(error);
            }
        });
    };

    //

    // Cookie handling
    useEffect(() => {
        const authToken = cookies.get(cookieName) && cookies.get(cookieName).token;
        const storedUporabniskoImeOrEmail = cookies.get(cookieName) && cookies.get(cookieName).username;

        if (authToken && storedUporabniskoImeOrEmail) {
            setUporabniskoImeOrEmail(storedUporabniskoImeOrEmail);
        } else {
            navigate('/Vpis'); // Redirect to the login page if no token or username is found
        }
    }, [cookies, cookieName, navigate]);

    return (
        <div className="oddajavanturekontejner">
        <h5>OBJAVI SVOJO NAJBOLJ KUL MOTORISTIČNO DOŽIVETJE</h5>
            <form onSubmit={handleSubmit} className="cool-form">
                <p className="oddajavatureimeuporabnika"> Uporabnik : &nbsp;  {uporabniskoImeOrEmail}</p>
                <div className="form-group">
                    <label htmlFor="text1">NASLOV ZGODBE:</label>
                    <input
                        type="text"
                        id="text1"
                        value={text1}
                        onChange={handleText1Change}
                        className="oddajavantureform-control"
                        required 
                    />
                </div>
                <div className="oddajavantureform-group">
                    <label htmlFor="text2">OPIS ZGODBE:</label>
                    <textarea
                        id="text2"
                        value={text2}
                        onChange={handleText2Change}
                        className="oddajavanturetextarea"
                        required 
                    />
                </div>
                <div className="oddajavantureform-group">
                    <label htmlFor="image">IZBERITE SLIKO:</label>
                    <input
                        type="file"
                        id="image"
                        accept=".jpg, .jpeg"
                        onChange={handleImageChange}
                        className="oddajavantureform-control"
                        required 
                    />
                </div>
                {previewImage && (
                    <div>
                        <img src={previewImage} alt="Selected" className="oddajaavantureimage" />
                    </div>
                )}
                <div className="oddajavantureform-group">
                    <button type="submit" className="oddajavanturebutton">Objavi Zgodbo</button>
                </div>
            </form>
        </div>
    );
};

export default OddajAvanture;