import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import "./DomovPodstrani.css";
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const Acdc2024 = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const pageId = 'Acdc2024';
    const videoRef1 = useRef(null);
    const videoSource1 = "/Slike/DomovSlike/Acdc2024/Acdc2024.mp4";
    const imageSource1 = "/Slike/DomovSlike/Acdc2024/video1.jpg";
    const [isVideoPlaying1, setIsVideoPlaying1] = useState(false);
    const videoTitle1 = "Video 1 : { Koncert skupine The Pretty Reckless in AC DC }";
    const [isLoading1, setIsLoading1] = useState(false);
    const [selectedImageTitle, setSelectedImageTitle] = useState('');

    const pageTitle = "Acdc2024";
    const pageDescription = "Legendarni koncert rokerske skupine AC DC";

    const toggleVideo1 = () => {
        setIsLoading1(true);

        // Access the video element using the ref
        if (videoRef1.current) {
            videoRef1.current.load(); // Start loading the video
        }

        setTimeout(() => {
            if (videoRef1.current) {
                videoRef1.current.play(); // Start playing the video
            }

            setIsLoading1(false);
            setIsVideoPlaying1(true);
        }, 3000); // 3-second delay
    };

    const closeVideo1 = () => {
        setIsVideoPlaying1(false);
        setIsLoading1(false);
    };

    // SLIKE
    //

    const [fullImageSrc, setFullImageSrc] = useState(null);

    const openFullImage = (src, title) => {
        setFullImageSrc(src);
        setSelectedImageTitle(title);
    };

    const closeFullImage = () => {
        setFullImageSrc(null);
        setSelectedImageTitle('');
    };

    useEffect(() => {
        // Fetch comments from the API and set them in reverse order
        axios.get(`/api/Komentar/komentar?pageId=${pageId}`)
            .then((response) => {
                setComments(response.data.reverse());
            })
            .catch((error) => {

            });

        // Check for cookies
        const cookies = new Cookies();
        const motolegendeCookie = cookies.get('Motolegende');
        const adminCookie = cookies.get('adminCookie');

        // Set isLoggedIn to true if either cookie exists
        if (motolegendeCookie || adminCookie) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleSubmitComment = () => {
        const cookies = new Cookies();
        const user = cookies.get('Motolegende');
        const admin = cookies.get('adminCookie');

        // Check isLoggedIn and comment content
        if ((user || admin) && newComment.trim() !== '') {
            const commentData = {
                userId: user ? user.id : admin.id,
                username: user ? user.username : admin.Administrator, // Use 'administrator' field as username
                content: newComment,
                timestamp: moment().format(),
                pageId: pageId,
            };
            // Send the new comment to the API with the pageId parameter
            axios.post(`/api/Komentar/createkomentar?pageId=${pageId}`, commentData)
                .then((response) => {
                    // Add the new comment to the top of the list
                    setComments([response.data, ...comments]);
                    setNewComment('');
                })
                .catch((error) => {

                });
        } else {

        };
    };

    const deleteComment = (commentId) => {
        axios
            .delete(`/api/Komentar/deletekomentar/${commentId}`)
            .then(() => {
                // Remove the deleted comment from the state
                setComments(comments.filter(comment => comment.id !== commentId));
            })
            .catch((error) => {

            });
    };

    return (
        <div className="okvirkontejnerja">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Domov/Acdc2024" />
                {/* Add other metadata tags as needed */}
            </Helmet>         
            <div className="vsebinakontejnerja">             
                <div className="levikontejner">
                    <div className="large-image-container">
                        <img
                            src="/Slike//DomovSlike/acdc2024/slika1.jpg"
                            alt="Ac dc 2024 Dunaj Wien"
                            className="podstrandomovimage"
                            loading="lazy"
                            onClick={() => openFullImage('/Slike//DomovSlike/acdc2024/slika1.jpg' , "Slika 1 : ( Panoramski razgled koncerta AC-CD. )")}
                        />
                        <br></br>
                        <p className="podstranislikeopis">Slika 1 : Prikaz skupine AC DC</p>
                    </div>
                    <div className="small-images-container">
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/acdc2024/slika3.jpg" alt="Ac dc 2024 Dunaj Wien" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/acdc2024/slika3.jpg" , "Slika 2 : ( Cena vstopnice za koncert. )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2 : Panoramski pogled štadijona Ernst Happel</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/acdc2024/slika2.jpg" alt="Ac dc 2024 Dunaj Wien" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/acdc2024/slika2.jpg', "Slika 3 : ( Izboljšan panoramski razgled na koncert z pivom.  )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 3 : Panorama pred začetkom koncerta</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/acdc2024/slika4.jpg" alt="Ac dc 2024 Dunaj Wien" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/acdc2024/slika4.jpg', "Slika 4 : ( Zvesti oboževalci rokerske skupine AC-DC .  )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 4 : Panorama pred začetkom koncerta</p>
                        </div>
                        {fullImageSrc && (
                            <div className="full-image-overlay" onClick={closeFullImage}>
                                <img src={fullImageSrc} alt="celotnaslika" className="full-img" />
                                <h5 className="koizbereslikoopis">{selectedImageTitle}</h5>
                            </div>
                        )}
                    </div>


                    <div>
                        {isLoading1 && (
                            <div className="video-overlay" onClick={closeVideo1}>
                                <div className="loading-overlay">
                                    <div style={{ color: 'white' }}>
                                        <h1>Nalaganje videoposnetka...</h1>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isVideoPlaying1 && (
                            <div className="video-overlay" onClick={closeVideo1}>
                                <video ref={videoRef1} controls style={{ height: '600px' }} preload="auto" loading="lazy" onClick={(e) => e.stopPropagation()}>
                                    <source src={videoSource1} type="video/mp4" />
                                </video>
                                <div className="video-title">{videoTitle1}</div>
                            </div>
                        )}

                        {!isVideoPlaying1 && !isLoading1 && (
                            <div>
                                <img src={imageSource1} alt="Motolegende video 2024" onClick={toggleVideo1} loading="lazy" className="videoposnetki" />
                            </div>
                        )}
                    </div>


                    <br></br>
                    <p className="podstranislikeopis">Avtor Fotografij : Rock 'N' Roll. </p>
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Verona2023" rel="noopener noreferrer">Motoristični sejem MBE (Motor Bike Expo 2023)</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Verona2023/slika3.jpg" width="150px;" />
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Grobnik" rel="noopener noreferrer">Dirkališče Grobnik - Hrvaška</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Grobnik/grobnik2.jpg" width="150px;" />
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Eicma2023" rel="noopener noreferrer">Motoristični sejem EICMA 2023 (Milano)</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Eicma2023/africatwin2023.jpg" width="150px;" />
                    <br></br>

                    <br></br>
                    <p className="podstranislikeopis">Avtor Fotografij : (Rock 'N' Roll) </p>
                    <img
                        src="/Logotipi/Motolegende.png"
                        alt="Motolegende"
                        className="podstranilogoslika"
                    />
                    <p className="podstranilogoslikaime">Powered From 2024</p>
                </div>
                <div className="desnikontejner">
                    <h1 className="naslovzapodnaslovdomov">KONCERT LEGENDARNE ROKERSKE SKUPINE AC-DC 2024 -VIENNA</h1>
                    <br></br>
                    <h6>- Država : Avstrija  <img src="/Logotipi/avstrija.png" alt="avstrija" className="ikonedrzave" /></h6>
                    <h6>- Lokacija : Dunaj(Wien) - (Ernst Happel Stadion)</h6>
                    <h6>- Čas vožnje z avtomobilom : 3ure  <img src="/Logotipi/motolegendeikonamini.png" alt="Avstrija" style={{ width: "45px" }} /> </h6>
                    <h6>- Čas vožnje z avtobusom : 5ur</h6>
                    <h6>- Naslov : Meiereistraße 7, 1020 Wien  <img src="/Logotipi/lokacijaikona.png" alt="Dunaj" style={{ width: "25px" }} /></h6>
                    <p className="desnikontejnertext">
                        Dne 23.06.2024 smo imeli čast v živo poslušati legendarno rokersko skupino AC DC. Njihov bend že deluje od leta 1973 naprej, ki so imeli prvi nastop v nočnem klubu v Sydneyju.
                        Prevoz smo si rezervirali preko spletnega portala koncerti.net. Cena prevoza z avtobusom na osebo je bila 50€. Zbor in odhod avtobusa je bil ob 10.uri zjutraj pri dvorani Golovec v Celju. Z avtobusom nas je štartalo okoli 50 z odličnima šoferjema in vodičko. Za vstopnico smo odšteli minimalno 150 - 250 € na osebo. Cene piva pa so se nagibale kvalitetnih 10€. Če je kdo hotel jesti pa je odštel za
                        hamburger cca.15€. Kapaciteta množice je znašala okoli 60.000 ljudi in z rokerskim programom so začeli ob 18.uri. Čas trajanja koncerta z predskupino The Pretty Reckless in glavno skupino AC DC je trajal približno 3 ure. Vendar se moramo paziti avstrijcev, povejo da lahko posamezen lonček za pivo vrnemo in dobimo 3€ dobropisa, vendar pred koncem tekme blagajne zaprejo (wau). 
                        Ocena koncerta: Rock "N" Roll (10). 
                          <img src="/Logotipi/vsecek2.png" alt="nasmeh" style={{ width: '20px' }} />
                    </p>

                    <p>Članek objavljen : 26.06.2024</p>
                    <div className="komentarcelotnikontejner">
                        <h3 alt="klepetalnica">KLEPETALNICA : </h3>
                        <p>Število Komentarjev: {comments.length}</p>
                        <div className="komentarlist">
                            {comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort comments by timestamp in descending order
                                .map((comment) => (
                                    <div key={comment.id} className="komentar">
                                        <strong className="uporabnikime">{comment.username}</strong>: {comment.content}
                                        <br />
                                        <span className="timestamp">{moment(comment.createdAt).format("DD MMMM YYYY  HH:mm")}</span>
                                    </div>
                                ))}
                        </div>
                        <div className="komentarinput">
                            {isLoggedIn ? (
                                <div>
                                    <textarea
                                        rows="3"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        className="komentartextarea"
                                        placeholder="Komentiraj Vsebino ..."
                                    />
                                    <br />
                                    <button onClick={handleSubmitComment} className="komentarbutton">Pošlji Komentar</button>
                                </div>
                            ) : (
                                <p>Če želite komentirati, se prijavite...</p>
                            )}
                        </div>
                    </div>
                    {isLoggedIn && adminCookie && comments.map((comment) => (
                        <div key={comment.id} className="komentar">
                            <strong>{comment.username}</strong>: {comment.content}
                            <br />
                            <span className="timestamp">{moment(comment.timestamp).format("LLL")}</span>
                            <button onClick={() => deleteComment(comment.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Acdc2024;
