import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const HendricksGin = () => {
    const pageTitle = "Najboljši gin Hendricks Gin";
    const pageDescription = "Hendricks Gin";


    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/Vinoteka/HendricksGin" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\klet2.jpg" alt="Vinoteka klet" className="vinotekaumetnostozadje2" />
            <center><h1 className="k5" alt="Hendricks gin">IDEALEN GIN - HENDRICKS GIN</h1></center>
            <center><p className="k5" alt="idealni gin tonic">( - Ste naveličani vode ? - )</p></center>
            <p className="k4">- Objavljeno dne :  2.07.2024</p>
            <div className="k1">
                <img src="Slike\DomovSlike\Umetnost\Vinoteka\VinotekaSlike\slika6.jpg" alt="Hendricks gin" className="vinotekaodprtaklincevaslika" />
                <p className="k2"> Ste si zmešali kdaj gin-tonic vendar okus je bil čuden in ste se vedno spraševali ali je narobe glasba ali gin?. Odgovor je oboje dvoje. Hendricks gin spada med kvalitetnejše gine, ki vas popelje mimo glasbe in okusa.
                    Ustanovljen je bil leta 1999 na Škotskem. Cena za posamezno steklenico 700ml znaša 40€ in prihaja z kvalitetno 42% vsebnostjo alkohola. Gin je kot služba, prvo vse štima, pol pa pride za tabo. Priporočeno je uravnovešeno pitje.
                 Ocena Hendricks gina : (10.4/10).
                </p>
            </div>

            <div>
                <a href="https://www.motolegende.si/Umetnost/Vinoteka">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default HendricksGin;