import React, { useEffect, useRef } from 'react';
import './StarryBackground.css';


function StarryBackground({ show }) {
    const starContainerRef = useRef(null); // Create a ref to the star container

    useEffect(() => {
        const starContainer = starContainerRef.current;

        function createStar() {
            const star = document.createElement('div');
            star.classList.add('star');
            star.style.left = `${Math.random() * 100}%`;
            star.style.top = `${Math.random() * 100}%`;
            star.style.animationDuration = `${Math.random() * 10 + 5}s`;
            star.style.animationDelay = `${Math.random() * 3}s`;
            starContainer.appendChild(star);
        }

        // Generate a certain number of stars
        const numberOfStars = 200;
        for (let i = 0; i < numberOfStars; i++) {
            createStar();
        }
    }, []);


    return (
        <div className="starry-background">
            <div className="star-container" ref={starContainerRef}></div>
        </div>
    );
}

export default StarryBackground;