import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import "./DomovPodstrani.css";
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const Verona2023 = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const pageId = 'Verona2023';
    const pageTitle = "Motor Bike Expo 2023";
    const pageDescription = "Motoristični sejem";

    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);

    const videoSource1 = "/Slike/DomovSlike/Verona2023/video1.mp4";
    const videoSource2 = "/Slike/DomovSlike/Verona2023/video2.mp4";

    const imageSource1 = "/Slike/DomovSlike/Verona2023/thumbnail1.jpg";
    const imageSource2 = "/Slike/DomovSlike/Verona2023/thumbnail2.jpg";


    const [isVideoPlaying1, setIsVideoPlaying1] = useState(false);
    const [isVideoPlaying2, setIsVideoPlaying2] = useState(false);

    const videoTitle1 = "Video 1 : { Driftanje Tovornjakov in Avtomobilov }";
    const videoTitle2 = "Video 2 : { Driftanje predelanih avtomobilov }";

    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);


    const toggleVideo1 = () => {
        setIsLoading1(true);

        // Access the video element using the ref
        if (videoRef1.current) {
            videoRef1.current.load(); // Start loading the video
        }

        setTimeout(() => {
            if (videoRef1.current) {
                videoRef1.current.play(); // Start playing the video
            }

            setIsLoading1(false);
            setIsVideoPlaying1(true);
        }, 3000); // 3-second delay
    };

    const toggleVideo2 = () => {
        setIsLoading2(true);

        // Access the video element using the ref
        if (videoRef2.current) {
            videoRef2.current.load(); // Start loading the video
        }

        setTimeout(() => {
            if (videoRef2.current) {
                videoRef2.current.play(); // Start playing the video
            }

            setIsLoading2(false);
            setIsVideoPlaying2(true);
        }, 3000); // 3-second delay
    };

    const closeVideo1 = () => {
        setIsVideoPlaying1(false);
        setIsLoading1(false);
    };

    const closeVideo2 = () => {
        setIsVideoPlaying2(false);
        setIsLoading2(false);
    };


    // SLIKE
    //

    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'auto' // Use 'auto' for an instant jump to the top
        });
    }, []);

    const [fullImageSrc, setFullImageSrc] = useState(null);
    const [selectedImageTitle, setSelectedImageTitle] = useState('');

    const openFullImage = (src, title) => {
        setFullImageSrc(src);
        setSelectedImageTitle(title);
    };

    const closeFullImage = () => {
        setFullImageSrc(null);
        setSelectedImageTitle('');
    };

    useEffect(() => {
        // Fetch comments from the API and set them in reverse order
        axios.get(`/api/Komentar/komentar?pageId=${pageId}`)
            .then((response) => {
                setComments(response.data.reverse());
            })
            .catch((error) => {
          
            });

        // Check for cookies
        const cookies = new Cookies();
        const motolegendeCookie = cookies.get('Motolegende');
        const adminCookie = cookies.get('adminCookie');

        // Set isLoggedIn to true if either cookie exists
        if (motolegendeCookie || adminCookie) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleSubmitComment = () => {
        const cookies = new Cookies();
        const user = cookies.get('Motolegende');
        const admin = cookies.get('adminCookie');

        // Check isLoggedIn and comment content
        if ((user || admin) && newComment.trim() !== '') {
            const commentData = {
                userId: user ? user.id : admin.id,
                username: user ? user.username : admin.Administrator, // Use 'administrator' field as username
                content: newComment,
                timestamp: moment().format(),
                pageId: pageId,
            };
            // Send the new comment to the API with the pageId parameter
            axios.post(`/api/Komentar/createkomentar?pageId=${pageId}`, commentData)
                .then((response) => {
                    // Add the new comment to the top of the list
                    setComments([response.data, ...comments]);
                    setNewComment('');
                })
                .catch((error) => {
                  
                });
        } else {
       
        };
    };

    const deleteComment = (commentId) => {
        axios
            .delete(`/api/Komentar/deletekomentar/${commentId}`)
            .then(() => {
                // Remove the deleted comment from the state
                setComments(comments.filter(comment => comment.id !== commentId));
            })
            .catch((error) => {
               
            });
    };

    return (
        <div className="okvirkontejnerja">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Domov/Verona2023" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <div className="vsebinakontejnerja">
                <div className="levikontejner">
                    <div className="large-image-container">
                        <img
                            src="/Slike//DomovSlike/Verona2023/slika1.jpg"
                            alt="Custom Harley Davidson chopper"
                            className="podstrandomovimage"
                            onClick={() => openFullImage('/Slike//DomovSlike/Verona2023/slika1.jpg', "Slika 1 : ( Custom Harley Davidson )")}
                            loading="lazy"
                        />
                        <br></br>
                        <p className="podstranislikeopis">Slika 1 : (Custom Harley Davidson.) </p>
                    </div>
                    <div className="small-images-container">
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Verona2023/slika2.jpg" alt="Custom K-Quad štirikolesnik" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/Verona2023/slika2.jpg", "Slika 2 : ( Predelani K-Quad štirikolesnik )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2 : (Predelani K-Quad štirikolesnik.)</p>
                        </div>                   
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Verona2023/slika4.jpg" alt="Predelani motocikli" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/Verona2023/slika4.jpg", "Slika 3 : ( Razstava predelanih motociklov )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 3 : (Razstava predelanih motociklov.)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Verona2023/slika3.jpg" alt="Dj in tovornjak z hrano" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/Verona2023/slika3.jpg", "Slika 4 : ( Prikolica, Hrana in Rokenrol )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 4 : (Prikolica, Hrana in Rokenrol)</p>
                        </div>
                        {fullImageSrc && (
                            <div className="full-image-overlay" onClick={closeFullImage}>
                                <img src={fullImageSrc} alt="celotnaslika" className="full-img" />
                                <h5 className="koizbereslikoopis">{selectedImageTitle}</h5>
                            </div>
                        )}                      
                    </div>

                    <div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Verona2023/slika5.jpg" alt="Harley Davidson in Vespa" className="img" loading="lazy" onClick={() => openFullImage("/Slike//DomovSlike/Verona2023/slika5.jpg", "Slika 5 : ( Custom Harley Davidsoni in Vespa )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 5 : (Custom Harley Davidsoni in Vespa)</p>
                        </div>
           
                        <div>
                            {isVideoPlaying1 && (
                                <div className="video-overlay" onClick={closeVideo1}>
                                    <video ref={videoRef1} controls style={{ height: '600px' }} preload="auto" loading="lazy" onClick={(e) => e.stopPropagation()}>
                                        <source src={videoSource1} type="video/mp4" />
                                    </video>
                                    <div className="video-title">{videoTitle1}</div>
                                </div>
                            )}

                            {isLoading1 && (
                                <div className="video-overlay">
                                    <div style={{ color: 'white' }}>
                                        <h1>Nalaganje videoposnetka...</h1>
                                    </div>
                                </div>
                            )}

                            {!isVideoPlaying1 && !isLoading1 && (
                                <img src={imageSource1} alt="Driftanje tovornih vozil" onClick={toggleVideo1} loading="lazy" className="videoposnetki" />
                            )}

                            {isVideoPlaying2 && (
                                <div className="video-overlay" onClick={closeVideo2}>
                                    <video ref={videoRef2} controls style={{ height: '600px' }} preload="auto" loading="lazy" onClick={(e) => e.stopPropagation()}>
                                        <source src={videoSource2} type="video/mp4" />
                                    </video>
                                    <div className="video-title">{videoTitle2}</div>
                                </div>
                            )}

                            {isLoading2 && (
                                <div className="video-overlay">
                                    <div style={{ color: 'white' }}>
                                        <h1>Nalaganje videoposnetka...</h1>
                                    </div>
                                </div>
                            )}

                            {!isVideoPlaying2 && !isLoading2 && (
                                <img src={imageSource2} alt="Driftanje predelanih avtomobilov" onClick={toggleVideo2} loading="lazy" className="videoposnetki" />
                            )}
                        </div>
   
                    </div>                 

                    <br></br>
                    <p className="podstranislikeopis">Avtor Fotografij : Rock 'N' Roll. </p>
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/UmagSejem2024" rel="noopener noreferrer">Sejem predelanih vozil Umag 2024</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/UmagSejem2024/avto1.jpg" width="150px;" />
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Eicma2023" rel="noopener noreferrer">Motoristični sejem EICMA 2023 (Milano)</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Eicma2023/africatwin2023.jpg" width="150px;" />
                    <br></br>

                    <img
                        src="/Logotipi/Motolegende.png"
                        alt="Rock 'N' Roll"
                        className="podstranilogoslika"
                    />
                    <p className="podstranilogoslikaime">Powered From 2024</p>
                </div>
                <div className="desnikontejner">
                    <h1 className="naslovzapodnaslovdomov">SEJEM MBE (Motor Bike Expo 2023 - VERONA)</h1>
                    <br></br>
                    <h6>- Država : Italija  <img src="/Logotipi/italija.png" alt="Italija" className="ikonedrzave" /> </h6>
                    <h6>- Lokacija : Verona (cca -430km , Avtomobil: 4,5 ure, Mercedes: 4ure)  <img src="/Logotipi/motolegendeikonamini.png" alt="Verona" style={{ width: "45px" }} />  </h6>
                    <h6>- Naslov : Viale del Lavoro 8 – 37135 Verona  <img src="/Logotipi/lokacijaikona.png" alt="Verona" style={{ width: "25px" }} /></h6>
                    <p className="desnikontejnertext">
                        Za vse motoriste, ki ste ljubitelji čoperjev in retro motociklov ali avtomobilov, ta sejem je direktno za vas. Obiskali smo motoristični sejem Motor Bike
                        Expo 2023 v Veroni - Italija. Začetek sejma je bil 27.januarja 2023 in vstopnica za posamezno osebo je znašala cca. 18€. Bili smo pozitivno presenečeni na vso ponudbo, motorje, avtomobile, tovornjake, dekleta in glasbo. Če ste ljubitelj retro motociklov,
                        avtomobilov in rock "n" rolla je ta sejem definitivno za vas. Široka ponudba Harley Davidsonov, Indian, Victory, Big Dog, Iron Horse ter ostalih custom motociklov, štirikolesnikov. Posamezna cena piva je znašala okoli 5€ in burgerji okoli 12€ vendar ni bilo panike, odvisno od osebe - 2 piva za 1 burger.
                        Vzdušje je bilo fenomenalno, od kromiranih štantov za hrano, DJ-jev, motornih poslikav in poslikav na dekleta. 
                        Pozorni pa morate biti na parkirne prostore ter garažne hiše, ker Italijani kasirajo parkirni prostor izven našega osončja in po milimetrih.
                        Priporočeno je, da si pred začetkom sejma vsaj predčasno rezervirate hotel ali apartma v bližini, ker zadnji mesec so cene dvojne in v Italiji ne pomaga šarmantnost, karizmatičnosti in dijaška izkaznica.
                        Ocena sejma: Rock "N" Roll (10).  <img src="/Logotipi/vsecek2.png" alt="motolegende" className="vsecekikona" />
                    </p>
                    <p>Članek objavljen : 10.01.2024</p>
                    <div className="komentarcelotnikontejner">
                        <h3 alt="klepetalnica">KLEPETALNICA : </h3>
                        <p>Število Komentarjev: {comments.length}</p>
                        <div className="komentarlist">
                            {comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort comments by timestamp in descending order
                                .map((comment) => (
                                    <div key={comment.id} className="komentar">
                                        <strong className="uporabnikime">{comment.username}</strong>: {comment.content}
                                        <br />
                                        <span className="timestamp">{moment(comment.createdAt).format("DD MMMM YYYY  HH:mm")}</span>
                                    </div>
                                ))}
                        </div>
                        <div className="komentarinput">
                            {isLoggedIn ? (
                                <div>
                                    <textarea
                                        rows="3"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        className="komentartextarea"
                                        placeholder="Komentiraj Vsebino ..."
                                    />
                                    <br />
                                    <button onClick={handleSubmitComment} className="komentarbutton">Pošlji Komentar</button>
                                </div>
                            ) : (
                                <p>Če želite komentirati, se prijavite...</p>
                            )}
                        </div>
                    </div>
                    {isLoggedIn && adminCookie && comments.map((comment) => (
                        <div key={comment.id} className="komentar">
                            <strong>{comment.username}</strong>: {comment.content}
                            <br />
                            <span className="timestamp">{moment(comment.timestamp).format("LLL")}</span>
                            <button onClick={() => deleteComment(comment.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Verona2023;