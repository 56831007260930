import React, { useEffect, useState } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import "./DomovPodstrani.css";
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';


const Eicma2023 = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const pageId = 'Eicma2023'; 
    const pageTitle = "Eicma 2023";
    const pageDescription = "Motoristični sejem";

    const [fullImageSrc, setFullImageSrc] = useState(null);
    const [selectedImageTitle, setSelectedImageTitle] = useState('');

    const openFullImage = (src, title) => {
        setFullImageSrc(src);
        setSelectedImageTitle(title);
    };

    const closeFullImage = () => {
        setFullImageSrc(null);
        setSelectedImageTitle('');
    };

    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'auto' // Use 'auto' for an instant jump to the top
        });
    }, []);

    useEffect(() => {
        // Fetch comments from the API and set them in reverse order
        axios.get(`/api/Komentar/komentar?pageId=${pageId}`)
            .then((response) => {
                setComments(response.data.reverse());
            })
            .catch((error) => {
                console.error('Error fetching comments:', error);
            });

        // Check for cookies
        const cookies = new Cookies();
        const motolegendeCookie = cookies.get('Motolegende');
        const adminCookie = cookies.get('adminCookie');

        // Set isLoggedIn to true if either cookie exists
        if (motolegendeCookie || adminCookie) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleSubmitComment = () => {
        const cookies = new Cookies();
        const user = cookies.get('Motolegende');
        const admin = cookies.get('adminCookie');

        // Check isLoggedIn and comment content
        if ((user || admin) && newComment.trim() !== '') {
            const commentData = {
                userId: user ? user.id : admin.id,
                username: user ? user.username : admin.Administrator, // Use 'administrator' field as username
                content: newComment,
                timestamp: moment().format(),
                pageId: pageId,
            };
            // Send the new comment to the API with the pageId parameter
            axios.post(`/api/Komentar/createkomentar?pageId=${pageId}`, commentData)
                .then((response) => {
                    // Add the new comment to the top of the list
                    setComments([response.data, ...comments]);
                    setNewComment('');
                })
                .catch((error) => {
                    console.error('Error submitting comment:', error);
                });
        } else {
            console.error('No logged-in user found or the comment is empty.');
        };
    };

    const deleteComment = (commentId) => {
        axios
            .delete(`/api/Komentar/deletekomentar/${commentId}`)
            .then(() => {
                // Remove the deleted comment from the state
                setComments(comments.filter(comment => comment.id !== commentId));
            })
            .catch((error) => {
                console.error('Error deleting comment:', error);
            });
    };

    return (
        <div className="okvirkontejnerja">

            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Domov/Eicma2023" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <div className="vsebinakontejnerja">
                <div className="levikontejner">
                    <div className="large-image-container">
                        <img
                            src="/Slike//DomovSlike/Eicma2023/africatwin2023.jpg"
                            alt="Africa Twin 2023,1100cc "
                            className="podstrandomovimage"
                            loading="lazy" 
                            onClick={() => openFullImage('/Slike//DomovSlike/Eicma2023/africatwin2023.jpg', "Slika 1: (Honda Africa Twin 1100 (Letnik: 2023))")}
                        />
                        <br></br>
                        <p className="podstranislikeopis">Slika 1: (Honda Africa Twin 1100 (Letnik: 2023))</p>                 
                    </div>
                    <div className="small-images-container">
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Eicma2023/mugeloracing.jpg" alt="Simulator dirkanja Mugello" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Eicma2023/mugeloracing.jpg', "Slika 2: ( Simulator dirkanja Mugello )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2: (Simulator dirkanja Mugello)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Eicma2023/eicmachopper.jpg" alt="Predelani motocikel" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Eicma2023/eicmachopper.jpg', "Slika 2: ( Custom Chopper )")} />
                            <br></br>
                            <p className="podstranislikeopis">Slika 2: (Custom Chopper)</p>
                        </div>
                        <div className="small-image">
                            <img src="/Slike//DomovSlike/Eicma2023/Mitt2023.jpg" alt="coper znamke mitt" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Eicma2023/Mitt2023.jpg', "Slika 3: ( Čoper znamke Mitt 808 )")} />
                            <br></br>                           
                            <p className="podstranislikeopis">Slika 3: (Znamka Mitt 808)</p>
                        </div>
                 
                        {fullImageSrc && (
                            <div className="full-image-overlay" onClick={closeFullImage}>
                                <img src={fullImageSrc} alt="celotnaslika" className="full-img" />
                                <h5 className="koizbereslikoopis">{selectedImageTitle}</h5>
                            </div>
                        )}
                    </div>
                    <div className="small-image">
                        <img src="/Slike//DomovSlike/Eicma2023/pivo.jpg" alt="heineken pivo" className="img" loading="lazy" onClick={() => openFullImage('/Slike//DomovSlike/Eicma2023/pivo.jpg', "Slika 3: ( Eden izmed zlatih gralov piv )")} />
                        <br></br>
                        <p className="podstranislikeopis">Slika 3: (Najbolše od sejma Eicma 2023)</p>
                    </div>

                    <br></br>
                    <p className="podstranislikeopis">Avtor Fotografij : Rock 'N' Roll. </p>
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Verona2023" rel="noopener noreferrer">Motoristični sejem MBE (Motor Bike Expo 2023)</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Verona2023/slika3.jpg" width="150px;" />
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/Grobnik" rel="noopener noreferrer">Dirkališče Grobnik - Hrvaška</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/Grobnik/grobnik2.jpg" width="150px;" />
                    <br></br>
                    <a href="https://www.motolegende.si/Domov/UmagSejem2024" rel="noopener noreferrer">Sejem predelanih vozil Umag 2024</a>
                    <br></br>
                    <img src="/Slike//DomovSlike/UmagSejem2024/avto2.jpg" width="150px;" />
                    <br></br>

                    <img
                        src="/Logotipi/Motolegende.png"
                        alt="rock n roll"
                        className="podstranilogoslika"
                        loading="lazy" 
                    />
                    <p className="podstranilogoslikaime">Powered From 2024</p>
                </div>
                <div className="desnikontejner">
                    <h1 className="naslovzapodnaslovdomov">MOTORISTIČNI SEJEM (EICMA 2023) MILANO</h1>
                    <br></br>
                    <h6>- Država : Italija  <img src="/Logotipi/italija.png" alt="Italija" className="ikonedrzave" /> </h6>
                    <h6>- Lokacija : Milano (cca-580km, Avtomobil: 6,5 ure, Mercedes: 6ur)  <img src="/Logotipi/motolegendeikonamini.png" alt="Eicma Milano" style={{ width: "45px" }} /> </h6>
                    <h6>- Naslov : Strada Statale Sempione 28, 20017 Rho MI, Italija  <img src="/Logotipi/lokacijaikona.png" alt="Eicma Milano" style={{ width: "25px" }} /></h6>
                    <p className="desnikontejnertext">
                        Dne 10.11.2023 smo obiskali največji evropski sejem motorjev (Eicma2023). Število obiskovalcev je vsako leto večje vendar pri kvaliteti pa ne moremo povedati
                        za enako stvar. Očitno se vse nagiba k količini namesto kvalitete. Z leti na motociklističnem sejmu Eicma, kvaliteta pada in cene rastejo. Kitajci in elektrika pa nas bodo preganjali do konca osončja in naprej.
                        Zato moramo motociklistični ljubitelji moliti k bencinskim bogovom za motorje brez elektrike. Za vstopnice smo plačali cca. 25€ na osebo, vendar Italijani omogočajo poseben sistem spletnih nakupov vstopnic, ki ponudi 20% popust, vendar ne dela (mogoče samo za domače državljane). 
                        Zadnjih par let imajo v Eicmi veliko težav, ker znane motociklistične znamke kot Harley Davidson, BMW in Indian zapuščajo sejem. V letu 2023 je bila slaba 1/2 sejma kitajskih blagovnih znamk. Namesto motorjev in pokanja iz izpuha,
                        ki ti prinese užitek v srce, smo dobili estrogenske električne komarje, ki te ob vožnji delajo živčnega. Namesto, da bi na sejemu prevladovali bencinski motorji, je bil poln akomulatorjev in električnih skirojev, skuterjev, buggijev in rezervnih delov. Sejem smo obiskali po 5 letih, in naslednjega bomo verjetno še dlje.
                        Ocena sejma: Rock "N" Roll (neopredeljeno).  <img src="/Logotipi/vsecek2.png" alt="like button" className="vsecekikona" />
                    </p>
                    <p>Članek objavljen : 17.11.2023</p>
                    <div className="komentarcelotnikontejner">
                        <h3 className="klepetalnicatext" alt="klepetalnica">KLEPETALNICA : </h3>
                        <p className="klepetalnicatext">Število Komentarjev: {comments.length}</p>
                        <div className="komentarlist">
                            {comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort comments by timestamp in descending order
                                .map((comment) => (
                                    <div key={comment.id} className="komentar">
                                        <strong className="uporabnikime">{comment.username}</strong>: {comment.content}
                                        <br />
                                        <span className="timestamp">{moment(comment.createdAt).format("DD MMMM YYYY  HH:mm")}</span>
                                    </div>
                                ))}
                        </div>
                        <div className="komentarinput">
                            {isLoggedIn ? (
                                <div>
                                    <textarea
                                        rows="3"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        className="komentartextarea"
                                        placeholder="Komentiraj Vsebino ..."
                                    />
                                    <br />
                                    <button onClick={handleSubmitComment} className="komentarbutton">Pošlji Komentar</button>
                                </div>
                            ) : (
                                <p>Če želite komentirati, se prijavite...</p>
                            )}
                        </div>
                    </div>
                    {isLoggedIn && adminCookie && comments.map((comment) => (
                        <div key={comment.id} className="komentar">
                            <strong>{comment.username}</strong>: {comment.content}
                            <br />
                            <span className="timestamp">{moment(comment.timestamp).format("LLL")}</span>
                            <button onClick={() => deleteComment(comment.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Eicma2023;