import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';


const PortoKatsikiBeach = () => {
    const pageTitle = "Ena izmed najlepših plaž v Grčiji Porto Katsiki Beach";
    const pageDescription = "Ena izmed najlepših plaž v Grčiji Porto Katsiki Beach";

    return (

        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/PortoKatsikiBeach" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\PortoKatsiki.jpg" alt="Porto Katsiki Beach" className="vinotekaumetnostozadje2" />
            <br></br>
                <center>
                <h3 className="k5" alt="najboljša plaža v grčiji porto katsiki beach">ENA IZMED TOP PLAŽ V GRČIJI (PORTO KATSIKI BEACH)</h3>
                <p style={{color:"white"} }>( - Sonček, kokteli in sinje modro morje - )</p>
                <div>                  
                    <img src="Slike\DomovSlike\Umetnost\PortoKatsikiBeach\slika1.jpg" alt="porto katsiki beach grčija" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\PortoKatsikiBeach\slika3.jpg" alt="porto katsiki plaža grčija" className="umetnostpodstranivecslik" />  
                    <img src="Slike\DomovSlike\Umetnost\PortoKatsikiBeach\slika2.jpg" alt="porto katsiki plaža grčija" className="umetnostpodstranivecslik" /> 
                    </div>
                </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 29.06.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }} alt="opis plaže v grčiji">Če ste ljubitelj eksotičnih plaž s sinje modrim morjem, je Porto Katsiki plaža ena izmed top destinacij v Grčiji.
                    Odšteti morate visoko ceno parkinga pred plažo in se boste takoj počutili bolje. Svetujemo, da si pred nastakom na plažo vzamete dežnik za senco, saj tam je golo skalovje in plaža, opekline pa dobiš zastonj.
                    Plaža Porto Katsiki se nahaja na Grškem otoku Lefkada, nahaja se 35km od mesta Lefkada . Če potujete z avtomobilom je do tja iz Slovenije cca.1400 km. Če hočete na dopust peljati svojo drago, je otok Lefkada v Grčji odlična izbira.
                    Ocena otoka in pustolovščin na njem (10/10).
            </p>
                <div>
                    <a href="https://www.motolegende.si/Umetnost">
                        <img src="/Logotipi/nazaj.png" className="k6" />
                    </a>
                </div>        
        </div>

    );
};

export default PortoKatsikiBeach;