import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './OglasiMotorjev.css'; 
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';


const OglasiMotorjev = () => {
    const [data, setData] = useState([]);
    const [oglasList, setOglasList] = useState([]);
    const [currentItems, setCurrentItems] = useState([]);
    const navigate = useNavigate();
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const maxVisiblePages = 3;
    const [selectedZnamka, setSelectedZnamka] = useState('');
    const [numberOfOglasi, setNumberOfOglasi] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedProstornina, setSelectedProstornina] = useState('');
    const [selectedPogon, setSelectedPogon] = useState('');
    const cookies = new Cookies();
    const adminCookie = cookies.get('adminCookie');
    const pageTitle = "Oglasi motorjev";
    const pageDescription = "Najnovejši moto oglasi";
    const { page } = useParams();

    const [filteredOglasList, setFilteredOglasList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/Oglas/getoglas');
                // Reverse the order of the data to have the newest posts on the left
                const reversedOglasList = response.data.reverse();
                setOglasList(reversedOglasList);
                setCurrentItems(reversedOglasList.slice(0, itemsPerPage));
                setNumberOfOglasi(reversedOglasList.length);
            } catch (error) {

            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [triggerFetch]);

    useEffect(() => {
        if (!loading) {
            const totalPages = Math.ceil(oglasList.length / itemsPerPage);
            const newPage = Math.max(1, Math.min(parseInt(page) || currentPage, totalPages));

            const startIndex = (newPage - 1) * itemsPerPage;
            const endIndex = Math.min(startIndex + itemsPerPage, oglasList.length);

            setCurrentPage(newPage);
            setCurrentItems(oglasList.slice(startIndex, endIndex));

            // Update the URL
            navigate(`/OglasiMotorjev/page/${newPage}`);
        }
    }, [oglasList, currentPage, itemsPerPage, navigate, page, loading]);

    useEffect(() => {
        let newData = oglasList;

        // Apply your filtering logic here based on selected filters
        if (selectedZnamka !== '') {
            newData = newData.filter((oglas) => oglas.znamka === selectedZnamka);
        }
        if (selectedCategory !== '') {
            newData = newData.filter((oglas) => oglas.vrstaMotorja === selectedCategory);
        }
        if (selectedProstornina !== '') {
            const [start, end] = selectedProstornina.split('-').map(Number);
            newData = newData.filter((oglas) => oglas.prostornina >= start && oglas.prostornina <= end);
        }
        if (selectedPogon !== '') {
            newData = newData.filter((oglas) => oglas.pogon === selectedPogon);
        }

        setFilteredOglasList(newData);
    }, [oglasList, selectedZnamka, selectedCategory, selectedProstornina, selectedPogon]);

    const handleItemClick = (oglas) => {
        // Use React Router's Link to navigate to the new page and pass the oglas data as state
        navigate(`/OglasiMotorjevMotor/${oglas.id}`, { state: { oglas: oglas } });
    };

    const handlePageChange = (newPage) => {
        const totalPages = Math.ceil(oglasList.length / itemsPerPage);

        // Ensure newPage is within valid range
        newPage = Math.max(1, Math.min(newPage, totalPages));

        const startIndex = (newPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, oglasList.length);

        setCurrentPage(newPage);
        setCurrentItems(oglasList.slice(startIndex, endIndex));
        navigate(`/OglasiMotorjev/page/${newPage}`);
    };

    const generatePageNumbers = (currentPage, totalPages) => {
        if (totalPages <= maxVisiblePages) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        }

        const halfVisiblePages = Math.floor(maxVisiblePages / 2);
        const startPage = Math.max(1, currentPage - halfVisiblePages);
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        const pageNumbers = [];
        if (startPage > 1) {
            pageNumbers.push(1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages) {
            pageNumbers.push(totalPages);
        }

        return pageNumbers;
    };

    const renderPageNumbers = (totalPages) => {
        return (
            <p className="current-page">Stran {currentPage} of {totalPages}</p>
        );
    };

    //
    const handleZnamkaClick = async (znamka) => {
        try {
            let newData;
            if (znamka === 'VseZnamke') {
                // If "All Brands" is selected, use the existing data
                window.location.reload()
                newData = oglasList;
            } else {
                // Filter data for the selected brand (Znamka)
                newData = oglasList.filter((oglas) => oglas.znamka === znamka);
            }
            // Filter data based on the selected category as well
            if (selectedCategory !== '') {
                newData = newData.filter((oglas) => oglas.vrstaMotorja === selectedCategory);
            }

            setSelectedZnamka(znamka); // Set the selected brand
            setCurrentPage(1); // Reset to the first page when changing brands
            setCurrentItems(newData.slice(0, itemsPerPage));
        } catch (error) {

        }
    };
    const handleCategoryClick = async (category) => {
        try {
            let newData;
            if (category === '') {
                newData = oglasList;
            } else {
                newData = oglasList.filter((oglas) => oglas.vrstaMotorja === category);
            }
            // Filter data based on the selected brand as well
            if (selectedZnamka !== '') {
                newData = newData.filter((oglas) => oglas.znamka === selectedZnamka);
            }

            setSelectedCategory(category);
            setCurrentPage(1);
            setCurrentItems(newData.slice(0, itemsPerPage));
            setSelectedCategory(category);
        } catch (error) {
         
        }
    };

    //prostornina
    const handleProstorninaClick = async (prostornina) => {
        try {
            let newData;
            if (prostornina === '') {
                newData = oglasList;
            } else {
                const [start, end] = prostornina.split('-').map(Number);
                newData = oglasList.filter((oglas) => oglas.prostornina >= start && oglas.prostornina <= end);
            }

            // Filter data based on the selected brand and category as well
            if (selectedZnamka !== '') {
                newData = newData.filter((oglas) => oglas.znamka === selectedZnamka);
            }
            if (selectedCategory !== '') {
                newData = newData.filter((oglas) => oglas.vrstaMotorja === selectedCategory);
            }

            // Set the selected engine displacement and update the displayed items
            setSelectedProstornina(prostornina);
            setCurrentPage(1);
            setCurrentItems(newData.slice(0, itemsPerPage));
        } catch (error) {
       
        }
    };

    //pogon
    const handlePogonClick = async (pogon) => {
        try {
            let newData;

            if (pogon === '') {
                newData = oglasList;
            } else {
                // Filter data for the selected "Pogon"
                newData = oglasList.filter((oglas) => oglas.pogon === pogon);
            }

            // Filter data based on the selected brand, category, and engine displacement as well
            if (selectedZnamka !== '') {
                newData = newData.filter((oglas) => oglas.znamka === selectedZnamka);
            }
            if (selectedCategory !== '') {
                newData = newData.filter((oglas) => oglas.vrstaMotorja === selectedCategory);
            }
            if (selectedProstornina !== '') {
                const [start, end] = selectedProstornina.split('-').map(Number);
                newData = newData.filter((oglas) => oglas.prostornina >= start && oglas.prostornina <= end);
            }

            // Set the selected "Pogon" and update the displayed items
            setSelectedPogon(pogon);
            setCurrentPage(1);
            setCurrentItems(newData.slice(0, itemsPerPage));
        } catch (error) {
        
        }
    };

    // Function to count the number of oglasi for each brand
    const countOglasiPerBrand = (znamka) => {
        return oglasList.filter((oglas) => (znamka === 'VseZnamke' || oglas.znamka === znamka)).length;
    };


    //refresh
    const handleRefresh = () => {
        window.location.reload();
    };


    const countOglasiPerCategoryInBrand = (znamka, category) => {
        return oglasList.filter(
            (oglas) =>
                ((znamka === 'VseZnamke' || oglas.znamka === znamka) || znamka === '') &&
                (category === '' || oglas.vrstaMotorja === category)
        ).length;
    };
    const getAvailableBrandsForCategory = (category) => {
        const availableBrands = [...new Set(
            oglasList
                .filter((oglas) => category === '' || oglas.vrstaMotorja === category)
                .map((oglas) => oglas.znamka)
        )];
        return availableBrands;
    };
    const countOglasiPerProstorninaRange = (start, end, selectedZnamka) => {
        return oglasList.filter((oglas) =>
            (selectedZnamka === '' || oglas.znamka === selectedZnamka) &&
            (oglas.prostornina >= start && oglas.prostornina <= end)
        ).length;
    };
    const countOglasiPerPogon = (selectedPogon) => {
        let filteredData = oglasList;

        if (selectedPogon === '') {
            return oglasList.length; // Return the total count if no "Pogon" is selected
        }
        // Apply your filtering logic here based on selected filters
        if (selectedZnamka !== '') {
            filteredData = filteredData.filter((oglas) => oglas.znamka === selectedZnamka);
        }
        if (selectedCategory !== '') {
            filteredData = filteredData.filter((oglas) => oglas.vrstaMotorja === selectedCategory);
        }
        if (selectedProstornina !== '') {
            const [start, end] = selectedProstornina.split('-').map(Number);
            filteredData = filteredData.filter((oglas) => oglas.prostornina >= start && oglas.prostornina <= end);
        }

        // Exclude items with pogon equal to 0
        filteredData = filteredData.filter((oglas) => oglas.pogon !== 0);

        if (selectedPogon === '') {
            return filteredData.length; // Return the total count if no "Pogon" is selected
        }

        return filteredData.filter((oglas) => oglas.pogon === selectedPogon).length;
    }; 
    //

    const handleDelete = (id) => {
        axios
            .delete(`/api/Oglas/deleteoglas/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    // Remove the item from the data
                    setData((prevData) => prevData.filter((item) => item.id !== id));
                }
            })
            .catch((error) => {
            
            });
    };

    return (
        <div>    
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Oglasimotorjev" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <h2 className="oglasimotorjevnaslovna">NAJNOVEJŠI MOTO OGLASI</h2>
            <div>
                <div>

                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <select
                            id="znamka"
                            name="znamka"
                            value={selectedZnamka}
                            onChange={(e) => handleZnamkaClick(e.target.value)}
                            className={`oglasimotorjevselect ${selectedZnamka ? 'izbranaznamka' : ''}`}
                            required
                        >
                            <option value="" disabled hidden>
                                Znamka Motorja
                            </option>
                            <option value="VseZnamke">Vse Znamke ({numberOfOglasi})</option>
                            {getAvailableBrandsForCategory(selectedCategory).map((brand) => (
                                <option key={brand} value={brand}>
                                    {brand} ({countOglasiPerBrand(brand)})
                                </option>
                            ))}
                        </select>

                        <select
                            id="category"
                            name="category"
                            value={selectedCategory}
                            onChange={(e) => handleCategoryClick(e.target.value)}
                            className={`oglasimotorjevselect ${selectedCategory ? 'izbranaznamka' : ''}`}
                            required
                        >
                            <option value="" disabled hidden>
                                Kategorija Motorja
                            </option>
                            <option value="">Vsi Motorji ({countOglasiPerCategoryInBrand(selectedZnamka, selectedCategory)})</option>
                            {['Chopper', 'Naked', 'Tourer', 'Enduro', 'Sport', 'Retro', 'Scrambler', 'Cross', 'Scooter', 'Moped', 'Minimoto'].map((category) => (
                                <option key={category} value={category}>
                                    {category} ({countOglasiPerCategoryInBrand(selectedZnamka, category)})
                                </option>
                            ))}
                        </select>
              

    
                        <select
                            id="prostornina"
                            name="prostornina"
                            value={selectedProstornina}
                            onChange={(e) => handleProstorninaClick(e.target.value)}
                            className={`oglasimotorjevselect ${selectedProstornina ? 'izbranaznamka' : ''}`}
                            required
                        >
                            <option value="" disabled hidden>Prostornina Motorja</option>
                            <option value="1-2700">1 ccm - 2700 ccm ({countOglasiPerProstorninaRange(1, 2700, selectedZnamka)})</option>
                            <option value="1-50">1 ccm - 50 ccm ({countOglasiPerProstorninaRange(1, 50, selectedZnamka)})</option>
                            <option value="50-125">50 ccm - 125 ccm ({countOglasiPerProstorninaRange(50, 125, selectedZnamka)})</option>
                            <option value="125-300">125 ccm - 300 ccm ({countOglasiPerProstorninaRange(125, 300, selectedZnamka)})</option>
                            <option value="300-500">300 ccm - 500 ccm ({countOglasiPerProstorninaRange(300, 500, selectedZnamka)})</option>
                            <option value="500-700">500 ccm - 700 ccm ({countOglasiPerProstorninaRange(500, 700, selectedZnamka)})</option>
                            <option value="700-900">700 ccm - 900 ccm ({countOglasiPerProstorninaRange(700, 900, selectedZnamka)})</option>
                            <option value="900-1100">900 ccm - 1100 ccm ({countOglasiPerProstorninaRange(900, 1100, selectedZnamka)})</option>
                            <option value="1100-1300">1100 ccm - 1300 ccm ({countOglasiPerProstorninaRange(1100, 1300, selectedZnamka)})</option>
                            <option value="1300-1500">1300 ccm - 1500 ccm ({countOglasiPerProstorninaRange(1300, 1500, selectedZnamka)})</option>
                            <option value="1500-1700">1500 ccm - 1700 ccm ({countOglasiPerProstorninaRange(1500, 1700, selectedZnamka)})</option>
                            <option value="1700-1900">1700 ccm - 1900 ccm ({countOglasiPerProstorninaRange(1700, 1900, selectedZnamka)})</option>
                            <option value="1900-2100">1900 ccm - 2100 ccm ({countOglasiPerProstorninaRange(1900, 2100, selectedZnamka)})</option>
                            <option value="2100-2300">2100 ccm - 2300 ccm ({countOglasiPerProstorninaRange(2100, 2300, selectedZnamka)})</option>
                        </select>

                        <select
                            id="pogon"
                            name="pogon"
                            value={selectedPogon}
                            onChange={(e) => handlePogonClick(e.target.value)}
                            className={`oglasimotorjevselect ${selectedPogon ? 'izbranaznamka' : ''}`}
                            required
                        >
                            <option value="" disabled hidden>
                                Pogon Motorja
                            </option>
                            <option value="" key="all">
                                Vsi Pogoni ({countOglasiPerPogon('')})
                            </option>
                            <option value="Veriga" key="veriga">
                                Veriga ({countOglasiPerPogon('Veriga')})
                            </option>
                            <option value="Jermen" key="jermen">
                                Jermen ({countOglasiPerPogon('Jermen')})
                            </option>
                            <option value="Kardan" key="kardan">
                                Kardan ({countOglasiPerPogon('Kardan')})
                            </option>
                        </select>                     

                        <button onClick={handleRefresh} className="oglasimotorjevselect">Osveži Iskanje</button>
                    </div>                 

                    <p className="steviloobjavljenihoglasov">Število vseh oglasov : {numberOfOglasi}</p>

                    {/* Conditionally display the count based on the selected brand */}
                    {selectedZnamka && selectedZnamka !== 'VseZnamke' && (
                        <p className="steviloobjavljenihoglasov">
                            Število oglasov za {selectedZnamka} : {countOglasiPerBrand(selectedZnamka)}
                        </p>
                    )}
               
                <div className="oglasimotorjevkontejner">
                    {Array.isArray(currentItems) && currentItems.length > 0 ? (
                            currentItems.map((oglas) => (
                            <div key={oglas.id} className="oglasmotorja" onClick={() => handleItemClick(oglas)}>
                                <div className="oglasimotorjevmini-container">
                                    <img className="oglasimotorjevslika" src={oglas.imageUrl1} loading="lazy" alt="oglasmotorja" />
                                </div>
                                <div className="motor-ad-content">
                                    <h3 className="oglasimotorjevnaslovmotorja">{oglas.znamka}&nbsp;{oglas.model}</h3>         
                                    <br></br>
                                    <p className="oglasimotorjevtext">Prostornina: {oglas.prostornina + "(ccm) "}</p>
                                    <p className="oglasimotorjevtext">Moč Motorja: {oglas.kilovati + "(kW) " + oglas.konji + "(HP)"}</p>
                                    <p className="oglasimotorjevtext">Cena: {oglas.cena + " €"}</p>
                                        <p className="oglasimotorjevtext">GSM ŠTEVILKA: {oglas.gsmStevilka}</p>

                                        {adminCookie && (
                                            <button onClick={() => handleDelete(oglas.id)}>Delete</button>
                                        )}
                                </div>   
                            </div>
                        ))
                    ) : (
                        <p>No oglas data found.</p>
                    )}
                    </div>
                </div>

            <div className="oglasipagination">
                <div className="oglasipagination-numbers">
                    {renderPageNumbers(Math.ceil(oglasList.length / itemsPerPage))}
                </div>

                <div>
                    {currentPage > 1 && (
                            <button className="oglasipagination-buttons" onClick={() => handlePageChange(currentPage - 1)}>
                                Nazaj
                            </button>
                    )}

                    {currentPage < Math.ceil(oglasList.length / itemsPerPage) && (
                            <button className="oglasipagination-buttons" onClick={() => handlePageChange(currentPage + 1)}>
                                Naprej
                            </button>
                    )}
                </div>
            </div>
            </div>
        </div>

    );
};

export default OglasiMotorjev;