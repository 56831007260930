import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <footer className="domovfooter">
            <div>
                <p className="domovfootertext">© Powered by Rock 'N' Roll </p>
            </div>
            <div>
                <a href="/Kontakt" className="kontakttext">
                    Kontakt
                </a>
                <a href="/Pravila" className="kontakttext">
                    Pravila in Pogoji
                </a>
            </div>
        </footer>
    );
};

export default Footer;