import React, { useState } from 'react';
import axios from 'axios';
import StarryBackground from '../StarsEffect/StarryBackground';
import './Registracija.css';
import UniversalCookie from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const Registracija = () => {
    const [uporabniskoIme, setUporabniskoIme] = useState('');
    const [email, setEmail] = useState('');
    const [geslo, setGeslo] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const cookies = new UniversalCookie();
   

    const handleInputChange = (e, field) => {
        const inputValue = e.target.value;

        // Define a regular expression pattern for letters (both uppercase and lowercase) and numbers
        const pattern = /^[a-zA-Z0-9]*$/;

        // Check if the input value is not empty and does not match the pattern
        if (inputValue !== '' && !pattern.test(inputValue)) {
            setErrorMessage(`${field} lahko vsebuje le črke (velike in male) ter številke.`);
            return;
        }

        // If the input value is valid, update the state
        if (field === 'uporabniskoIme') {
            setUporabniskoIme(inputValue);
        } else if (field === 'geslo') {
            setGeslo(inputValue);
        }

        setErrorMessage(''); // Clear any previous error message
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prevent double submissions
        if (isSubmitting) {
            return;
        }

        if (!isChecked) {
            alert('Strinjajte se z pogoji uporabe portala.');
            return;
        }

        setIsSubmitting(true);

        const userData = {
            uporabniskoIme,
            email,
            geslo,
        };

        try {
            const response = await axios.post('/api/Registracija/registracija', userData);
            if (response.status === 200) {
                setErrorMessage('Registracija Uspešna');
                // Use universal-cookie to set the cookie
                cookies.set('Motolegende', {
                    token: 'authentication_token',
                    username: uporabniskoIme, // Use the correct variable
                }, { path: '/', expires: new Date(Date.now() + 3 * 30 * 24 * 60 * 60 * 1000), secure: true });

                navigate("/MojProfil");
                window.location.reload();
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data);
            } else {
                setErrorMessage('An error occurred while processing your request.');
            }
        } finally {
            setIsSubmitting(false); // Re-enable the submit button
        }
    };

    return (
        <div className="registracijakontejner">
            <h1 className="registracijauporabnikanaslov">REGISTRACIJA UPORABNIKA</h1>
            <form onSubmit={handleSubmit}>
                <div className="registracijainputs">
                    <label>Uporabniško Ime :</label>
                    <input
                        type="text"
                        className="form-control"
                        value={uporabniskoIme}
                        onChange={(e) => handleInputChange(e, 'uporabniskoIme')}
                        required
                    />
                </div>
                <div className="registracijainputs">
                    <label>Email:</label>
                    <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="registracijainputs">
                    <label>Geslo :</label>
                    <input
                        type="password"
                        className="form-control"
                        value={geslo}
                        onChange={(e) => handleInputChange(e, 'geslo')}
                        required
                    />
                </div>
                <div>
                <br></br>
                    <label>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                            required
                        />
                        <span> Strinjam se z :</span>
                        <a href="/Pravila">Pravila in Pogoji Uporabe</a>
                    </label>
                </div>
                <button type="submit" className="registrirajmebutton">
                    Registriraj Me
                </button>
            </form>
            <br />  
                <center>
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p>
                </center>
           <div className="my-page">
             {/* Your page content */}
            <StarryBackground show={true} /> {/* Show the starry background */}
         </div>
        </div>
    );
}

export default Registracija;