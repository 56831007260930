import React, { useEffect, useState } from 'react';
import "./UmetnostPodstrani.css";
import { Helmet } from 'react-helmet';

const GrossGlockner = () => {
    const pageTitle = "Moto potovanje na Grossglockner Avstrija";
    const pageDescription = "Moto potovanje na Grossglockner Avstrija";

    return (

        <div className="">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.motolegende.si/Umetnost/GrossGlockner" />
                {/* Add other metadata tags as needed */}
            </Helmet>
            <br></br>
            <img src="Slike\DomovSlike\Umetnost\SimbolicnoOzadje\Grossglockner.jpg" alt="Grossglockner Avstrija" className="vinotekaumetnostozadje2" />
            <center>
                <h3 className="k5" alt="kam na dopust">GROSSGLOCKNER - AVSTRIJA</h3>
                <p style={{ color: "white" }}>( - Motoristično potovanje na ledenik 3798m - )</p>
                <div>
                    <img src="Slike\DomovSlike\Umetnost\GrossGlockner\slika3.jpg" alt="Grossglockner avstrija" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\GrossGlockner\slika1.jpg" alt="Grossglockner avstrija" className="umetnostpodstranivecslik" />
                    <img src="Slike\DomovSlike\Umetnost\GrossGlockner\slika2.jpg" alt="Grossglockner avstrija" className="umetnostpodstranivecslik" />
                </div>

            </center>
            <br></br>
            <p style={{ color: "white", width: "80%", marginLeft: "10%" }}>Članek objavljen : 19.06.2024</p>
            <p style={{ color: "white", width: "80%", marginLeft: "10%", fontSize: "20px" }} alt="Grossglockner Avstrija"> Grossglockner je čudovita gora v Avstriji, katera sega 3798m v višino in ime je dobila leta 1561. Do vrha gore imamo čudovite motoristične poti, ovinke in postojanke.
                Cena za posamezen motor ob vponu je 25€ (ni čisto đabe drva). Na vrhu gore imamo ledenik, ki se vsako leto bolj topi in prečudovite razgledne točke. Na cilju imamo velik parking za vozila, trgovino in razgled na ledenik. Nato se lahko odpravimo peš do bara, okoli 100m višje od parkirišča.
                Tam nudijo odličen štrudel, za keterega je potrebno odšteti 5€ in klasično avstrijsko pijačo. Cenovno ni pri ugodnjših motorističnih izletih (spanje v hotelu + gorivo + karta in ostalo) Za enkratno motoristično doživetje je Grossglockner dokaj dobra izbira. Ocena(9/10).
            </p>
            <div>
                <a href="https://www.motolegende.si/Umetnost">
                    <img src="/Logotipi/nazaj.png" className="k6" />
                </a>
            </div>
        </div>

    );
};

export default GrossGlockner;