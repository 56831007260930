import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

const MyComponent = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [administrator, setAdministrator] = useState({
        Administrator: '',
        Geslo: '',
    });
    const [errorMessage, setErrorMessage] = useState(null); // State for error message

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAdministrator({
            ...administrator,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Send a request to your server to validate the login
            const response = await axios.post('/api/Administrator/admin', administrator, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Login successful');

                // Create a custom cookie for the administrator
                cookies.set('adminCookie', {
                    Administrator: administrator.Administrator,
                    Geslo: administrator.Geslo,
                });

                // Redirect to the admin page upon successful login
                navigate('/Kontakt/Administrator');
                window.location.reload();
            } else {
                console.error('Login failed');
                setErrorMessage('Login failed. Please check your credentials.'); // Set error message

                // Redirect to another page upon login failure (optional)
                // navigate('/Domov');
            }
        } catch (error) {
            console.error('Network error:', error);
            setErrorMessage('WARNING : Unauthorized Access !'); // Set error message for network error
        }
    };

    return (
        <div className="admin">
        <br></br>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="Administrator"
                    value={administrator.Administrator}
                    onChange={handleInputChange}
                    placeholder="Administrator"
                />
                <input
                    type="password"
                    name="Geslo"
                    value={administrator.Geslo}
                    onChange={handleInputChange}
                    placeholder="Geslo"
                />
                <button type="submit">Vpis</button>
            </form>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <br></br>
            <p style={{color:"red"} }>If you are not Admin - Don"t Login.</p>
            <center><img src="/logotipi/admin.jpg" alt="komentar" style={{ width: "200px" }} /></center>
        </div>
    );
};

export default MyComponent;