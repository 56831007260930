import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UniversalCookie from 'universal-cookie'; // Import the default export
import StarryBackground from '../StarsEffect/StarryBackground';
import "./Vpis.css"; // Adjust the CSS import based on your file structure


const Vpis = () => {
    const cookies = new UniversalCookie(); // Create an instance of universal-cookie
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        uporabniskoImeOrEmail: '',
        geslo: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.post('/api/Vpis/vpis', formData);

            if (response.status === 200) {

                // Clear form data
                setFormData({
                    uporabniskoImeOrEmail: '',
                    geslo: '',
                });

                // Check if login was done with an email
                const isEmailLogin = response.data.uporabniskoIme !== undefined;

                if (!isEmailLogin) {
                    // If login was done with username, throw an error
                    throw new Error('Napačno geslo ali email!');
                }

                // Use universal-cookie to set the cookie only if login was with email
                const uporabniskoIme = response.data.uporabniskoIme; // Assuming 'uporabniskoIme' is returned from the upper controller
                cookies.set('Motolegende', {
                    token: 'authentication_token',
                    username: uporabniskoIme,
                }, { path: '/', expires: new Date(Date.now() + 3 * 30 * 24 * 60 * 60 * 1000), secure: true });

                navigate("/MojProfil");
                window.location.reload();
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage('Incorrect username or password');
            } else {
                setErrorMessage("Napačno geslo ali email");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = () => {
        setShowPassword(!showPassword);
    };
   
    return (
        <div className="prijavakontejner">
            <h3 className="prijavatext">VPIS</h3>
            <br></br>
            <form onSubmit={handleSubmit}>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="prijavaminikontejner">
                    <label htmlFor="uporabniskoImeOrEmail">E-mail:</label>
                    <input
                        type="text"
                        id="uporabniskoImeOrEmail"
                        name="uporabniskoImeOrEmail"
                        value={formData.uporabniskoImeOrEmail}
                        onChange={handleChange}
                        className="prijavainputs"
                        required
                    />
                </div>
                <div className="prijavaminikontejner">
                    <label htmlFor="geslo">Geslo:</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        id="geslo"
                        name="geslo"
                        value={formData.geslo}
                        onChange={handleChange}
                        className="prijavainputs"
                        required
                    />
                    <label>
                    <br></br>
                        <input
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            checked={showPassword}
                        />
                         Prikaz gesla
                    </label>
                </div>
                <button type="submit" disabled={isLoading} className="prijavabutton">
                    {isLoading ? 'Vpisovanje ...' : 'VPIŠI ME'}
                </button>
            </form>

            {/* Registration link below the form */}
            <div className="registration-link">
                <p className="white-text">Še nimate uporabniškega računa?</p>
                <a href="/Registracija" className="registracijagumb">
                    REGISTRIRAJ SE
                </a>
            </div>    
            <a href="/Vpis/Email" style={{ marginTop: '10%' }}>
                Pozabljeno Geslo?
            </a>
            <StarryBackground show={true} />        
        </div>
    );
};

export default Vpis;